import { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import { getProjectManagementList } from "../../services";
import { _contractStatus } from "../../utils/constant";
import AddProjectPlan from "../projects/projectManagement/AddProjectPlan";


const ClientProjectManagementComponent = ({ selectedProject }) => {
  const userDetail = JSON.parse(localStorage.getItem("authUserData")) || null;
  const [list, setList] = useState([]);
  const [pageLoader, setPageLoader] = useState(true);
  const [activeProject, setActiveProject] = useState({});
  const [showProjectDetailRow, setShowProjectDetailRow] = useState(false);

  useEffect(() => {
    const fetchDealrooms = async () => {
      if (!userDetail?.businessDetail?.businessId || !selectedProject?.id) return;
      const params = `?businessId=${userDetail.businessDetail.businessId}&projectId=${selectedProject.id}`;
      try {
        const { data } = await getProjectManagementList(params);
        if (data?.status) {
          setList(data.responseData?.data || []);
        }
        setPageLoader(false)
      } catch (error) {
        console.error('Error fetching signers:', error);
        setPageLoader(false)
      }
    };
    
    fetchDealrooms();
  }, [selectedProject]);


  const handleClose = () => {
    setActiveProject({});
    setShowProjectDetailRow(false);
  };


  if(pageLoader){
    return <div>Loading...</div>
  }else{

    if (showProjectDetailRow) {
      return (
        <AddProjectPlan
          isClientView={true}
          handleCancel={handleClose}
          staticDetails={selectedProject}
          userDetails={userDetail}
          isEditing={true}
          isShowOnlySection={activeProject.isOnlySection}
          projectRowData={activeProject}
        />
      );
    }else{
      return (
        <div>
          <h3>Project Plans</h3>
          <div className="table-responsive">
          <table
            className="project_table dealroom_table"
            style={{ marginTop: 0 }}
          >
            <thead>
              <tr>
                <th className="text-start ps-5">#</th>
                <th className="text-start">Last Updated</th>
                <th className="text-start">Title</th>
                <th className="text-start">Created By</th>
              </tr>
            </thead>
            <tbody>
              {
                list.filter(j => j.isShareWithClient).map((changeRequest, i) => (
                  <tr key={i} onClick={() => (setActiveProject(changeRequest), setShowProjectDetailRow(true))}>
                    <td className="text-start ps-5">L-{i + 1}</td>
                    <td className="text-start">{format(parseISO(changeRequest?.updated_at), "MMMM dd, yyyy")}</td>
                    <td className="dealroom_table_title text-start">{changeRequest?.planName}</td>
                    <td className="text-start">{changeRequest?.createBy || "N/A"}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          </div>
        </div>
      );
    }

    

  }


};

export default ClientProjectManagementComponent;
