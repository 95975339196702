import React, { useEffect, useState } from "react";
import PhoneInput, { formatPhoneNumberIntl, isValidPhoneNumber } from "react-phone-number-input";
import 'react-phone-number-input/style.css';

const PhoneNumber = ({ value = "", setValue, isError, setError, isSubmitTouch, isDisabled, tabIndex }) => {
  const [touched, setTouched] = useState(false);
  const [focus, setFocus] = useState(false);
  const [val, setVal] = useState(value);

  useEffect(() => {
    setVal(value || "");  // Initialize val as an empty string if value is undefined
  }, [value]);

  useEffect(() => {
    if (isSubmitTouch) setTouched(true);
  }, [isSubmitTouch]);

  const handleOnChange = (phoneNumber) => {
    setVal(phoneNumber);
    setValue(formatPhoneNumberIntl(phoneNumber) || "");  // Ensure it doesn't format as "undefined"
    if (touched) {
      setError(isValidPhoneNumber(phoneNumber || "") ? "" : "Enter a valid mobile number");
    }
  };

  const handleOnBlur = () => {
    setFocus(false);
    setTouched(true);
    if (!val) {
      setError("Mobile number is required");
    } else if (isValidPhoneNumber(val)) {
      setError("");
    } else {
      setError("Enter a valid mobile number");
    }
  };

  const handleOnFocus = () => {
    setFocus(true);
  };

  return (
    <div className={`${(touched && isError) ? "phone-input-error" : ""} _PhoneInput_ ${focus ? !isError ? 'phone-focused' : 'phone-input-error' : 'phone-not-focused'}`}>
      <PhoneInput
        disabled={isDisabled}
        tabIndex={tabIndex}
        placeholder="Mobile Number"
        countryCallingCodeEditable={false}
        defaultCountry="IN"
        value={val}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        limitMaxLength={true}
      />
    </div>
  );
};

export default PhoneNumber;
