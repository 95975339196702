
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  templates: {},
};

const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    addTemplates: (state, action) => {
      state.templates = action.payload;
    },
  },
});

export const { addTemplates } = templatesSlice.actions;

export default templatesSlice.reducer;