import { useState } from "react";

const useValidation = () => {
  const [values, setValues] = useState({
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
  });
  const [runTimeErrors, setRunTimeErrors] = useState({});
  const [errors, setErrors] = useState({});

  const validateEmail = (email) => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(){}|\\?\/<>,.;:'"`~_+\-=\[\]])[A-Za-z\d!@#$%^&*(){}|\\?\/<>,.;:'"`~_+\-=\[\]]{8,}$/;
    return passwordRegex.test(password);
  };


  const validateName = (name) => {
    const nameRegex = /^[a-zA-Z\s]{2,}$/;
    return nameRegex.test(name);
  };

  /****************************************************************************/
  /********************* ON-SUBMIT CLICK VALIDATIONS **************************/
  /****************************************************************************/

  const validateForm = (formData, formType) => {
    const errors = {};

    switch (formType) {
      case "email":
        if (formData.email === "") {
          errors.email = "Please enter your Email Id";
        } else {
          if (!validateEmail(formData.email)) {
            errors.email = "Please enter valid Email Id";
          }
        }
        break;
      case "forgetPassword":
        if (formData.email === "") {
          errors.email = "Email should not be empty";
        } else {
          if (!validateEmail(formData.email)) {
            errors.email = "Please enter valid email id";
          }
        }
        break;
      case "signin":
        if (formData.email === "") {
          errors.email = "Email id is required";
        } else {
          if (!validateEmail(formData.email)) {
            errors.email = "Please enter valid Email Id";
          }
        }
        if (formData.signinPass === "") {
          errors.signinPass = "Password is required";
        }else if(formData.signinPass.length > 18)  {
          errors.signinPass = "Password should have max length of 18 character.";
        }else{

        }
        break;
      case "signup":
        if (formData.firstName === "") {
          errors.firstName = "First name is required";
        } else {
          if (!validateName(formData.firstName)) {
            errors.firstName = "2 characters required";
          }
        }
        if (formData.lastName === "") {
          errors.lastName = "Last name is required";
        } 
        // else {
        //   if (!validateName(formData.lastName)) {
        //     errors.lastName = "2 characters required";
        //   }
        // }
        // if (!validatePhoneNumber(formData.phoneNumber)) {
        //   errors.phoneNumber = "Invalid phone number";
        // }
        if (formData.password === "") {
          errors.password = "Password is required";
        }else if(formData.password.length > 18)  {
          errors.password = "Password should have max length of 18 character.";
        }else {
          
          if (!validatePassword(formData.password)) {
            errors.password =
              "Password must have at least 8 characters, one uppercase, one lowercase, one number and one special character";
          }
        }
        break;

        case "clientDetails":
        // if (formData.clientName === "") {
        //   errors.clientName = "Client name is required";
        // } else {
        //   if (!validateName(formData.firstName)) {
        //     errors.firstName = "2 characters required";
        //   }
        // }
        if (formData.firstName === "") {
          errors.firstName = "First name is required";
        } else {
          if (!validateName(formData.firstName)) {
            errors.firstName = "2 characters required";
          }
        }
        if (formData.lastName === "") {
          errors.lastName = "Last name is required";
        } 
        if (formData.email === "") {
          errors.email = "Please enter your Email Id";
        } else {
          if (!validateEmail(formData.email)) {
            errors.email = "Please enter valid Email Id";
          }
        }
        // else {
        //   if (!validateName(formData.lastName)) {
        //     errors.lastName = "2 characters required";
        //   }
        // }
        // if (!validatePhoneNumber(formData.phoneNumber)) {
        //   errors.phoneNumber = "Invalid phone number";
        // }
        break;
      case "createPassword":
        if (formData.password === "") {
          errors.password = "Password is required";
        } else if (!validatePassword(formData.password)) {
          errors.password =
            "Password should contain atleast 8 characters with 1 Uppercase,1 Lowercase,1 Numeric & 1 Special Character";
        }
        if (formData.confirmPassword === "") {
          errors.confirmPassword = "Confirm password is required";
        } else if (formData.password !== formData.confirmPassword) {
          errors.confirmPassword =
            "Password and Confirm Password must be same";
        }
        break;
      case "businessInfo":
        if (formData.businessName === "") {
          errors.businessName = "Business name is required";
        } 

        if (formData.industry === "") {
          errors.industry = "Industry is required";
        } 

        if (formData.businessAddress === "") {
          errors.businessAddress = "Business address is required";
        } 

        if (formData.role === "") {
          errors.role = "Business address is required";
        } 
        break;

      case "projectInfo":
        if (formData.projectName === "") {
          errors.projectName = "Project name is required";
        } 

        if (formData.firstName === "") {
          errors.firstName = "Client first name required";
        } 

        if (formData.lastName === "") {
          errors.lastName = "Client last name required";
        } 

        if (formData.email === "") {
          errors.email = "Client email required";
        } 

        if (!formData.phone_number) {
          errors.phone_number = "Client phone number required";
        } 

        break;
      
      case "projectDetails":
        if (formData.projectName === "") {
          errors.projectName = "Project name is required";
        } 
        
        break;

        case "businessInfo":
          if (formData.businessName === "") {
            errors.businessName = "Business name is required";
          } 
  
          if (formData.industry === "") {
            errors.industry = "Industry is required";
          } 
  
          if (formData.businessAddress === "") {
            errors.businessAddress = "Business address is required";
          } 
  
          if (formData.role === "") {
            errors.role = "Business address is required";
          } 
          break;
  
        case "projectInfo":
          if (formData.projectName === "") {
            errors.projectName = "Project name is required";
          } 
  
          if (formData.clientName === "") {
            errors.clientName = "Client name required";
          } 
          break;
      default:
        break;
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  /****************************************************************************/
  /****************************************************************************/




  

  /****************************************************************************/
  /************************* RUN TIME VALIDATIONS *****************************/
  /****************************************************************************/

  const validateRunTimeError = (fieldName, value, pass) => {
    setValues({
      ...values,
      [fieldName]: value,
    });

    switch (fieldName) {
      case "email":
        if (value == "") {
          setValues({ ...values, email: "inValid" });
          setRunTimeErrors({
            ...runTimeErrors,
            email: "Please enter your Email Id",
          });
        } else if (validateEmail(value)) {
          setValues({ ...values, email: "valid" });
          setRunTimeErrors({ ...runTimeErrors, email: "" });
        } else {
          setValues({ ...values, email: "inValid" });
          setRunTimeErrors({
            ...runTimeErrors,
            email: "Please enter valid Email Id",
          });
        }
        // Validate email
        break;
      case "signinPass":
        if (value == ''){
          setRunTimeErrors({
            ...runTimeErrors,
            signinPass: "Password is required",
          });
        }else if(value.length > 18)  {
          setRunTimeErrors({
            ...runTimeErrors,
            signinPass: "Password should have max length of 18 character.",
          });
        }
        break
      case "firstName":
        if (value == "") {
          setValues({ ...values, firstName: "inValid" });
          setRunTimeErrors({
            ...runTimeErrors,
            firstName: "First name is required",
          });
        } else if (validateName(value)) {
          setValues({ ...values, firstName: "valid" });
        } else {
          setValues({ ...values, firstName: "inValid" });
          setRunTimeErrors({
            ...runTimeErrors,
            firstName: "2 characters required",
          });
        }
        break;
      case "lastName":
        if (value == "") {
          setValues({ ...values, lastName: "inValid" });
          setRunTimeErrors({
            ...runTimeErrors,
            lastName: "Last name is required",
          });
        } 
        else if(validateName(value)) {
          setValues({ ...values, lastName: "valid" });
        } 
        // else {
        //   setValues({ ...values, lastName: "inValid" });
        //   setRunTimeErrors({
        //     ...runTimeErrors,
        //     lastName: "Last Name should have at least 3 char.",
        //   });
        // }
        else {

        }
        break;
      case "phoneNumber":
        // Validate phoneNumber
        break;
      case "password":
        if (value == "") {
          setValues({ ...values, password: "inValid" });
          setRunTimeErrors({
            ...runTimeErrors,
            password: "Password is required",
          });
        } else if(value.length > 18)  {
          setRunTimeErrors({
            ...runTimeErrors,
            password: "Password should have max length of 18 character.",
          });
        }else if (validatePassword(value)) {
          setValues({ ...values, password: "valid" });
          setRunTimeErrors({ ...runTimeErrors, password: "" });
        } else {
          setValues({ ...values, password: "inValid" });
          setRunTimeErrors({
            ...runTimeErrors,
            password:
              "Password must have at least 8 characters, one uppercase, one lowercase, one number and one special character",
          });
        }
        break;
      case "confirmPassword":
        if (value == "") {
          setValues({ ...values, password: "inValid" });
          setRunTimeErrors({
            ...runTimeErrors,
            confirmPassword: "Confirm password should not be empty",
          });
        }else if(pass === ''){
          setValues({ ...values, password: "inValid" });
          setRunTimeErrors({
            ...runTimeErrors,
            confirmPassword: "Please fill password first than confirm password",
          });
        } else if(pass !== value){
          setValues({ ...values, password: "inValid" });
          setRunTimeErrors({
            ...runTimeErrors,
            confirmPassword: "Password and Confirm password must be same",
          });
        } else if (validatePassword(value)) {
          setValues({ ...values, confirmPassword: "valid" });
        } else {
          setValues({ ...values, confirmPassword: "inValid" });
        }
        break;
      case "businessName":
        if(value === ''){
          setRunTimeErrors({
            ...runTimeErrors,
            businessName: 'Business name is required',
          });
        }
        break;
      case "businessAddress":
        if(value === ''){
          setRunTimeErrors({
            ...runTimeErrors,
            businessAddress: 'Business address is required',
          });
        }
        break;
      case "projectName": 
        if(value === ''){
          setRunTimeErrors({
            ...runTimeErrors,
            projectName: 'Project name is required',
          });
        }
        break;
      case "clientName": 
        if(value === ''){
          setRunTimeErrors({
            ...runTimeErrors,
            clientName: 'Client name is required',
          });
        }  
      
        break;
      default:
        break;
    }
  };

  /**************************************************************************/
  /**************************************************************************/

  return {
    errors,
    values,
    runTimeErrors,
    setErrors,
    validateForm,
    setRunTimeErrors,
    validateRunTimeError,
  };
};

export default useValidation;
