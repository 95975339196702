import axios from "axios";

import settings from '../config/settings';


const ApiInstance = axios.create({
  baseURL: settings.api.url,
});



ApiInstance.interceptors.request.use(req => {
  let token = localStorage.getItem("token");
  let headers = {
    'accept': 'application/json',
    "Content-Type": "application/json",
  };

  headers["Authorization"] = settings.api.AUTH;
  headers = {
    ...headers,
    ...(token && { token: token })
  }
  req.headers = headers;

  return req;
},
  (error) => Promise.reject(error)
);




ApiInstance.interceptors.response.use(res => {
  // if(res.data.statusCode == 0){
  //   if(res.data.error[0].errorCode === 4){
  //     localStorage.clear();
  //     window.location.href = '/'
  //   }
  // }

  if (res.status == 400) {
    return {
      statusCode: res.data.statusCode,
      errors: res.data.error
    }
  } else {
    return {
      statusCode: res.status,
      data: res.data
    }

  }
},
  (error) => {
    if (error.response) {
      //return Promise.reject(error.response.data);
      return {
        statusCode: 0,
        errors: error.response.data?.errorMessage
      }
    }
    if (error.message === "Network Error") {
      const networkError = {
        errorCodeList: ["NETWORK"],
      };
      return {
        statusCode: 0,
        errors: 'Server Error' || error.response.data?.errorMessage
      }
      // return Promise.reject(networkError);
    } else if (error.message === `timeout of 3 ms exceeded`) {
      const timeoutError = {
        errorCodeList: ["TIMEOUT"],
      };
      return Promise.reject(timeoutError);
    }
    return Promise.reject(error);
  }
);
export default ApiInstance;