import {  Navigate } from "react-router-dom";
const PublicRoutes = (props) => {
        const role = localStorage.getItem('userRole');
        const { Component } = props
        let auth = localStorage.getItem('isLogin');

        if(!auth){
                return <Component/>
        }else{
            if(role == 'Accountant'){
                return <Navigate to="/accountant-app" />
                
            }else if(role == 'client'){
                return <Navigate to="/client-app" />
            }else{
                return <Navigate to="/dashboard" />
            } 
        }

}
export default PublicRoutes
