import React, { memo, useEffect, useState } from "react";
import CommonButton from "../../../components/common/CommonButton";
import plusicon from "./../../../assets/dashboard/plus.svg";
import deleteicon from "./../../../assets/dashboard/delete.svg";
import templateIcon from "../../../assets/docs/signersTemplate.svg"
import TemplatePreview from "./TemplatePreview";
import useToast from "../../../utils/customHooks/useToast";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { capitalizeFirstLetter, getPrimaryClient } from "../../../utils/helper";
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const colors = {
  0: '#A9A9A9', // light green
  1: '#9370DB', // GreenYellow
  2: '#28A745', // LightPink
  3: '#5BC0DE', // LightSkyBlue
  4: '#007BFF', // LemonChiffon
};


const newEmptySigner = {name: '', email: '', bgColor: '', userType: 2, countryCode: '', phoneNumber: ''}

const DealroomSigners = ({previewUrl, templateDetailHandler, _cancelHandler, type, renerHanler}) => {
  const projectDetail = JSON.parse(localStorage.getItem('projectDetail'));
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [currentSigner, setCurrenSigner] = useState(1)
  const [signers, setSigners] = useState([{ name: "", email: "", bgColor:"", userType: "" }]);
  const [titleError, setTItleError] = useState('')
  const [emailErrors, setEmailErrors] = useState([]);
  const showToast = useToast();

  useEffect(() => {
    const myDetails = JSON.parse(localStorage.getItem('authUserData'));
    const _crSchema = JSON.parse(localStorage.getItem('crSchema'));
    let me = {
      name: `${myDetails.firstName} ${myDetails.lastName}`,
      email: myDetails.emailId,
      bgColor:'#A9A9A9',
      userType: 1,
      countryCode: `${myDetails.countryCode}`,
      phoneNumber: `${myDetails.phoneNumber}`
    }

    if(currentSigner === 1){
      let temp = []
      temp.push(me)
      setSigners(temp)
      setEmailErrors([false]);
    }


    if(currentSigner === 2){
      let temp = []
      let primaryClient = {};
      if(projectDetail.clientEmail){
        let _primaryClient = getPrimaryClient(projectDetail);
        console.log(_primaryClient, '_primaryClient_primaryClient_primaryClient')
        if(_primaryClient.email){
          primaryClient = {
            name: _primaryClient?.clientName,
            countryCode: `${_primaryClient.country_code}`,
            phoneNumber: `${_primaryClient.phone_number}`,
            ..._primaryClient,
          };
        }else{
          let _cl = projectDetail.clients[0]
          primaryClient = {
            name: _cl.clientName,
            email: _cl.email,
            bgColor: colors[0],
            userType: 2,
            countryCode: `${_cl.country_code}`,
            phoneNumber: `${_cl.phone_number}`
          }
        }
      }else{
        let _cl = projectDetail.clients[0]
        primaryClient = {
          name: _cl?.clientName,
          email: _cl?.email,
          bgColor: colors[0],
          userType: 2,
          countryCode: `${_cl?.country_code || ''}`,
          phoneNumber: `${_cl?.phone_number || ''}`
        }
      }

     


     if(_crSchema?.signers?.length > 1){
      let _res = _crSchema.signers.map((item, indx) => ({
        name: item.name,
        email: item.emailId,
        bgColor: colors[indx],
        userType: 2,
        countryCode: `${item.countryCode}`,
        phoneNumber: `${item.phoneNumber}`
       }))

       _res.push(primaryClient)

      setSigners(_res)
     }else{
      temp.push(me);
      temp.push(primaryClient)
      //temp.push(newEmptySigner)
      setSigners(temp)
     }
      setEmailErrors([false, false]);
    }


    if(currentSigner === 3){
      setSigners([newEmptySigner])
      setEmailErrors([false]);
    }

  },[currentSigner])


  useEffect(() => {
    const _crSchema = JSON.parse(localStorage.getItem('crSchema'));
    if(_crSchema?.currentSigner){
      setCurrenSigner(_crSchema.currentSigner);
    }
  }, [])


  const addSigner = () => {
    if(signers.length < 5){
      setSigners([
        ...signers,
        newEmptySigner
      ]);
      setEmailErrors([...emailErrors, false]);
    }
  };


  const removeSigner = (index) => {
    const signerToRemove = signers[index];
    if (signerToRemove.userType === 1) {
      return;
    }
    const newSigners = signers.filter((_, i) => i !== index);
    setSigners(newSigners);
    setEmailErrors(emailErrors.filter((_, i) => i !== index));
  };


  const handleInputChange = (index, field, value) => {
    setSigners(prevSigners => {
      const updatedSigners = [...prevSigners];
      updatedSigners[index] = {
        ...updatedSigners[index],
        [field]: value,
        bgColor: colors[index]
      };
  
      return updatedSigners;
    });
  
    if (field === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setEmailErrors(prevErrors => {
        const updatedErrors = [...prevErrors];
        updatedErrors[index] = !emailRegex.test(value);
        return updatedErrors;
      });
    }
  };
  



  // const handleInputChange = (index, field, value) => {
  //   const newSigners = [...signers];
  //   newSigners[index][field] = value;
  //   newSigners[index]["bgColor"] = colors[index]

  //   if (field === "email") {
  //     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //     const newEmailErrors = [...emailErrors];
  //     newEmailErrors[index] = !emailRegex.test(value);
  //     setEmailErrors(newEmailErrors);
  //   }

  //   setSigners(newSigners);
  // };

  const handleInputChange2 = (value, index) => {
    if (value) {
      const phoneNumberObj = parsePhoneNumberFromString(value);
  
      if (phoneNumberObj && phoneNumberObj.isValid) {
        const countryCode = phoneNumberObj.countryCallingCode;
        const number = phoneNumberObj.nationalNumber;
  
        const newSigners = [...signers];
        newSigners[index].phoneNumber = number;
        newSigners[index].countryCode = `+${countryCode}`;
        delete newSigners[index].phoneNumberErr; 
        setSigners(newSigners);
      } else {
        const newSigners = [...signers];
        newSigners[index].phoneNumberErr = 'Invalid phone number';
        setSigners(newSigners);
      }
    }
  };

  const emailErrorValidation = () => {
      const containsTrue = emailErrors.some(element => element === true);
      return !containsTrue;
  }

  const handleNextClick = (url) => {
      localStorage.removeItem('_boxes');

      if(title !== '' && emailErrorValidation()){
        const projectData = JSON.parse(localStorage.getItem('projectDetail'))
        const projectID = projectData?.id;

        let temp = {
          documentTitle:title,
          documentMessage:description,
          signers:[...signers]
        }

        const crSchema = {...temp}
        crSchema["status"] = 6
        crSchema["currentSigner"] = currentSigner
        crSchema["projectId"] = projectID

        crSchema.signers = signers.map((item, index) => ({
          emailId: item.email,
          name: item.name,
          status: 20,
          phoneNumber: item.phoneNumber,
          countryCode: item.countryCode
        }))


        localStorage.setItem('crSchema',JSON.stringify(crSchema))
        templateDetailHandler(temp)
        if(type === 1){
          renerHanler()
        }
      }else{
        if(title === ''){
          showToast('Please enter title', 'error')
          setTItleError('Please enter title')
        }
      }
  };




  return isPreviewVisible ? (
    <div className="templatepreview">
      <TemplatePreview  previewUrl={previewUrl}/>
    </div>
  ) : (
    <>

      <div className="dealroom_signer_main">
        <h3 className="dashboard_heading">Select Signers</h3>
        <ul className="select_signer_ul">
          <li className={`select_signer_li ${currentSigner === 1 ? "higlight-signer-box" : ''}`} onClick={() => setCurrenSigner(1)}>
            <img src={templateIcon} alt="signer" />
            <p className="font_14">Me</p>
          </li>
          <li className={`select_signer_li ${currentSigner === 2 ? "higlight-signer-box" : ''}`} onClick={() => setCurrenSigner(2)}>
            <img src={templateIcon} alt="signer" />
            <p className="font_14">Others & Me</p>
          </li>
          <li className={`select_signer_li ${currentSigner === 3 ? "higlight-signer-box" : ''}`} onClick={() => setCurrenSigner(3)}>
            <img src={templateIcon} alt="signer" />
            <p className="font_14">Only Others</p>
          </li>
        </ul>
        {signers.map((signer, index) => (
          <ul className="select_signer_ul add_signer_ul" key={index}>
            <li className="modal_main_li">
              <label>Name</label>
              <input
                type="text"
                placeholder="Name"
                value={signer.name}
                onChange={(e) =>
                  handleInputChange(index, "name", e.target.value)
                }
              />
            </li>
            <li className="modal_main_li">
              <label>Email</label>
              <div style={{position: 'relative', width:'100%'}}>
                <input
                  type="email"
                  placeholder="Email"
                  value={signer.email}
                  onChange={(e) =>
                    handleInputChange(index, "email", e.target.value)
                  }
                />
                {emailErrors[index] && (
                  <span className='_error_' style={{ position: 'absolute', bottom: '-20px', left: '0' }}>
                    Invalid email address
                  </span>
                )}
              </div>
            </li>

            <li className="modal_main_li">
              <label>Phone Number</label>
              <div style={{position: 'relative', width:'100%'}}>
                
              <PhoneInput
                value={`${signer.countryCode}${signer.phoneNumber}`}
                onChange={e => handleInputChange2(e, index)}
                placeholder="Mobile Number"
                countryCallingCodeEditable={false}
                defaultCountry="IN"
                limitMaxLength={true} />
                 {signer.phoneNumberErr ? <span style={{position: 'absolute',left: '50px', bottom: '-20px'}} className="cm_err">{signer.phoneNumberErr}</span> : ''}
              </div>

             
            </li>
            <li style={{width: '50px'}}>
              <img src={deleteicon} alt="deleteicon"onClick={() => removeSigner(index)} />
            </li>
          </ul>
        ))}
        {currentSigner !==1 && <CommonButton
          label="Add Participant"
          fontSize="14px"
          fontWeight="700"
          className={`dash_common_btn`}
          icon={plusicon}
          onClick={addSigner}
        />}

        <div className="dealroom_signer_lower">
          <h3 className="dashboard_heading">Add a message</h3>
          <ul className="modal_main_ul">
            <li className="modal_main_li">
              <label className="font_14">Document Title</label>
              <input
                type="text"
                placeholder="Document Title"
                maxlength="150"
                value={title}
                onChange={(e) => {setTitle(e.target.value ? capitalizeFirstLetter(e.target.value) : ''); setTItleError('')}}
              />
              {titleError!=='' && <span className='_error_'>{titleError}</span>}
            </li>
            <li className="modal_main_li">
              <label className="font_14">Message (Optional)</label>
              <input
              type="text"
              placeholder="Type here..."
              value={description}
              onChange={(e) => {setDescription(e.target.value ? capitalizeFirstLetter(e.target.value) : '')}}
              />
            </li>
          </ul>
        </div>
      </div>
      <div className="contract_main_button">
        <CommonButton
          label="Cancel"
          fontSize="14px"
          fontWeight="700"
          className="dash_common_btn white_bg_btn"
          icon={false}
          onClick={() => _cancelHandler()}
        />
        <CommonButton
          label="Next"
          fontSize="14px"
          fontWeight="700"
          className="dash_common_btn"
          icon={false}
          onClick={handleNextClick}
        />
      </div>
    </>
  );
}

export default memo(DealroomSigners)
