import { Fragment, useEffect, useState } from "react";
import { industry } from "../../utils/constant"; // assuming industry is an array of objects
const typesOptions = ["Proposal", "Contract"];

const TemplateFilter = ({viewType, callback}) => {
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [selectedIndustry, setSelectedIndustry] = useState([]);

    // Generic handler for both Types and Industry checkboxes
    const handleCheck = (e, category) => {
        const { value, checked } = e.target;
        if (category === 'Type') {
            setSelectedTypes((prev) =>
                checked ? [...prev, value] : prev.filter((item) => item !== value)
            );
        } else if (category === 'Industry') {
            setSelectedIndustry((prev) =>
                checked ? [...prev, value] : prev.filter((item) => item !== value)
            );
        }
    };

    useEffect(() => {
        callback(selectedTypes, selectedIndustry)
    }, [selectedTypes, selectedIndustry])

    return (
        <div className="template_filter">
            <h3 className="mt-0">Type</h3>
            <ul className="templategallery_left_ul">
                {typesOptions.map((item) => (
                    <li key={item}>
                        <input
                            value={item}
                            checked={selectedTypes.includes(item)}
                            type="checkbox"
                            onChange={(e) => handleCheck(e, 'Type')}
                        />{" "}
                        {item}
                    </li>
                ))}
            </ul>

            {
                viewType == 'templateGallery' ? <Fragment>
                        <h3>Industry</h3>
                            <ul className="templategallery_left_ul">
                                {industry.map((item) => (
                                    <li key={item.value}>
                                        <input
                                            value={item.value}
                                            checked={selectedIndustry.includes(item.value)}
                                            type="checkbox"
                                            onChange={(e) => handleCheck(e, 'Industry')}
                                        />{" "}
                                        {item.value}
                                    </li>
                                ))}
                            </ul>
                </Fragment> : ''
            }
            
        </div>
    );
};

export default TemplateFilter;
