import React, { useEffect, useState } from "react";
import TemplateImage from "./../../../assets/dashboard/dealroom/template_gallery.svg";
import CommonButton from "../../../components/common/CommonButton";
import searchicon from "./../../../assets/dashboard/Search.svg";
import filtericon from "./../../../assets/dashboard/filter_icon.svg";
import plusicon from "./../../../assets/dashboard/plus.svg";

export default function TemplateGallery({
  dealroomCreateNew,
  getTemplates,
  handlePreviewClick,
  resetDealRoomCreateNew
}) {
  const [checkboxes, setCheckboxes] = useState({
    types: {
      questionnaire: false,
      proposal: false,
      contract: false,
      form: false,
      blankFile: false,
    },
    industries: {
      Accounting: false,
      Advertising: false,
      Architecture: false,
      Art: false,
      Bakery: false,
      "Chartered Accountants": false,
      "Business Consulting": false,
      Calligraphy: false,
      Catering: false,
      Coaching: false,
      "Content Creation": false,
      Copywriting: false,
      Counseling: false,
      "Digital Marketing": false,
      "DJ/Entertainment": false,
      "Education/Coaching": false,
      "Event Planning": false,
      Exports: false,
      Fashion: false,
      Fitness: false,
      Florist: false,
      "Food & Drinks": false,
      Freelancer: false,
      "Graphic Design": false,
      "Hair & Makeup": false,
      "Health and Fitness": false,
      "Home Theater": false,
      Influencer: false,
      "Interior Design": false,
      Landscaping: false,
      "Law Firm": false,
      "Lighting/AV": false,
      Manufacturing: false,
      "Marketing Agency": false,
      "Marketing Consulting": false,
      Photography: false,
      Planning: false,
      "Printing and Packaging": false,
      "Real Estate": false,
      Rentals: false,
      "Social Media": false,
      "Software Development": false,
      Stationery: false,
      Stylists: false,
      Tutoring: false,
      Venue: false,
      Videography: false,
      "Virtual Assistants": false,
      "Web Design": false,
      "Wedding Rentals": false,
      "Wedding Planners": false,
    },
  });

  const [templateData, setTemplateData] = useState([]);

  const onCheckboxChange = (name) => {
    setCheckboxes((prevCheckboxes) => {
      const isType = Object.keys(prevCheckboxes.types).includes(name);
      if (isType) {
        return {
          ...prevCheckboxes,
          types: {
            ...prevCheckboxes.types,
            [name]: !prevCheckboxes.types[name],
          },
        };
      } else {
        return {
          ...prevCheckboxes,
          industries: {
            ...prevCheckboxes.industries,
            [name]: !prevCheckboxes.industries[name],
          },
        };
      }
    });
  };

  useEffect(() => {
    const storedUserData = localStorage.getItem("authUserData");
    if (storedUserData) {
      const parsedUserData = JSON.parse(storedUserData);
      const userIndustries = parsedUserData?.businessDetail?.industry || [];
      onCheckboxChange(userIndustries);
    }
  }, []);

  useEffect(() => {
    if (dealroomCreateNew) onCheckboxChange(dealroomCreateNew);
  }, [dealroomCreateNew]);

  useEffect(() => {
    if (getTemplates && getTemplates.length > 0) setTemplateData(getTemplates);
  }, [getTemplates]);

  const filterTemplates = () => {
    const selectedTypes = Object.keys(checkboxes.types).filter(
      (type) => checkboxes.types[type]
    );
    const selectedIndustries = Object.keys(checkboxes.industries).filter(
      (industry) => checkboxes.industries[industry]
    );

    return templateData.filter((template) => {
      const isTypeMatch =
        selectedTypes.length === 0 ||
        selectedTypes.includes(template.templateName.toLowerCase());
      const isIndustryMatch =
        selectedIndustries.length === 0 ||
        selectedIndustries.includes(template.industry);
      return isTypeMatch && isIndustryMatch;
    });
  };

  const filteredTemplates = filterTemplates();

  function replacePlaceholder(template, replacements) {
    for (const [key, value] of Object.entries(replacements)) {
      const regex = new RegExp(`\\b${key}\\b`, 'g');
      template = template.replace(regex, value);
    }
    return template;
  }

  function removeBrackets(input) {
    return input.replace(/[\[\]]/g, '');
  }

  const handleReplaceTemplate = (htmlString) => {
    const _projectDetails = JSON.parse(localStorage.getItem('projectDetail'));
    const _authUserData = JSON.parse(localStorage.getItem('authUserData'));

    const replacements = {
      'Project Name': _projectDetails?.projectName || '',
      'Client.FirstName': _projectDetails?.clients[0]?.clientName || '',
      'Client.Firstname': _projectDetails?.clients[0]?.clientName || '',
      'Client First Name': _projectDetails?.clients[0]?.clientName || '',
      'Client.LastName': _projectDetails?.clients[0]?.lastName || '',
      'Client.Lastname': _projectDetails?.clients[0]?.lastName || '',
      'Client Last Name': _projectDetails?.clients[0]?.lastName || '',
      'Client Address': _projectDetails?.clients[0]?.address,
      'Effective Date': new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: '2-digit' }).format(new Date()),
      'Sender.FirstName': _authUserData?.firstName || '',
      'Sender.Firstname': _authUserData?.firstName || '',
      'Sender.LastName': _authUserData?.lastName || '',
      'Sender.Lastname': _authUserData?.lastName || '',
      'Sender.Company Name': _authUserData?.businessDetail?.businessName || '',
      //'Sender.Company': _authUserData?.businessDetail?.businessName || '',
      'Sender.Company Address': _authUserData?.businessDetail?.businessAddress || '',

    };
    const result = replacePlaceholder(htmlString, replacements);
    const result2 = removeBrackets(result);
    handlePreviewClick(result2)
  }

  return (
    <div>
      <div className="templategallery_main">
        <div className="templategallery_left">
          <h2 className="font_14">Type</h2>
          <ul className="templategallery_left_ul">
          {/* "blankFile" */}
            {["questionnaire", "proposal", "contract", "form"].map(
              (type) => (
                <li key={type}>
                  <input
                    type="checkbox"
                    name={type}
                    checked={checkboxes.types[type]}
                    onChange={() => onCheckboxChange(type)}
                  />
                  <label className="font_14">
                    {type.charAt(0).toUpperCase() + type.slice(1)}
                  </label>
                </li>
              )
            )}
          </ul>

          <h2 className="font_14">Industry</h2>
          <ul className="templategallery_left_ul">
            {Object.keys(checkboxes.industries).map((industry) => {
              return (
                <li key={industry}>
                  <input
                    type="checkbox"
                    name={industry}
                    checked={checkboxes.industries[industry]}
                    onChange={() => onCheckboxChange(industry)}
                  />
                  <label className="font_14">{industry}</label>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="templategallery_right">
        <div className="dealroom_detail_upper _flex_row_spacebtw_">
            <div className="dealroom_detail_upper_left _flex_gap_30">
              <h4 className="font_16">{filteredTemplates.length} Templates</h4>
             
            </div>
            <div className="dealroom_detail_upper_right _flex_gap_30">
           
              <CommonButton
                label="Create New Templates"
                fontSize="14px"
                fontWeight="700"
                className={`dash_common_btn`}
                icon={plusicon}
                onClick={handlePreviewClick}
                />
            </div>
          </div>
         
          <div className="templategallery_right_main">
            {filteredTemplates.length > 0 &&
              filteredTemplates.map((template) => (
                <div
                  key={template.id}
                  className="templategallery_right_sub"
                  onClick={() => handleReplaceTemplate(template.htmlString)}
                >
                  <div className="templategallery_image_main_">
                    <img
                      src={TemplateImage}
                      className="templategallery_right_image"
                    />
                  </div>
                  <h3 className="font_16">{template.title}</h3>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="contract_main_button">
        <CommonButton
          label="Cancel"
          fontSize="14px"
          fontWeight="700"
          className="dash_common_btn white_bg_btn"
          icon={false}
          onClick={resetDealRoomCreateNew}
        />
      </div>
    </div>
  );
}
