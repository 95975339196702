import React, { useState, useEffect } from "react";
import uploaddoc from "./../../../assets/dashboard/upload_doc.svg";
import { PDFDocument } from "pdf-lib";
import mammoth from "mammoth";
import { SignatureUploadTemplate } from "./SignatureUploadTemplate";
import { postUploadDoc } from "../../../services";
import TemplatePreview from "./TemplatePreview";
import FileUpload from "./FileUpload";
import { Container } from "./Container";



export default function SignatureUpload({ closeSignatureModule, type, refreshSignersList }) {
  const [_type, set_type] = useState('');
  const [pdfUrl, setPdfUrl] = useState("");
  const [showTemplate, setShowTemplate] = useState(false);


  useEffect(() => {
    if(pdfUrl !== ''){
      setShowTemplate(true)
    }else{
      setShowTemplate(false)
    }
  },[pdfUrl])


  function replacePlaceholder(template, replacements) {
    for (const [key, value] of Object.entries(replacements)) {
      const regex = new RegExp(`\\[${key}\\]`, 'g');
      template = template.replace(regex, value);
    }
    return template;
  }




  const handleReplaceTemplate = (htmlString) => {
    const _projectDetails = JSON.parse(localStorage.getItem('projectDetail'));
    const _authUserData = JSON.parse(localStorage.getItem('authUserData'));

    const replacements = {
      'Project Name': _projectDetails?.projectName || '',
      'Client.FirstName': _projectDetails?.clients[0]?.clientName || '',
      'Client.Firstname': _projectDetails?.clients[0]?.clientName || '',
      'Client First Name': _projectDetails?.clients[0]?.clientName || '',
      'Client.LastName': _projectDetails?.clients[0]?.lastName || '',
      'Client.Lastname': _projectDetails?.clients[0]?.lastName || '',
      'Client Last Name': _projectDetails?.clients[0]?.lastName || '',
      'Client Address': _projectDetails?.clients[0]?.address,
      'Effective Date': new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }),
      'Sender.FirstName': _authUserData?.firstName || '',
      'Sender.Firstname': _authUserData?.firstName || '',
      'Sender.LastName': _authUserData?.lastName || '',
      'Sender.Lastname': _authUserData?.lastName || '',
      'Sender.Company Name': _authUserData?.businessDetail?.businessName || '',
      'Sender.Company': _authUserData?.businessDetail?.businessName || '',
      'Sender.Company Address': _authUserData?.businessDetail?.businessAddress || '',

    };
    return replacePlaceholder(htmlString, replacements);
  }



  const handlePdfGenerated = (url) => {
    if(Array.isArray(url)){
      set_type(1);
      //setPdfUrl(url[0].data.responseData.html_content);
      //localStorage.setItem('pdfUrl', url[0].data.responseData.html_content);
      setPdfUrl(handleReplaceTemplate(url[0].data.responseData.html_content));
      localStorage.setItem('pdfUrl', handleReplaceTemplate(url[0].data.responseData.html_content));
    }else{
      setPdfUrl(url);
      localStorage.setItem('pdfUrl', url)
    }
  };



  return (
    <>
      {showTemplate ? (
        <TemplatePreview
          previewUrl={pdfUrl}
          cancelHandler={closeSignatureModule}
          type={_type || type}
          _refreshSignersList={refreshSignersList} />
      ) : (
        <FileUpload
              onPdfGenerated={handlePdfGenerated}
              cancelHandler={closeSignatureModule} />
      )}
    </>
  );
}
