
import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import CommonButton from "./common/CommonButton";

const SignatureModal = ({ isOpen, onClose, onSave }) => {
  const sigCanvas = useRef({});
  const [selectedOption, setSelectedOption] = useState(0)
  const [uploadedSignature, setUploadedSignature] = useState(null);

  const handleSave = () => {
    let signatureData;
    if (selectedOption === 1 && uploadedSignature) {
      signatureData = uploadedSignature;
    } else {
      signatureData = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
    }
    onSave(signatureData);
    setSelectedOption(0);
    setUploadedSignature(null);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "image/png") {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedSignature(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      alert("Please upload a PNG file.");
    }
  };

  if (!isOpen) return null;

  const optionHandler = (option) => {
    setSelectedOption(option);
  };

  const closeHandler = () => {
    onClose();
    setSelectedOption(0);
    setUploadedSignature(null);
  };

  return (
    <div className="signature-modal">
      <div className="modal-content">
        {selectedOption !== 0 ? (
          selectedOption === 1 ? (
            <div className="file-upload-wrapper">
              <input
                type="file"
                accept="image/png"
                onChange={handleFileChange}
              />
              {uploadedSignature && (
                <img
                  className="signature_img"
                  src={uploadedSignature}
                  alt="Uploaded Signature"/>
              )}
            </div>
          ) : (
            <SignatureCanvas
              ref={sigCanvas}
              canvasProps={{ width: 276, height: 115, className: 'sigCanvas'}} />
          )
        ) : (
          <div className="signs-options-wrapper">
            <div className="signs-options" onClick={() => optionHandler(1)}>
              <div className="signs-options_inner">
                <h4>Upload Signature</h4>
              </div>
            </div>

            <div className="signs-options" onClick={() => optionHandler(2)}>
              <div className="signs-options_inner">
              <h4>Create Signature</h4>
              </div>
            </div>

          </div>
        )}
        <div className="signature-modal-button">
          <CommonButton
            label="Close"
            fontSize="14px"
            fontWeight="700"
            className={`dash_common_btn`}
            icon=''
            onClick={closeHandler}
          />
          <CommonButton
            label="Save"
            fontSize="14px"
            fontWeight="700"
            icon=''
            className={`dash_common_btn`}
            onClick={handleSave}
          />
        </div>
      </div>
    </div>
  );
};

export default SignatureModal;

