import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import rightarrow from "./../assets/logos/right_arrow.svg";
import crossicon from "./../assets/dashboard/close.svg";
import plusicon from "./../assets/dashboard/pluscircle.svg";
import PhoneInput, {formatPhoneNumberIntl} from "react-phone-number-input";
import useValidation from "../utils/customHooks/useValidations";
import { separateCountryCodeAndNumber } from "../utils/helper";
import {
  getClientbyBusinessIdService,
  updateClientService,
  createClientService,
  addClientToProjectService,
  updateProjectService,
  addProjectService
} from "../services";
import useToast from "../utils/customHooks/useToast";
import Loader from "../components/common/Loader";

const ClientDetails = ({
  isFromNewProject,
  onHide,
  openProject,
  from,
  callProjectList,
  _refreshPipeLine,
  type = "",
  id = null,
  updateProjectList = null
}) => {
  let projectDetail = JSON.parse(localStorage.getItem("projectDetail"));
  let newProjectDetail = JSON.parse(localStorage.getItem("newProjectDetail"));
  let userDetail = JSON.parse(localStorage.getItem("authUserData"));

  const [allClientsName, setAllClientsName] = useState([]);
  const [allClientsDetails, setAllClientDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [tagError, setTagError] = useState("");
  const [colorError, setColorError] = useState("");

  const [clientDetails, setClientDetails] = useState({
    clientName: "",
    firstName: "",
    lastName: "",
    email: "",
    country_code: "",
    phone_number: "",
    status: "1",
    clientTag: [],
    address: "",
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagName, setTagName] = useState("");
  const [tagColor, setTagColor] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [isOptionLoading, setIsOptionLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [preFilledNumberItems, setPreFilledNumberItems] = useState({
    _cc: "",
    _number: "",
  });
  const [clientNameError, setClientNameError] = useState("");
  const showToast = useToast();



  useEffect(() => {
    getAllClients();
  }, []);

  useEffect(() => {
    if (
      preFilledNumberItems._cc !== "" &&
      preFilledNumberItems._number !== ""
    ) {
      let temp = {
        _cc: "",
        _number: "",
      };
      setPreFilledNumberItems(temp);
    }
  }, [phoneNumber]);



  const getAllClients = async () => {
    let businessDetails = JSON.parse(localStorage.getItem("authUserData")) || null;
    if (businessDetails) {
      setIsOptionLoading(true);
      let businessId = businessDetails?.businessDetail?.businessId || null;
      if (businessId) {
        try {
          const res = await getClientbyBusinessIdService(businessId);
          if (res.data.status === 1) {
            const uniqueClients = [];
            const clientNames = new Set();
            
            res.data.responseData.clients.forEach(client => {
              const lowerCaseName = client.clientName.toLowerCase();
              if (!clientNames.has(lowerCaseName)) {
                clientNames.add(lowerCaseName);
                uniqueClients.push(client);
              }
            });
  
            uniqueClients.sort((a, b) => {
              const firstNameA = a.clientName.split(" ")[0].toLowerCase();
              const firstNameB = b.clientName.split(" ")[0].toLowerCase();
              return firstNameA.localeCompare(firstNameB);
            });
  
            setAllClientDetails(uniqueClients);
            const clientData = uniqueClients.filter(jj => jj.firstName && jj.lastName).map(client => ({
              ...client,
              value: client.clientName,
              label: client.clientName,
            }));
            setAllClientsName(clientData);
          }
        } catch (error) {
          console.error("Error fetching clients:", error);
        } finally {
          setIsOptionLoading(false);
        }
      }
    }
  };
  

  const handleCreateOption = (inputValue) => {
    setIsOptionLoading(true);
    setTimeout(() => {
      const newOption = { label: inputValue, value: inputValue };
      setIsOptionLoading(false);
      setAllClientsName((prev) => [...prev, newOption]);
      setSelectedOption(newOption); // Update the selected option here
    }, 1000);
  };

  const {
    errors,
    setErrors,
    validateForm,
    runTimeErrors,
    setRunTimeErrors,
    validateRunTimeError,
  } = useValidation();

  const addTag = (e) => {
    e.preventDefault();
    let hasError = false;

    if (!tagName) {
      setTagError("Please enter a tag name.");
      hasError = true;
    } else {
      setTagError("");
    }

    if (!selectedColor) {
      setColorError("Please select a color.");
      hasError = true;
    } else {
      setColorError("");
    }

    if (!hasError) {
      setTags([...tags, { name: tagName, color: tagColor }]);
      setTagName("");
      setTagColor("");
      setSelectedColor("");
      document.getElementById("addTagForm").style.display = "none";
    }
  };

  const deleteTag = (index) => {
    setTags(tags.filter((_, i) => i !== index));
  };

  const handleColorSelect = (color) => {
    setTagColor(color);
    setSelectedColor(color);
    setColorError(''); 

  };

  const handleTagNameChange = (e) => {
    const newTagName = e.target.value.replace(/^\w/, (c) => c.toUpperCase());
    setTagName(newTagName);
    if (newTagName === "") {
      setTagError("Please enter a tag name.");
    } else {
      setTagError("");
    }
  };


  const handleCancel = () => {
    onHide();
    openProject();
  };


  const handleKeyDown = (e) => {
    if (/[^A-Za-z]\s/.test(e.key)) {
      e.preventDefault(); // Prevent input of numeric and special characters
    }
  };

  const validatePhone = (no) => {
    if (no === "") return false;
    else return true;
  };


  const handleValidate = () => {
    const fieldsToValidate = {
      firstName: 'First name is required',
      lastName: 'Last name is required',
      email: 'Email is required',
      phoneNumber: 'Phone number is required',
    };
  
    let isValid = true;
    let newErrors = {...errors};
  
    // Email format validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    Object.keys(fieldsToValidate).forEach((field) => {
      if (!eval(field)) {
        newErrors[field] = fieldsToValidate[field];
        isValid = false;
      } else if (field === 'email' && !emailRegex.test(email)) {
        newErrors.email = 'Please enter a valid email address';
        isValid = false;
      } else {
        delete newErrors[field]; // Remove the error if the field is valid
      }
    });
  
    setErrors(newErrors);
    return isValid;
  };
  
  

  const handleAdd = () => {

    if(handleValidate()){
      setIsLoading(true);

      if(isFromNewProject){
        let newProjectId = ''
        if(newProjectDetail?.projectName){
          let projectObj = {
            businessId: userDetail?.businessDetail?.businessId || userDetail?.businessDetail?.id,
            projectStatus: newProjectDetail.projectStatus,
            projectStage: newProjectDetail.projectStage,
            projectName: newProjectDetail.projectName,
            leadSource: newProjectDetail.leadSource
          }

          addProjectService(projectObj).then(res => {
            if (res.data.status) {
              localStorage.removeItem('newProjectDetail');
              newProjectId = res?.data?.responseData?.projectData?.id || '';

            

              const { cc, number } = separateCountryCodeAndNumber(phoneNumber || '');

              let data = {
                clientName: `${firstName} ${lastName}`,
                firstName: firstName,
                lastName: lastName,
                country_code: cc,
                phone_number: number,
                address: address,
                email: email,
                clientTag: tags,
              }


              if(selectedOption?.id){
                data.isPrimary = 1;
                updateClientService(newProjectId, selectedOption.id, data).then((res) => {
                  if (res.statusCode === 200) {
                    if (res.data.status === 1) {

                      let params = {
                        clientId: selectedOption.id,
                        projectId: newProjectId
                      }
        
        
                      addClientToProjectService(params).then((res) => {
                        if (res.statusCode === 200) {
                          if (res.data.status === 1) {
                            let temp = {
                              id: newProjectId,
                              clientEmail: email
                            }
                                updateProjectService(temp).then(res => {
                                    if(res.data.status){
                                      setIsLoading(false);
                                      if(callProjectList){
                                        callProjectList()
                                      }
                                    }
                                })
                            onHide();
                            showToast("Client Added successfully", "success");
                          } else {
                            setIsLoading(false)
                          }
                        }
                      })



                      setIsLoading(false)
                      if(callProjectList){
                        callProjectList()
                      }
                      onHide();
                      showToast("Client details updated successfully", "success");
                    } else {
                      setIsLoading(false)
                    }
                  }
                })
        
              }else{
        
                data.isPrimary = 1;
                data.businessId = userDetail?.businessDetail?.businessId;
        
                createClientService(data).then((res) => {
                  if (res.statusCode === 200) {
                    if (res.data.status === 1) {
        
                      let params = {
                        clientId: res.data.responseData.clientData.id,
                        projectId: newProjectId
                      }
        
        
                      addClientToProjectService(params).then((res) => {
                        if (res.statusCode === 200) {
                          if (res.data.status === 1) {

                            let temp = {
                              id: newProjectId,
                              clientEmail: email
                            }
                            updateProjectService(temp).then(res => {
                                if(res.data.status){
                                  setIsLoading(false);
                                  if(callProjectList){
                                    callProjectList()
                                  }
                                }
                            })
                            onHide();
                            showToast("Client Added successfully", "success");
                          } else {
                            setIsLoading(false)
                          }
                        }
                      })
        
                    } else {
                      setIsLoading(false)
                    }
                  }
                })
        
        
        
        
              }



            }
          } )
      }
      }else{

        const { cc, number } = separateCountryCodeAndNumber(phoneNumber || '');

        let data = {
          clientName: `${firstName} ${lastName}`,
          firstName: firstName,
          lastName: lastName,
          country_code: cc,
          phone_number: number,
          address: address,
          email: email,
          clientTag: tags
        }


           
      if(selectedOption?.id){
        data.isPrimary = 1;
        updateClientService(projectDetail.id, selectedOption.id, data).then((res) => {
          if (res.statusCode === 200) {
            if (res.data.status === 1) {
              setIsLoading(false)
              updateProjectList();
              onHide();
              showToast("Client details updated successfully", "success");
            } else {
              setIsLoading(false)
            }
          }
        })

      }else{

        data.isPrimary = 1;
        data.businessId = userDetail?.businessDetail?.businessId;

        createClientService(data).then((res) => {
          if (res.statusCode === 200) {
            if (res.data.status === 1) {

              let params = {
                clientId: res.data.responseData.clientData.id,
                projectId: projectDetail.id
              }


              addClientToProjectService(params).then((res) => {
                if (res.statusCode === 200) {
                  if (res.data.status === 1) {
                    setIsLoading(false)
                    updateProjectList()
                    onHide();
                    showToast("Client Added successfully", "success");
                  } else {
                    setIsLoading(false)
                  }
                }
              })

            } else {
              setIsLoading(false)
            }
          }
        })

      }
  
      }


      

     

   



   

    }

  };

  const handleClienNameChange = (e) => {
    if (e.length > 20) {
      e.preventDefault();
    }
  };

  const handleSelectKeyDown = (e) => {
    const input = e.target;
    if (input.value.length >= 20 && !/[^A-Za-z\s.]/.test(e.key)) {
      e.preventDefault();
    } else if (/[^A-Za-z\s.]/.test(e.key)) {
      e.preventDefault();
    }
  };


  useEffect(() => {
    if(selectedOption?.id){
        setFirstName(selectedOption.firstName);
        setlastName(selectedOption.lastName);
        setEmail(selectedOption.email);
        setAddress(selectedOption.address);
        setPhoneNumber(`${selectedOption.country_code} ${selectedOption.phone_number}`)
    }else{
      setFirstName('');
      setlastName('');
      setEmail('');
      setAddress('');
      setPhoneNumber('')
    }
  }, [selectedOption])



  const changePhoneNumber = (num) => {
    setErrors({...errors, phoneNumber : '' })
    let _num = formatPhoneNumberIntl(num)
    setPhoneNumber(_num)
  }

  return (
    <Modal show={true} onHide={onHide} backdrop="static">
      <div className="modal_main_div">
        <h2 className="modal_heading">
          {type !== "update" ? "Client Details" : "Update Client Details"}
        </h2>
        <ul className="modal_main_ul">
          <li className="modal_main_li">
            <label className="font_14">Add Existing Client</label>
            <CreatableSelect
              isClearable
              isDisabled={isOptionLoading}
              isLoading={isOptionLoading}
              onChange={e => setSelectedOption(e)}
              onCreateOption={handleCreateOption}
              options={allClientsName}
              value={selectedOption}
              onInputChange={(e) => handleClienNameChange(e)}
              onKeyDown={handleSelectKeyDown} />

            <div className="_error_">{clientNameError}</div>
          </li>

          <li className="line_or"><span>Or</span></li>

          
          <li className="modal_main_li">
            <div className="modal_two_field">
              <div className="modal_two_field_left">
                <label className="font_14">
                  First Name<span className="_imp_">*</span>
                </label>
                <input
                  type="text"
                  value={firstName}
                  placeholder="First Name"
                  maxLength={50}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => (setFirstName(e.target.value), setErrors({...errors, firstName: ''}))}/>
                <div className="_error_">
                  {errors?.firstName || runTimeErrors?.firstName}
                </div>
              </div>
              <div className="modal_two_field_left">
                <label className="font_14">
                  Last Name<span className="_imp_">*</span>
                </label>
                <input
                  type="text"
                  value={lastName}
                  placeholder="Last Name"
                  maxLength={50}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => (setlastName(e.target.value), setErrors({...errors, lastName: ''}))}/>
                <div className="_error_">
                  {errors?.lastName || runTimeErrors?.lastName}
                </div>
              </div>
            </div>
          </li>
          <li className="modal_main_li">
            <label className="font_14">
              Email<span className="_imp_">*</span>
            </label>
            <input
              type="email"
              placeholder="xyz@gmail.com"
              value={email}
              maxLength={50}
              onChange={(e) => (setEmail(e.target.value), setErrors({...errors, email: ''}))}/>
            <div className="_error_">
              {errors?.email || runTimeErrors?.email}
            </div>
          </li>
          <li className="" style={{ marginBottom: "16px" }}>
            <div className="_label_ ">
              Mobile Number<span className="_imp_">*</span>
            </div>


            <div className={`_PhoneInput_`}>
              <PhoneInput
                international={phoneNumber ? true : false}
                placeholder="Mobile Number"
                countryCallingCodeEditable={false}
                defaultCountry="IN"
                value={phoneNumber}
                onChange={changePhoneNumber}
                limitMaxLength={true}
              />
            </div>

            <div className="_error_">
              {errors?.phoneNumber || runTimeErrors?.phoneNumber}
            </div>


            {phoneError && <span className="_error_">{phoneError}</span>}
          </li>
          <li className="modal_main_li">
            <label className="font_14">
              Address <span>(optional)</span>
            </label>
            <input
              type="text"
              placeholder="Address"
              value={clientDetails.address || address}
              onChange={(e) => {
                setClientDetails({ ...clientDetails, address: e.target.value });
                setAddress(e.target.value);
              }}
            />
          </li>

          {type !== "update" ? (
            <>
              <li className="modal_main_li">
                <label className="font_14">
                  Client Tags <span>( Not visible to the client )</span>
                </label>
                <div className="client_tag_div">
                  {tags.map((tag, index) => (
                    <span key={index} style={{ backgroundColor: tag.color }}>
                      {tag.name}
                      <img
                        src={crossicon}
                        alt="Delete"
                        onClick={() => deleteTag(index)}
                      />
                    </span>
                  ))}
                  <img
                    src={plusicon}
                    alt="Add"
                    onClick={() =>
                      (document.getElementById("addTagForm").style.display =
                        "block")
                    }
                  />
                </div>
              </li>

              <form
                id="addTagForm"
                className="modal_main_li"
                style={{ display: "none" }}
                onSubmit={addTag}>
        
                <input type="text" placeholder="Name Tag" value={tagName} onChange={handleTagNameChange} maxLength={20} />

                {tagError && (
                  <div className="error-message _error_">{tagError}</div>
                )}

                <div className="select_color_div">
                  <label className="font_14 select_color_label">
                    Select Color :
                  </label>
                  <div className="color-selector">
                    {[
                      "#EF4444",
                      "#F97316",
                      "#FACC15",
                      "#4ADE80",
                      "#2DD4BF",
                      "#3B82F6",
                      "#6366F1",
                      "#EC4899",
                      "#F43F5E",
                      "#D946EF",
                      "#8B5CF6",
                      "#0EA5E9",
                      "#10B981",
                      "#84CC16",
                    ].map((color) => (
                      <div className="modal_selector_div" key={color}>
                        <button
                          type="button"
                          style={{ backgroundColor: color }}
                          className={selectedColor === color ? "active" : ""}
                          onClick={() => handleColorSelect(color)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                {colorError && (
                  <div className="error-message _error_">{colorError}</div>
                )}

                <button type="submit" className="create_modal_btn">
                  Create
                </button>
              </form>
            </>
          ) : (
            ""
          )}
        </ul>
      </div>
      <div className="modal_btn_div modal_color_btn_div">
        <button
          className="_btn_transparent_ modal_div_button"
          onClick={handleCancel}
        >
          <span>Cancel</span>
        </button>
        <button
          disabled={isLoading}
          className={`_btn_normal_ modal_div_button ${
            isLoading ? "_disable_" : ""
          }`}
          onClick={handleAdd}
        >
          {isLoading ? <Loader /> : ''}
          <span>{selectedOption?.id ? "UPDATE" : "ADD"}</span>
          <span className="_right_btn_icon_">
            <img src={rightarrow} alt="arrow" />
          </span>
        </button>
      </div>
    </Modal>
  );
};

export default ClientDetails;
