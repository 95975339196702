import React, { useEffect, useState } from "react";
import Select from "react-select";
import "../projects/notesAndTasks/Tabs.css";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getMembersByBusinessIdService,
  getProjectByBusinessService,
  getTasksList,
  createTask,
  UpdateTask,
  DeleteTask,
} from "../../services";
import ReactSelect from "react-select";

import DeleteIcon from "../../assets/dashboard/delete.svg";
import useToast from "../../utils/customHooks/useToast";
import DeleteModal from "../../modals/DeleteModal";
import TableDateComponent from "../projects/projectManagement/TableDateComponent";
import GlobalSearch from "../../components/common/GlobalSearch";
import { useDispatch } from "react-redux";
import { logout } from "../../features/auth/authSlice";
import { persistor } from "../../store";
import searchIcon from "../../assets/dashboard/Search.svg";

const GlobalTask = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

  let projectDetails = JSON.parse(localStorage.getItem("projectDetail"));
  let authUserData = JSON.parse(localStorage.getItem("authUserData"));

  const [tasks, setTasks] = useState([]);
  const [initialTasks, setInitialTasks] = useState([]);
  const showToast = useToast();
  const location = useLocation();
  const { id } = location.state || {};

  const token = localStorage.getItem("token");
  const [dropDownData, setDropDownData] = useState([]);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [taskToDeleteIndex, setTaskToDeleteIndex] = useState(null);

  const [dueDateErrors, setDueDateErrors] = useState(
    Array(tasks.length).fill("")
  );
  const [taskTitleErrors, setTaskTitleErrors] = useState(
    Array(tasks.length).fill("")
  );

  const [projectsList, setProjectList] = useState([]);
  const [selectedProject, setSelectedProject] = useState({});
  const [searchTerm, setSearchTerm] = useState('');

  const [fSharedWith, setFSharedWith] = useState('');
  const [fAssigneeTo, setFAssigneeTo] = useState('');
  const [fStatus, setFStatus] = useState('');
  const [submitDisable, setSubmitDisable] = useState(false);

  

  // Define sharing options
  const sharingOptions = [
    { value: 1, label: "Me Only" },
    { value: 2, label: "Team" },
    { value: 3, label: "Team + Client" },
  ];



  const handleLogout = () => {
    localStorage.clear();
    dispatch(logout());
    persistor.purge()
    navigate("/signin");
};



const getAllProjects = async () => {
    try {
        const res = await getProjectByBusinessService(authUserData?.businessDetail?.businessId);
        let _projects = res.data.responseData.projectData || [];
        if(_projects.length > 0) {
            _projects = _projects.map(item => ({...item, label: item.projectName, value: item.id}))
            setProjectList(_projects);
        }
    } catch (error) {
        console.error('Error fetching projects:', error);
    }
};


useEffect(() => {
    if(authUserData?.businessDetail?.businessId){
        getAllProjects();
    }
    
}, [])



  useEffect(() => {
    const fetchMembersData = async () => {
      try {
        const authUser = {
          firstName: authUserData?.firstName,
          lastName: authUserData?.lastName,
          email: authUserData?.emailId,
          label: "Login",
        };

        const data = await getMembersByBusinessIdService(
          authUserData?.businessDetail?.businessId
        );
        const membersData = data?.data?.responseData?.members || [];
        const membersList = membersData.map((member) => ({
          firstName: member.firstName,
          lastName: member.lastName,
          email: member.email,
          label: "Member",
        }));

        const clientList = (projectDetails?.clients || []).map((client) => ({
          firstName: client.firstName,
          lastName: client.lastName,
          email: client.email,
          label: "Client",
        }));

        const combinedData = [authUser, ...membersList, ...clientList];
        setDropDownData(combinedData);
      } catch (error) {
        console.error("Error fetching members or clients data:", error);
      }
    };
    fetchMembersData();
  }, []);

  const fetchTaskData = async () => {
    const projectId = projectDetails?.id || "";
    const businessId = authUserData?.businessDetail?.businessId;

    let query = `?businessId=${businessId}`;
    // if (!isGlobal || (isGlobal && selectedProject.value)) {
    //   const effectiveProjectId = isGlobal ? selectedProject.value : projectId;
    //   query += `&projectId=${effectiveProjectId}`;
    // }

    const getList = await getTasksList(query, token);
    setInitialTasks(getList?.data?.responseData?.data);
    setTasks(getList?.data?.responseData?.data);
  };

  useEffect(() => {
    fetchTaskData();
  }, [id, selectedProject]);

  function checkValidation(index, field, value) {
    const newDueDateErrors = [...dueDateErrors];
    const newTaskTitleErrors = [...taskTitleErrors];

    if (field === "dueDate") {
      if (value === "") {
        newDueDateErrors[index] = "Due Date is required.";
      } else {
        newDueDateErrors[index] = "";
      }
    }

    if (field === "taskTitle") {
      if (value === "") {
        newTaskTitleErrors[index] = "Task Title is required.";
      } else {
        newTaskTitleErrors[index] = "";
      }
    }

    setTaskTitleErrors(newTaskTitleErrors);
    setDueDateErrors(newDueDateErrors);
  }

  const handleNewTaskChange = (index, field, value) => {
    const updatedTasks = [...tasks];

    if(field === 'taskProject'){
        updatedTasks[index] = {
            ...updatedTasks[index],
            projectId: value.value,
            projectName: value.label,
        };


    }else if (field === "assingTo") {
      updatedTasks[index] = {
        ...updatedTasks[index],
        [field]: value
          ? {
              firstName: value.firstName,
              lastName: value.lastName,
              email: value.value,
            }
          : {},
      };
    } else {
      updatedTasks[index] = { ...updatedTasks[index], [field]: value };
    }

    checkValidation(index, field, value);
    if (field === "sharing" && value === 1) {
      updatedTasks[index].assingTo = {
        firstName: authUserData?.firstName,
        lastName: authUserData?.lastName,
        email: authUserData?.emailId,
      };
    }
    setTasks(updatedTasks);
  };

  const addNewTask = () => {
    const newTask = {
      id: null,
      taskTitle: "",
      taskDescription: "",
      assingTo: {},
      dueDate: "",
      projectId: id,
      sharing: 1,
      taskStatus: 1,
    };

    if (newTask.sharing === 1) {
      newTask.assingTo = {
        firstName: authUserData?.firstName,
        lastName: authUserData?.lastName,
        email: authUserData?.emailId,
      };
    }

    setTasks([...tasks, newTask]);
  };

  const handleSaveTask = async (index) => {
    const task = tasks[index];

    if (task?.taskTitle === "" || task?.dueDate === "" || task.projectId == "") {
      if (
        (taskTitleErrors[index] && taskTitleErrors[index] !== "") ||
        taskTitleErrors[index] === undefined ||
        (dueDateErrors[index] && dueDateErrors[index] !== "") ||
        dueDateErrors[index] === undefined
      ) {
        showToast("Please fill all the required fields", "error");
        return;
      }
    }

    const params = {
        projectId: task.projectId,
        projectName: task.projectName,
        sharing: task.sharing,
        assingTo: task.assingTo,
        dueDate: task.dueDate,
        taskDescription: "",
        taskTitle: task.taskTitle,
        taskStatus: 6,
        userId: authUserData?.userId
    };


    setSubmitDisable(true)

    try {
      if (task.id) {
        await UpdateTask(params, task.id, token);
        showToast("Task updated successfully:", "success");
      } else {
        await createTask(params, token);
        showToast("Task created successfully", "success");
      }

      setSubmitDisable(false)

      fetchTaskData();
    } catch (error) {
      console.error("Error saving task:", error);
    }
  };

  const confirmDeleteTask = (index) => {
    setTaskToDeleteIndex(index);
    setShowDeletePopup(true);
  };

  const handleDeleteTask = async () => {
    const newTasks = [...tasks];
    const taskToDelete = newTasks[taskToDeleteIndex];

    try {
      await DeleteTask(taskToDelete.id, token);
      newTasks.splice(taskToDeleteIndex, 1);
      setTasks(newTasks);
      setShowDeletePopup(false);
      showToast("Task deleted successfully", "success");
    } catch (error) {
      console.error("Error deleting task:", error);
    }
  };

  const handleCheckboxChange = async (index, checked) => {
    const task = tasks[index];
    const newStatus = checked ? 11 : 6;
    const params = {
      projectName: task.projectName,
      sharing: task.sharing,
      assingTo: task.assingTo,
      dueDate: task.dueDate,
      taskDescription: "",
      taskTitle: task.taskTitle,
      taskStatus: newStatus,
      projectId: task.projectId,
    };

    try {
      await UpdateTask(params, task.id, token);
      const updatedTasks = [...tasks];
      updatedTasks[index] = { ...updatedTasks[index], taskStatus: newStatus };
      showToast("Task Updated successfully", "success");
      setTasks(updatedTasks);
    } catch (error) {
      console.error("Error updating task status:", error);
    }
  };

  const formattedOptions = dropDownData.map((person) => ({
    value: person.email,
    label: (
      <div className="tabs_dropdown-option">
        <div className="name">{`${person.firstName} ${person.lastName}`}</div>
        <div className="email">{person.email}</div>
      </div>
    ),
    firstName: person.firstName,
    lastName: person.lastName,
  }));


  useEffect(() => {  
        const filteredData = initialTasks.filter((task) => {
            const matchesProject = selectedProject.value ? task.projectId == selectedProject.value : true;
            const matchesSearchTerm = searchTerm ? task.taskTitle.toLowerCase().includes(searchTerm.toLowerCase()) : true;
            const matchesStatus = fStatus.value ? task.taskStatus == fStatus.value : true;
            const matchesAssignee = fAssigneeTo?.value ? task.assingTo.email == fAssigneeTo.value : true;
            const matchesSharedWith = fSharedWith.value ? task.sharing == fSharedWith.value : true;
      
            return matchesSearchTerm && matchesStatus && matchesAssignee && matchesSharedWith && matchesProject;
          });
        
          setTasks(filteredData);



  }, [searchTerm, fAssigneeTo, fStatus, fSharedWith, selectedProject, initialTasks]);
  

  return (

    <>
        <div className="dashboard_upper projectdetails_dash_upper">
            <div className="projectdetails_dash_left">
              {authUserData?.businessDetail?.logo ? <img src={authUserData?.businessDetail?.logo} className="fav_star" alt="Star Toggle"/> : ''}
                
                <h3>{authUserData?.businessDetail?.businessName}</h3>
            </div>
            <div className="projectdetails_dash_left">
                <GlobalSearch />
                <div className="logout_main_div">
                    <div className="name_intials_dash_upper">
                        {authUserData.firstName ? authUserData.firstName.charAt(0).toUpperCase() : ''}{authUserData.lastName ? authUserData.lastName.charAt(0).toUpperCase() : ''}
                    </div>
                    <div className="logout_div">
                        <p onClick={handleLogout}>Logout</p>
                    </div>
                </div>
            </div>
        </div>

        <div className="page_wrapper" style={{background: '#fff'}}>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <div>
                    <h3>Tasks</h3>
                </div>
                <div>
                    <div className="cm_search">
                        <img src={searchIcon} alt="searchicon" />
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Search"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                </div>
            </div>

            <div className="section_filter">
                <div className="row align-items-end">
                    <div className="col-lg-3 col-md-6">
                        <Select
                            classNamePrefix="cm_ui_select"
                            value={selectedProject.value ? selectedProject : ''}
                            onChange={e => setSelectedProject(e)}
                            options={[
                                {label: 'All', value: ''},
                                ...projectsList
                            ]}
                            placeholder="All Projects" />
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <label>Status</label>
                        <Select
                            classNamePrefix="cm_ui_select"
                            value={fStatus.value ? fStatus : ''}
                            onChange={e => setFStatus(e)}
                            options={[{label: 'All', value: ''},{label: 'incomplete', value: 6},{label: 'Completed', value: 11}]}
                            placeholder="Select..." />
                    </div>


                    <div className="col-lg-3 col-md-6">
                    <label>Shared With</label>
                        <Select
                            classNamePrefix="cm_ui_select"
                            value={fSharedWith.value ? fSharedWith : ''}
                            onChange={e => setFSharedWith(e)}
                            options={[{label: 'All', value: ''}, ...sharingOptions]}
                            placeholder="Select..." />
                    </div>


                    <div className="col-lg-3 col-md-6">
                        <label>Assignee To</label>
                        <Select
                            classNamePrefix="cm_ui_select"
                            value={fAssigneeTo.value ? {value: fAssigneeTo.email, label: `${fAssigneeTo?.firstName} ${fAssigneeTo?.lastName}`} : null}
                            onChange={e => setFAssigneeTo(e)}
                            options={[
                                {label: 'All', value: ''},
                                ...formattedOptions,
                            ]}
                            placeholder="Select..." />
                    </div>
                </div>
            </div>


            <div style={{height: 'calc(100vh - 323px)', overflowY: 'scroll'}}>
                <table className="project_table dealroom_table task_table" style={{marginTop:"0px"}}>
                    <thead>
                    <tr>
                        <th style={{ width: "5%" }}></th>
                        <th style={{ width: "30%" }}>Task Name</th>
                        <th style={{ width: "15%" }}>Shared With</th>
                        <th style={{ width: "15%" }}>Assigned To</th>
                        <th style={{ width: "18%" }}>Due Date</th>
                        <th style={{ width: "12%" }}></th>
                    </tr>
                    </thead>
                    <tbody >
                    {tasks.map((task, index) => (
                        <tr key={index}>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={task.taskStatus === 11}
                                    onChange={(e) => handleCheckboxChange(index, e.target.checked)}/>
                            </td>

                        <td className="two_input_td">
                            <div>
                                <input
                                    type="text"
                                    placeholder="Task Title"
                                    style={{ border: "none" }}
                                    value={task.taskTitle}
                                    onChange={(e) => handleNewTaskChange(index, "taskTitle", e.target.value)} />
                                    
                                {taskTitleErrors[index] && ( <div className="error">{taskTitleErrors[index]}</div> )}
                            </div>
                            <div>
                                <Select
                                    classNamePrefix="cm_ui_select"
                                    value={task.projectName ? {label: task.projectName, value: task.projectId} : null}
                                    options={projectsList}
                                    onChange={(e) => handleNewTaskChange(index, "taskProject", e)}
                                    placeholder="Select Project" />
                            </div>
                        </td>
                        <td>
                            <select
                                value={task.sharing}
                                onChange={(e) => handleNewTaskChange(index, "sharing", parseInt(e.target.value))}
                                className="tabs_dropdown">
                                {sharingOptions.map((option) => (<option key={option.value} value={option.value}>{option.label}</option>))}
                            </select>
                        </td>
                        <td>
                            <ReactSelect
                                value={task?.assingTo ? {value: task?.assingTo.email, label: `${task?.assingTo?.firstName} ${task?.assingTo?.lastName}`} : null}
                                onChange={(selectedOption) => handleNewTaskChange(index, "assingTo", selectedOption)}
                                options={formattedOptions}
                                className="styled-select"
                                placeholder="Select Assignee"/>
                        </td>
                        <td>
                            <div className="bordered-date-picker">
                            <TableDateComponent
                                date={task.dueDate}
                                onChange={(e) => handleNewTaskChange(index, "dueDate", e.target.value)}/>
                            </div>
                            {dueDateErrors[index] && (<div className="error">{dueDateErrors[index]}</div>)}
                        </td>
                        <td className="save_btn_td">
                            <button disabled={submitDisable} onClick={() => handleSaveTask(index)}>{task.id ? "Update" : "Save"}</button>
                            <img src={DeleteIcon} style={{ width: "22px", height: "24px", cursor: "pointer" }} alt="Delete" onClick={() => confirmDeleteTask(index)}/>
                        </td>
                        </tr>
                    ))}

                        <tr>
                            <td colSpan={6} className="add_task_td">
                                <p onClick={addNewTask}><span>+</span> Add Task</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
              
        </div>

        <DeleteModal
            title={"Are you sure you want to delete this task?"}
            isOpen={showDeletePopup}
            onClose={() => setShowDeletePopup(false)}
            onSure={handleDeleteTask} />


        
    </>

   
  );
};

export default GlobalTask;
