import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import PhoneNumber from "../../components/PhoneNumber";
import imageIconOutline from "../../assets/dashboard/image-icon-outline.svg";
import PenIcon from "../../assets/logos/pen.svg";
import { addVendorsService, addVendorsPhotoService, editVendorsService } from "../../services";
import { separateCountryCodeAndNumber } from "../../utils/helper";

const VendorModalForm = ({activeVendor, onHide, callback }) => {
  const businessDetails = JSON.parse(localStorage.getItem("authUserData")) || null;
  const token = localStorage.getItem('token');
  const [fileErr, setFileErr] = useState('');
  const [submitLoader, setSubmitLoader] = useState(false);
  const [vendorDetails, setVendorDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    country_code: "",
    phone_number: "",
    address: "",
    vendorCompanyName: "",
    vendorCategory: "",
    vendorWebsite: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone_number: "",
    address: "",
    vendorCompanyName: "",
    vendorCategory: "",
    vendorWebsite: "",
  });

  const [profilePhoto, setProfilePhoto] = useState('');
  const [profilePhotoView, setProfilePhotoView] = useState('');
  const [resErr, setResErr] = useState('');


  useEffect(() => {
    if(activeVendor.id){

      let _venderDetail = {
        ...vendorDetails,
        firstName: activeVendor.firstName,
        lastName: activeVendor.lastName,
        email: activeVendor.email,
        phone_number: `${activeVendor.country_code} ${activeVendor.phone_number}`,
        address: activeVendor.address,
        vendorCompanyName: activeVendor.vendorCompanyName,
        vendorCategory: activeVendor.vendorCategory,
        vendorWebsite: activeVendor.vendorWebsite,
      }
      setVendorDetails(_venderDetail)
    }
  }, [activeVendor])


  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVendorDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));

    setErrors((prevPrrors) => ({
      ...prevPrrors,
      [name]: '',
    }));
  };

  // Handle phone input change
  const handlePhoneChange = (phone) => {
    setVendorDetails((prevDetails) => ({
      ...prevDetails,
      phone_number: phone,
    }));
    setErrors((prevPrrors) => ({
      ...prevPrrors,
      phone_number: '',
    }));
  };

  // Validate the form
  const validateForm = () => {
    const newErrors = {};
  
    // Email and URL regex patterns
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const urlRegex = /^(https?:\/\/)?([\w\d-]+\.)+[\w-]{2,}(\/[\w\d-]+)*\/?$/;
  
    if (!vendorDetails.firstName) newErrors.firstName = "First name is required";
    if (!vendorDetails.lastName) newErrors.lastName = "Last name is required";
    if (!vendorDetails.email) {
      newErrors.email = "Email is required";
    } else if (!emailRegex.test(vendorDetails.email)) {
      newErrors.email = "Please enter a valid email address";
    }
    if (!vendorDetails.phone_number) newErrors.phone_number = "Phone number is required";
    if (!vendorDetails.vendorCompanyName) newErrors.vendorCompanyName = "Company name is required";
    if (!vendorDetails.vendorCategory) newErrors.vendorCategory = "Category is required";
    if (!vendorDetails.vendorWebsite) {
      newErrors.vendorWebsite = "Website is required";
    } else if (!urlRegex.test(vendorDetails.vendorWebsite)) {
      newErrors.vendorWebsite = "Please enter a valid website URL";
    }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  

  // Handle form submission
  const handleSubmit = async () => {
    if (!validateForm()) return;
    const { cc, number } = separateCountryCodeAndNumber(vendorDetails.phone_number);
    const vendorData = {
      ...vendorDetails,
      businessId: businessDetails?.businessDetail?.businessId,
      country_code: cc,
      phone_number: number
    };

    try {

      if(activeVendor.id){
        setSubmitLoader(true)
        let query = `?vendorId=${activeVendor.id}`;
        const res = await editVendorsService(query, vendorData, token);

        if(res.data.status){
          if(profilePhoto){
            let query = `?vendorId=${activeVendor.id}`;
            const formData = new FormData();
            formData.append('profilePhoto', profilePhoto);
            addVendorsPhotoService(query, formData, token).then(resIn => {
              if(resIn.data.status){
                onHide();
                callback();
              }
            })
          }else{
            onHide();
            callback();
          }
        }else{
          setResErr(res.data.error.message)
        }

        setSubmitLoader(false)



      }else{
        setSubmitLoader(true)
        const res = await addVendorsService(vendorData, token);

        if(res.data.status){
          let _venderId = res.data.responseData.vendorData.id;
          if(profilePhoto && _venderId){
            let query = `?vendorId=${_venderId}`;
  
            const formData = new FormData();
            formData.append('profilePhoto', profilePhoto);
  
            
  
            addVendorsPhotoService(query, formData, token).then(resIn => {
              if(resIn.data.status){
                onHide();
                callback();
              }
            })
          }else{
            onHide();
            callback();
          }
        }else{
          setResErr(res.data.error.message)
        }

        setSubmitLoader(false)
      }

      

    } catch (error) {
      console.error("Error submitting vendor:", error);
      setSubmitLoader(false)
    }
  };




  const handleFileChange = async (event) => {

        setFileErr('');
        const file = event.target.files[0];
        const fileSizeInMB = file.size / (1024 * 1024);
        if (fileSizeInMB > 50) {
        setFileErr('File size exceeds 50 MB. Please upload a smaller image.');
        return;
        }


    setProfilePhoto(file);
    if (file) {
      const previewUrl = await getPreviewUrl(file);
      setProfilePhotoView(previewUrl);
    }
  };

  const getPreviewUrl = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  return (
    <Modal show={true} onHide={onHide} className="vender_modal" backdrop="static">
      <Modal.Body className="p-0">
        <div className="_form_label">
          <h3 className="modal_heading mb-4">Add Vendor</h3>


          <div className="cm_profile_upload">
            <div className="cm_profile_upload_inner">
              {profilePhotoView ? <img className="pmm0" src={PenIcon} /> : ''}
              <input onChange={handleFileChange} type="file" />
              {profilePhotoView ? <img className="pmm1" src={profilePhotoView} /> : <img className="pmm2" src={imageIconOutline} />}
            </div>
          </div>

          {fileErr && <p className="error-text text-center">{fileErr}</p>}

          <div className="row mb-4">
            <div className="col-md-6">
              <div className="form-group">
                <label>First Name<span className="_imp_">*</span></label>
                <input
                  type="text"
                  name="firstName"
                  value={vendorDetails.firstName}
                  onChange={handleInputChange}
                  className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
                />
                {errors.firstName && <div className="invalid-feedback">{errors.firstName}</div>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Last Name<span className="_imp_">*</span></label>
                <input
                  type="text"
                  name="lastName"
                  value={vendorDetails.lastName}
                  onChange={handleInputChange}
                  className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
                />
                {errors.lastName && <div className="invalid-feedback">{errors.lastName}</div>}
              </div>
            </div>
          </div>

          <div className="form-group mb-4">
            <label>Email<span className="_imp_">*</span></label>
            <input
              type="email"
              name="email"
              value={vendorDetails.email}
              onChange={handleInputChange}
              className={`form-control ${errors.email ? 'is-invalid' : ''}`}
            />
            {errors.email && <div className="invalid-feedback">{errors.email}</div>}
          </div>

          <div className="form-group mb-4">
            <label>Mobile Number<span className="_imp_">*</span></label>
            <PhoneNumber
              setError={() => {}}
              isError={!!errors.phone_number}
              //setError={(error) => setErrors((prevErrors) => ({ ...prevErrors, phone_number: error }))}
              value={vendorDetails.phone_number}
              setValue={handlePhoneChange}
              isSubmitTouch={true}
            />
            {errors.phone_number && <div className="text-danger">{errors.phone_number}</div>}
          </div>

          <div className="form-group mb-4">
            <label>Address<span>(optional)</span></label>
            <input
              type="text"
              name="address"
              value={vendorDetails.address}
              onChange={handleInputChange}
              className="form-control"
            />
          </div>

          <h6>Company Information</h6>

          <div className="form-group mb-4">
            <label>Company<span className="_imp_">*</span></label>
            <input
              type="text"
              name="vendorCompanyName"
              value={vendorDetails.vendorCompanyName}
              onChange={handleInputChange}
              className={`form-control ${errors.vendorCompanyName ? 'is-invalid' : ''}`}
            />
            {errors.vendorCompanyName && <div className="invalid-feedback">{errors.vendorCompanyName}</div>}
          </div>

          <div className="form-group mb-4">
            <label>Vendor Category<span className="_imp_">*</span></label>
            <input
              type="text"
              name="vendorCategory"
              value={vendorDetails.vendorCategory}
              onChange={handleInputChange}
              className={`form-control ${errors.vendorCategory ? 'is-invalid' : ''}`}
            />
            {errors.vendorCategory && <div className="invalid-feedback">{errors.vendorCategory}</div>}
          </div>

          <div className="form-group mb-4">
            <label>Website<span className="_imp_">*</span></label>
            <input
              type="text"
              name="vendorWebsite"
              value={vendorDetails.vendorWebsite}
              onChange={handleInputChange}
              className={`form-control ${errors.vendorWebsite ? 'is-invalid' : ''}`}
            />
            {errors.vendorWebsite && <div className="invalid-feedback">{errors.vendorWebsite}</div>}
          </div>

          {resErr ? <h6 className="res_err">{resErr}</h6> : ''}

          <div className="d-flex justify-content-between align-baseline mt-5">
            <span onClick={onHide} className="_btn_transparent_ modal_div_button px-5">
              Cancel
            </span>
            <span onClick={() => submitLoader ? null : handleSubmit()} className="_btn_normal_ modal_div_button px-5">
              Submit
            </span>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default VendorModalForm;
