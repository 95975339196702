import React from 'react'

const HtmlPage = () => {
  return (
    <div>HtmlPage</div>
  )
}

export default HtmlPage

