import React, { useEffect } from "react";
import HomeHeader from "../components/common/HomeHeader";
import error from "./../assets/images/404-error.svg";
import CommonButton from "../components/common/CommonButton";
import { useNavigate } from "react-router-dom";

export default function RedirectPage() {
  const navigate = useNavigate();
  useEffect(()=>{
    navigate("/404PageNotfound")
  })
  return (
    <div>
      
    </div>
  );
}
