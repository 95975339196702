import { useState, useEffect } from "react";
import Select from "react-select";
import brandLogo from "../../assets/images/logo.png";
import { getProjectByBusinessService } from "../../services";
import GlobalSearch from "../../components/common/GlobalSearch";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../features/auth/authSlice";
import { persistor } from "../../store";
import menubar from '../../assets/dashboard/menu_bar.svg';


import invoices from '../../assets/dashboard/icons/invoice.svg';
import expenses from '../../assets/dashboard/icons/expenses.svg';
import setting from '../../assets/dashboard/icons/setting.svg';
import backarrow from '../../assets/dashboard/back-arrow.svg';
import Expenses from "../expenses/Expenses";
import Logo from "../../components/common/Logo";
import Settings from "../settings/Settings";
import Invoices from "../invoices/Invoices";


export default function AccountandDashboard() {
  let userDetail = JSON.parse(localStorage.getItem("authUserData")) || null;
  let userRole = localStorage.getItem("userRole") || '';
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedProject, setSelectedProject] = useState({});
  const [projectsList, setProjectList] = useState([]);
  const [activeTab, setActiveTab] = useState('Invoices');

  useEffect(() => {
    // Add a state to the history stack
    window.history.pushState(null, null, window.location.href);

    const handleBackButton = (event) => {
      // Push the current state again to prevent going back
      window.history.pushState(null, null, window.location.href);
    };

    // Add the event listener for the back button
    window.addEventListener('popstate', handleBackButton);

    return () => {
      // Cleanup the event listener when the component unmounts
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    dispatch(logout());
    persistor.purge()
    navigate("/signin");
  };


  const getAllProjects = async () => {
    try {
        const res = await getProjectByBusinessService(userDetail?.businessDetail?.businessId);
        let _projects = res.data.responseData.projectData || [];
        if(_projects.length > 0) {
            _projects = _projects.map(item => ({...item, label: item.projectName, value: item.id}))
            setProjectList(_projects);
            if(_projects.length){
              setSelectedProject(_projects[0])
            }
 
        }
    } catch (error) {
        console.error('Error fetching projects:', error);
    }
};


useEffect(() => {
    if(userDetail?.businessDetail?.businessId){
        getAllProjects();
    }
    
}, [])

  

  return (
    <div className="accountant_module">
      {/* <div className="c_header">
        <div className="c_header_inner">
          <div className="c_logo">
            <img src={brandLogo} alt="Xora Logo" />
          </div>
          <div className="c_logout_wrap ms-auto">   
            <GlobalSearch />
            <div className="c_name_top ms-5 me-5">
              {userDetail.firstName ? userDetail.firstName.charAt(0).toUpperCase() : ''}{userDetail.lastName ? userDetail.lastName.charAt(0).toUpperCase() : ''}
              <p onClick={handleLogout}>Logout</p>
            </div>
          </div>
        </div>
      </div> */}

      <div className="a_sidebar">
        <div className="dash_logo">
          <Logo />
        </div>

        {userRole ? <h6 className="show_role mt-5">{userRole}</h6> : ''}
        <ul>
          
          <li className={`${activeTab == 'Invoices' ? 'active' : ''}`} onClick={() => setActiveTab('Invoices')}>
            <img src={invoices} /> <p>Invoices</p>
          </li>

          <li className={`${activeTab == 'Expenses' ? 'active' : ''}`} onClick={() => setActiveTab('Expenses')}>
            <img src={expenses} /> <p>Expenses</p>
          </li>

          <li className={`${activeTab == 'Settings' ? 'active' : ''}`} onClick={() => setActiveTab('Settings')}>
            <img src={setting} /> <p>Settings</p>
          </li>
         
        </ul>

      </div>



      <div className="a_body">
        {activeTab == 'Invoices' ? <Invoices isForAccountandView={true} showActionModal={true} /> : ''}
        {activeTab == 'Expenses' ? <Expenses isForAccountandView={true} showActionModal={true} isTabView={true} /> : ''}
        {activeTab == 'Settings' ? <Settings /> : ''}
      </div>

    </div>
  );
}
