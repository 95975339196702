import React, { memo, useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TemplateGallery from "./TemplateGallery";
import TemplatePreview from "./TemplatePreview";
import DealroomSigners from "./DealroomSigners";

export const  DealroomTemplate = ({ dealroomCreateNew, resetDealRoomCreateNew, getTemplates, type, signerLisingRefreshHandler })  => {
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");

  useEffect(() => {
    if(previewUrl !== ""){
      setIsPreviewVisible(true);
    }
  },[previewUrl])

  const handlePreviewClick = (url) => {
    setPreviewUrl(url);
  };

const handleBackTolist = () => {
  setIsPreviewVisible(false)
  resetDealRoomCreateNew()
}

  return isPreviewVisible ? (
    <div className="templatepreview">
      <TemplatePreview previewUrl={previewUrl} cancelHandler={handleBackTolist} type={type} _refreshSignersList={signerLisingRefreshHandler}/>
    </div>
  ) : (
    <>
      <Tabs
        defaultActiveKey="templateGallery"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="myTemplate" title="My Template">
          No template
        </Tab>
        <Tab eventKey="templateGallery" title="Template Gallery">
          <TemplateGallery
            dealroomCreateNew={dealroomCreateNew}
            getTemplates={getTemplates}
            handlePreviewClick={handlePreviewClick}
            resetDealRoomCreateNew={resetDealRoomCreateNew}
          />
        </Tab>
      </Tabs>
    </>
  );
}


export default memo(DealroomTemplate )
