import { useEffect, useState } from "react";
import { getMerchandStatusService, getMerchandAuthLinkService } from "../../services";
import useToast from "../../utils/customHooks/useToast";
import logo from '../../assets/images/logo.png';
import cashfreeLogo from '../../assets/images/cashfree-icon.svg';

const PaymentSettings = () => {
    const authUserData = JSON.parse(localStorage.getItem("authUserData")) || null;
    const token = localStorage.getItem('token');
    const showToast = useToast();

    const [statusView, setStatusView] = useState(0);
    const [btnLoader, setBtnLoader] = useState(false);

    const handleStatusView = (status) => {
        switch(status) {
            case "MIN_KYC_PENDING":
                setStatusView(2);
                break;
            case "MIN_KYC_SUBMITTED":
                setStatusView(3);
                break;
            case "MIN_KYC_APPROVED":
                setStatusView(4);
                break;
            case "MIN_KYC_REJECTED":
                setStatusView(5);
                break;
            default:
                setStatusView(1);
                break;
        }
    };

    const getMerchandStatus = async () => {
        try {
            const query = `?businessId=${authUserData.businessDetail.businessId}`;
            const res = await getMerchandStatusService(query);
            const _status = res?.data?.responseData?.product_status[0]?.product_min_kyc_status || '';
            handleStatusView(_status);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (authUserData?.businessDetail?.businessId) {
            getMerchandStatus();
        }
    }, []);

    const handleGetStarted = async () => {
        try {
            setBtnLoader(true);
            const query = `?businessId=${authUserData.businessDetail.businessId}`;
            const res = await getMerchandAuthLinkService(query);
            let _url = res?.data?.responseData?.cashfreeAuthLink || '';
            if(_url){
                window.open(_url, '_blank');
            }

        } catch (error) {
            console.error(error);
        } finally {
            setBtnLoader(false);
        }
    };

    const renderPaymentStatus = () => {
        const statusMessages = {
            1: {
                chip: "Not setup",
                heading: "Cashfree Account Not Set Up!",
                description: "Customers won’t be able to pay your invoices online. Please set up your Cashfree account to enable online payments.",
                buttonText: "Get Started",
                color: 'rgba(137, 137, 137, 1)'
            },
            2: {
                chip: "KYC Doc Pending",
                heading: "Pending KYC Document Submission!",
                description: "Submit your documents on the Cashfree portal to activate your payments.",
                buttonText: "Provide KYC Documents",
                color: 'rgba(233, 131, 58, 1)'
            },
            3: {
                chip: "Pending KYC Verification",
                heading: "Cashfree is Verifying Your Documents!",
                description: "Visit the Cashfree portal to check your verification status.",
                buttonText: "Check KYC Verification Status",
                color: 'rgba(247, 195, 37, 1)'
            },
            4: {
                chip: "Active",
                heading: "Account Connected!",
                description: "Your Cashfree account is active. Xora will now generate a payment link with each invoice, making it easy for your customers to pay online.",
                buttonText: "View Cashfree Merchant Portal",
                color: 'rgba(32, 120, 46, 1)'
            },
            5: {
                chip: "Rejected",
                heading: "KYC Rejected: Account Not Approved",
                description: "Your KYC documents were rejected. Please contact Xora customer support for assistance.",
                buttonText: "Check Cashfree Merchant Portal",
                color: 'rgba(32, 120, 46, 1)'
            }
        };

        const status = statusMessages[statusView];
        if (!status) return null;

        return (
            <div className="payment_status">
                <div className="cash_setup_status">
                    <div className="d-flex align-items-center">
                        <img style={{ background: '#000', padding: '10px' }} src={logo} alt="Xora Logo" />
                        <h6>xora</h6>
                        <img src={cashfreeLogo} alt="Cashfree Logo" />
                    </div>
                    <div>
                        <span className={`cfs_chip cfs_${statusView}`}>{status.chip}</span>
                    </div>
                </div>
                <h5 style={{ color: status.color }}>{status.heading}</h5>
                <p style={{ color: status.color }}>{status.description}</p>
                <div className="text-center my-5">
                    <button onClick={handleGetStarted} className="btn btn-primary btn-lg px-5">
                        {status.buttonText}
                    </button>
                </div>
            </div>
        );
    };

    return (
        <div className="setting_inner_page">
            <h3 className="mb-5">Payment Settings</h3>
            <div className="row">
                <div className="col-lg-12">
                    <h4>Payment Processing</h4>
                    <p>Connect a payment processor to start collecting payments online. Payment processing fee applies.</p>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    {renderPaymentStatus()}
                    <small>Xora does not store any payment information. All your payment info will be securely handled by Cashfree payments.</small>
                </div>
            </div>
        </div>
    );
};

export default PaymentSettings;
