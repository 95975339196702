
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  leadSource: {},
};

const leadSourceSlice = createSlice({
  name: 'leadSource',
  initialState,
  reducers: {
    addLeadSource: (state, action) => {
      state.leadSource = action.payload;
    },
  },
});

export const { addLeadSource } = leadSourceSlice.actions;

export default leadSourceSlice.reducer;