import React, { useState, useEffect } from 'react';
import { FileUploader } from "react-drag-drop-files";
import { PDFDocument } from 'pdf-lib';
import CommonButton from '../../../components/common/CommonButton';
import { postUploadDoc } from '../../../services';

const fileTypes = ["JPG", "PNG", "PDF", 'DOCX'];

const FileUpload = ({ onPdfGenerated, cancelHandler }) => {
  const [files, setFiles] = useState([]);

  const handleChange = (newFiles) => {
    localStorage.removeItem('_boxes');
    setFiles(Array.from(newFiles));
  };

  const generatePdfFromImages = async (imageFiles) => {
    const pdfDoc = await PDFDocument.create();

    for (const file of imageFiles) {
      const imgData = await readFileAsArrayBuffer(file);
      let img;
      if (file.type === 'image/jpeg' || file.type === 'image/jpg') {
        img = await pdfDoc.embedJpg(imgData);
      } else if (file.type === 'image/png') {
        img = await pdfDoc.embedPng(imgData);
      }

      const page = pdfDoc.addPage([img.width, img.height]);
      page.drawImage(img, {
        x: 0,
        y: 0,
        width: img.width,
        height: img.height,
      });
    }

    const pdfBytes = await pdfDoc.save();
    const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
    const pdfUrl = URL.createObjectURL(pdfBlob);
    onPdfGenerated(pdfUrl);
  };




  const generatePdfFromDocFiles = async (docFiles) => {
    try {
        const pdfPromises = docFiles.map(async (file) => {
            const formData = new FormData();
            formData.append('file', file);
            const pdfResponse = await postUploadDoc(formData);

            return pdfResponse;
        });

        const pdfFiles = await Promise.all(pdfPromises);
        onPdfGenerated(pdfFiles)

    } catch (error) {
        console.error('Error generating PDFs:', error);
        throw error;
    }
};


  const readFileAsArrayBuffer = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => resolve(event.target.result);
      reader.onerror = (error) => reject(error);
      reader.readAsArrayBuffer(file);
    });
  };

  const handleFiles = async (files) => {
    const pdfFiles = files.filter(file => file.type === 'application/pdf');
    const imageFiles = files.filter(file => file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png');
    const docFiles = files.filter(file => file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');

    if (pdfFiles.length > 0) {
      const pdfFile = pdfFiles[0];
      const pdfUrl = URL.createObjectURL(pdfFile);
      onPdfGenerated(pdfUrl);
    } else if (imageFiles.length > 0) {
      await generatePdfFromImages(imageFiles);
    }else if (docFiles.length > 0){
      await generatePdfFromDocFiles(docFiles)
    } else {
      alert('Unsupported file type!');
    }
  };

  useEffect(() => {
    if (files.length > 0) {
      handleFiles(files);
    }
  }, [files]);  

  return (
    <div className="signature_div_main">
      <div className="signature_div_middle">
        <h4 className="font_18">Choose files or drag & drop them here</h4>
        <FileUploader
          handleChange={handleChange}
          name="files"
          types={fileTypes}
          multiple />
      </div>
      <div className="contract_main_button">
        <CommonButton
          label="Cancel"
          fontSize="14px"
          fontWeight="700"
          className="dash_common_btn white_bg_btn"
          icon={false}
          onClick={cancelHandler}
        />
      </div>
    </div>
  );
};

export default FileUpload;
