import React, { useEffect, useState } from "react";
import CommonButton from "../../../components/common/CommonButton";
import PreviewTemplate from "./PreviewTemplate";

import { Modal, Button } from 'react-bootstrap';
import { hexToRgb, hexToRgba } from "../../../utils/helper";

import clientFirstNameIcon from "./../../../assets/dashboard/dealroom/icon/calender.svg";
import clientLastNameIcon from "./../../../assets/dashboard/dealroom/icon/calender.svg";
import clientAddressIcon from "./../../../assets/dashboard/dealroom/icon/location.svg";
import clientEmailIcon from "./../../../assets/dashboard/dealroom/icon/mail.svg";
import clientInitialsIcon from "./../../../assets/dashboard/dealroom/icon/as.svg";
import clientSignatureIcon from "./../../../assets/dashboard/dealroom/icon/pen.svg";

import companyNameIcon from "./../../../assets/dashboard/dealroom/icon/calender.svg";
import companyAddressIcon from "./../../../assets/dashboard/dealroom/icon/location.svg";
import companyPhoneNumberIcon from "./../../../assets/dashboard/dealroom/icon/phone.svg";

import projectNameIcon from "./../../../assets/dashboard/dealroom/icon/calender.svg";
import projectAddressIcon from "./../../../assets/dashboard/dealroom/icon/location.svg";
import projectDueDateIcon from "./../../../assets/dashboard/dealroom/icon/calender.svg";
import { Container } from "./Container";
import DynamicInputFieldsModal from "../../../components/DynamicInputFieldsModal";
import { PDF_VALUE_TYPE } from "../../../utils/constant";


const fieldList = [
  { label: "First Name", id: "clientFirstName", icon: clientFirstNameIcon, fieldType: 2, valueType:PDF_VALUE_TYPE.NAME, category: 'clientFields' },
  { label: "Last Name", id: "clientLastName", icon: clientLastNameIcon, fieldType: 2, valueType:PDF_VALUE_TYPE.NAME, category: 'clientFields' },
  { label: "Address", id: "clientAddress", icon: clientAddressIcon, fieldType: 2, valueType:PDF_VALUE_TYPE.TEXT, category: 'clientFields' },
  { label: "Email", id: "clientEmail", icon: clientEmailIcon, fieldType: 2, valueType:PDF_VALUE_TYPE.EMAIL, category: 'clientFields' },
  { label: "Initials", id: "clientInitials", icon: clientInitialsIcon, fieldType: 2 , valueType:PDF_VALUE_TYPE.INITIAL, category: 'clientFields'},
  { label: "Signature", id: "clientSignature", icon: clientSignatureIcon, fieldType: 2, valueType:PDF_VALUE_TYPE.SIGNER, category: 'clientFields' },
  { label: "Name", id: "companyName", icon: companyNameIcon, fieldType: 4, valueType:PDF_VALUE_TYPE.NAME, category: 'companyFields' },
  { label: "Address", id: "companyAddress", icon: companyAddressIcon, fieldType: 4 , valueType:PDF_VALUE_TYPE.TEXT, category: 'companyFields' },
  { label: "Phone Number", id: "companyPhoneNumber", icon: companyPhoneNumberIcon, fieldType: 4, valueType:PDF_VALUE_TYPE.CALL, category: 'companyFields' },
  { label: "Name", id: "projectName", icon: projectNameIcon, fieldType: 3, valueType:PDF_VALUE_TYPE.NAME, category: 'projectFields'},
  { label: "Address", id: "projectAddress", icon: projectAddressIcon, fieldType: 3, valueType:PDF_VALUE_TYPE.TEXT, category: 'projectFields' },
  { label: "Due Date", id: "projectDueDate", icon: projectDueDateIcon, fieldType: 3, valueType:PDF_VALUE_TYPE.CALENDAR, category: 'projectFields' },
  { label: "Total Amount", id: "Total_Amount", icon: projectNameIcon, fieldType: 5, valueType:PDF_VALUE_TYPE.TEXT, category: 'paymentFields' },
  { label: "Deposit Amount", id: "Desposit_Amount", icon: projectAddressIcon, fieldType: 5, valueType:PDF_VALUE_TYPE.TEXT, category: 'paymentFields' },
  { label: "Address", id: "Address", icon: projectAddressIcon, fieldType: 5, valueType:PDF_VALUE_TYPE.TEXT, category: 'paymentFields' },
  { label: "Late Fee", id: "Late_Fee", icon: projectDueDateIcon, fieldType: 5, valueType:PDF_VALUE_TYPE.CALENDAR, category: 'paymentFields' },
  { label: "Text", id: "Text", icon: projectDueDateIcon, fieldType: 5, valueType:PDF_VALUE_TYPE.TEXT, category: 'paymentFields' },
  { label: "Date", id: "Date", icon: projectDueDateIcon, fieldType: 6, valueType:PDF_VALUE_TYPE.CALENDAR, category: 'sessionFields' },
  { label: "Start Time", id: "Start_Time", icon: projectDueDateIcon, fieldType: 6, valueType:PDF_VALUE_TYPE.TEXT, category: 'sessionFields' },
  { label: "End Time", id: "End_Time", icon: projectDueDateIcon, fieldType: 6, valueType:PDF_VALUE_TYPE.TEXT, category: 'sessionFields' },
];

const fieldListTitles = [
  {title: 'Standard Fields', val: 'clientFields'},
  {title: 'Company Fields', val: 'companyFields'},
  {title: 'Project Fields', val: 'projectFields'},
  {title: 'Payment', val: 'paymentFields'},
  {title: 'Session', val: 'sessionFields'},
]

const defaultValues = {
  clientFirstName: '',
  clientLastName: '',
  clientAddress: '',
  clientEmail: '',
  companyName: '',
  companyAddress: '',
  companyPhoneNumber: '',
  projectName: '',
  projectAddress: '',
  projectDueDate: ''
};

export default function Contract({pdfType, pdfUrl, templateDetails, closeContract, backToDealRoomListing, _refreshSignersList_, crData={}}) {

  const [previewContent, setPreviewContent] = useState('');
  const [isPreview, setIsPreview] = useState(false);
  const [pdfView, setPDFView] = useState(false);
  const [signers, setSigners] = useState([]);
  const [currentSigner, setCurrentSigner] = useState(1)
  const [selectedSignerBgColor, setSelectedSignerBgColor] = useState('#A9A9A9')
  const [modalShow, setModalShow] = useState(false);
  const [previewPdfUrl, setPreviewPdfUrl] = useState('')
  const [magicFieldsValues, setMagicFieldsValue] = useState(defaultValues)
  const [isDynamicFieldModalOpen, setIsDynamicFieldModalOpen] = useState(false)
  const [currentSelectedFieldName, setCurrentSelectedFieldName] = useState('')
  const [selectedFieldType, setSelectedFieldType] = useState('')
  const [selectedValueType, setSelectedValueType] = useState('');

  useEffect(() => {
    if(pdfUrl){
      setPDFView(true)
    }
  }, [pdfType])

  useEffect(() => {
    const savedBusinedData = JSON.parse(localStorage.getItem('authUserData'))
    const savedProjectDetails = JSON.parse(localStorage.getItem('projectDetail'))

    let temp = {...magicFieldsValues}
    
    temp.clientFirstName = savedProjectDetails?.clients[0]?.firstName || ''
    temp.clientLastName = savedProjectDetails?.clients[0]?.lastName || ''
    temp.clientAddress = savedProjectDetails?.clients[0]?.address || ''
    temp.clientAddress = savedProjectDetails?.clients[0]?.address || ''
    temp.clientEmail = savedProjectDetails?.clients[0]?.email || ''
    temp.companyName = savedBusinedData?.businessDetail?.businessName || ''
    temp.companyAddress = savedBusinedData?.businessDetail?.businessAddress || ''
    temp.companyPhoneNumber = `${savedBusinedData?.phoneNumber}` || ''
    temp.projectName = savedProjectDetails?.projectName || ''
    temp.projectAddress = savedProjectDetails?.address || ''
    temp.projectDueDate = savedProjectDetails?.projectDueDate || ''
    setMagicFieldsValue(temp)
  },[])

  useEffect(() => {
    if(currentSelectedFieldName !== '' && selectedFieldType !== '' && selectedFieldType !== ''){
      setIsDynamicFieldModalOpen(true)
    }
  },[currentSelectedFieldName, selectedFieldType, selectedValueType])


  useEffect(() => {
      if(previewPdfUrl !== ''){
        setModalShow(true)
      }
  },[previewPdfUrl])

  const handleCancel = () => {
    closeContract()
  };

  useEffect(() => {
    if(previewContent !== ''){
      setIsPreview(true);
    }
  },[previewContent])

  const handlePreview = (url) => {
    setPreviewContent(url);
  };

  const handleClose = () => setModalShow(false);

  const handleCloseDynamicField = () => setIsDynamicFieldModalOpen(false)

  const handleBack = () => {
    setIsPreview(false);
    setPDFView(false)
  };

  const handleBack2 = () => {
    closeContract()
  }


  const handlePreviewTemplate = (pdfWithBoxesUrl) => {
    setPDFView(false)
    setPreviewPdfUrl(pdfWithBoxesUrl)
  };

  const handleAddClient = ({client, fieldType, valueType, label, id}) => {
    let _signers = [...signers];
    _signers.push({
      id: `${id}-${_signers.length}`,
      title: label,
      emailId: templateDetails.signers[currentSigner-1].email,
      name: templateDetails.signers[currentSigner-1].name,
      bgColor: selectedSignerBgColor,
      fieldType: fieldType,
      type: valueType
    })


    setSigners(_signers);
    
  };

  const handleMagicField = (fieldName,fieldValue, fieldType, valueType) => {
    const temp = {...magicFieldsValues}
    temp[fieldName] = fieldValue
    setMagicFieldsValue(temp)
    setSigners((prevSigners) => [
      ...prevSigners,
      {
        id: fieldName.toLowerCase().replace(" ", "_"),
        title: temp[fieldName],
        signer: currentSigner,
        emailId: templateDetails.signers[currentSigner-1].email,
        name: templateDetails.signers[currentSigner-1].name,
        bgColor: selectedSignerBgColor,
        fieldType: fieldType,
        type: valueType
      },
    ]);
  }
  return (
    <>
      {!isPreview && pdfUrl ? (
        <>
          <div className="contract_main">
            <div className="contract_left">
                
                <Container 
                  pdfUrl={pdfUrl} 
                  clients={signers} 
                  setClients={setSigners} 
                  onPreviewTemplate={handlePreviewTemplate} 
                  showUpdatePdf={pdfView} 
                  previewHandler={handlePreview}
                />
            </div>
            <div className="contract_right">
              <h2 className="dashboard_heading">Magic Fields</h2>
              <label className="font_14">Assign To</label>
              <ul className="assign_ul">
                {
                  templateDetails && templateDetails?.signers.length > 0 && templateDetails?.signers.map((item, index) => (
                    <li className={currentSigner-1 === index ? 'highlight-current-signer' : ''} style={{backgroundColor: hexToRgba(item.bgColor,0.3), cursor:'pointer', textTransform: 'capitalize'}} key={index} onClick={() => {setCurrentSigner(index + 1); setSelectedSignerBgColor(item?.bgColor)}}>{item.userType === 1 ? 'Me' : 'Client'} : {item.name}</li>
                  ))
                }
              </ul>

              

              <ul className="modal_main_ul">

                {
                  fieldListTitles.map(el => (
                    <li className="modal_main_li" key={el.val}>
                      <label className="font_14">{el.title}</label>
                      <div className="contract_right_labeling_main">
                        {fieldList.filter(item => item.category === el.val).map((field) => (
                          <div className="contract_right_image" key={field.label}>
                            <img src={field.icon} alt="" className="field_icon" />
                            <p
                              key={field.id}
                              className="contract_right_labeling font_14" 
                              onClick={() => handleAddClient(field)}>{field.label}</p>
                          </div>
                        ))}
                      </div>
                    </li>
                  ))
                }
                
               
              </ul>
            </div>
          </div>
          <div className="contract_main_button">
            <CommonButton label="Cancel" fontSize="14px" fontWeight="700" className="dash_common_btn white_bg_btn" icon={false} onClick={handleCancel} />
            <CommonButton label="Preview" fontSize="14px" fontWeight="700" className="dash_common_btn" icon={false} onClick={() => setPDFView(true)} />
          </div>
        </>
      ) : (
        <PreviewTemplate
          previewContent={previewContent}
          handleBack={handleBack}
          handleBack2={handleBack2}
          backToListing={backToDealRoomListing}
          signerListRefreshHandler={_refreshSignersList_}
          _crData={crData} />
      )}

    <Modal show={modalShow} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>PDF Viewer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <iframe
          src={previewPdfUrl}
          title="PDF Document"
          width="100%"
          height="500px"
          style={{ border: 'none' }}
        ></iframe>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>

    {isDynamicFieldModalOpen && (
      <DynamicInputFieldsModal
        isOpen={isDynamicFieldModalOpen}
        onClose={handleCloseDynamicField}
        onSave={handleMagicField}
        fieldName={currentSelectedFieldName}
        _fieldType={selectedFieldType}
        _valueType={selectedValueType} />
    )}
      
    </>
  );
}