import { useEffect, useState } from "react";
import { getChangeRequestList } from "../../services";
import { clientformatDate } from "../../utils/helper";
import { _contractStatus } from "../../utils/constant";
import threeDot from '../../assets/logos/three-dot.svg';
import ActionModalComponent from "./ActionModalComponent";

const ClientChangeRequestsComponent = ({ selectedProject }) => {
  const userDetail = JSON.parse(localStorage.getItem("authUserData")) || null;
  const [list, setList] = useState([]);
  const [pageLoader, setPageLoader] = useState(true);
  const [activeCR, setActiveCR] = useState({});

  useEffect(() => {
    const fetchDealrooms = async () => {
      if (!userDetail?.businessDetail?.businessId || !selectedProject?.id) return;
      const params = `?businessId=${userDetail.businessDetail.businessId}&projectId=${selectedProject.id}`;
      try {
        const { data } = await getChangeRequestList(params);
        if (data?.status) {
          setList(data.responseData?.data || []);
        }
        setPageLoader(false)
      } catch (error) {
        console.error('Error fetching signers:', error);
        setPageLoader(false)
      }
    };
    
    fetchDealrooms();
  }, [selectedProject]);


  if(pageLoader){
    return <div>Loading...</div>
  }else{
    return (
        <div>
          <h3>All Change Requests</h3>
          <p>You can find all change requests here</p>
          <div className="table-responsive">
            <table className="project_table dealroom_table">
              <thead>
                <tr>
                  <th className="text-start ps-4">S.No.</th>
                  <th className="text-start">Created</th>
                  <th className="text-start">Title</th>
                  <th className="text-start">Status</th>
                  <th className="text-start">Sent to</th>
                  <th className="text-start">Requested By</th>
                  <th className="text-start">Action</th>
                </tr>
              </thead>
              <tbody>
                {list.map((project, index) => (
                  <tr key={project.id || index}>
                    <td className="text-start ps-4">{index + 1}</td>
                    <td className="text-start">
                      {clientformatDate(new Date(project.created_at).toISOString().split("T")[0])}
                    </td>
                    <td className="dealroom_table_title text-start">
                      <span onClick={() => window.open(`/view-template?dealroomId=${project.id}`, "_blank")}>
                        {project.documentTitle}
                      </span>
                    </td>
                    <td className="text-start">
                      <p className="phases text-center ms-0" style={{ backgroundColor: _contractStatus[project.status]?.color }}>
                        {_contractStatus[project.status]?.value}
                      </p>
                    </td>
                    <td className="text-start">
                      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        {project.signers.map((signer, idx) => (
                          <div key={idx}>
                            <span style={{ color: _contractStatus[signer.status]?.color }}>
                              {_contractStatus[signer.status]?.value}
                            </span> : {signer.name}
                          </div>
                        ))}
                      </div>
                    </td>
                    <td className="text-start">{userDetail?.firstName} {userDetail?.lastName}</td>
                    <td onClick={() => setActiveCR(project)} className="text-start" style={{ cursor: 'pointer' }}>
                      <img src={threeDot} alt="three dot" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {activeCR.id ? <ActionModalComponent
                                    _data={activeCR}
                                    title={activeCR.documentTitle}
                                    onClose={() => setActiveCR({})}
                                    url={activeCR.id}
                                    viewType="CR" /> : ''}
        </div>
      );
  }


};

export default ClientChangeRequestsComponent;
