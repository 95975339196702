import { Modal } from "react-bootstrap";
import { getMembersByBusinessIdService, addMemberToProjectService } from "../../../services";
import { useEffect, useState, useCallback } from "react";
import Select from "react-select";
import PhoneNumber from "../../../components/PhoneNumber";
import { role } from "../../../utils/constant";

const AddTeamModal = ({ onHide, cmCallback }) => {
  const businessDetails = JSON.parse(localStorage.getItem("authUserData")) || null;
  const projectDetail = JSON.parse(localStorage.getItem("projectDetail")) || null;

  const [allTeams, setAllTeams] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState(null);
  const [selectedTeamsErr, setSelectedTeamsErr] = useState('');

  const [teamsDetails, setTeamsDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone_number: "",
    address: "",
    role: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone_number: "",
    role: "",
  });

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateFields = () => {
    const newErrors = {};
    if (!teamsDetails.firstName) newErrors.firstName = "First name is required.";
    if (!teamsDetails.lastName) newErrors.lastName = "Last name is required.";
    if (!teamsDetails.role) newErrors.role = "Role is required.";
    if (!teamsDetails.email) {
      newErrors.email = "Email is required.";
    } else if (!validateEmail(teamsDetails.email)) {
      newErrors.email = "Invalid email format.";
    }
    if (!teamsDetails.phone_number) newErrors.phone_number = "Phone number is required.";
    return newErrors;
  };

  const getAllTeams = useCallback(async () => {
    const businessId = businessDetails?.businessDetail?.businessId;
    if (businessId) {
      try {
        const res = await getMembersByBusinessIdService(businessId);
        if (res.data.status === 1) {
          const members = res.data.responseData.members || [];
          const options = members.filter(jj => jj.firstName && jj.lastName).map((member) => ({
            value: member.id,
            label: (!member.firstName && !member.lastName) ? member.email : `${member.firstName} ${member.lastName}`,
            ...member,
          }));
          setAllTeams(options);
        }
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    }
  }, [businessDetails]);

  useEffect(() => {
    getAllTeams();
  }, []);

  const handleClientChange = (selectedOption) => {
    console.log(selectedOption, 'selectedOptionselectedOption')
    setSelectedTeamsErr('');
    if (selectedOption) {
      setTeamsDetails({
        firstName: selectedOption.firstName,
        lastName: selectedOption.lastName,
        email: selectedOption.email,
        role: selectedOption.role,
        phone_number: `${selectedOption.country_code}${selectedOption.phone_number}`,
        address: selectedOption.address || "",
      });

      setErrors({
        firstName: "",
        lastName: "",
        email: "",
        phone_number: "",
        role: "",
      });
    } else {
      setTeamsDetails({
        firstName: "",
        lastName: "",
        email: "",
        phone_number: "",
        address: "",
        role: "",
      });
    }
    setSelectedTeams(selectedOption);
  };

  const handleInputChange = (field, value) => {
    setTeamsDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "", // Clear error when user starts typing
    }));
  };


  const handleRoleChange = e => {
    setTeamsDetails((prevDetails) => ({
      ...prevDetails,
      role: e.value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      role: "",
    }));
  };




  const handleSubmit = async () => {
    if (selectedTeams?.id) {
      setSelectedTeamsErr('');
  
      let params = {
        memberId: selectedTeams.id,
        projectId: projectDetail.id
      };
  
      try {
        const res = await addMemberToProjectService(params);
        onHide();
        cmCallback('members')
      } catch (error) {
        console.error('Error adding member to project:', error);
      }
    } else {
      setSelectedTeamsErr('please select a team');
    }
  };
  

  return (
    <Modal show={true} onHide={onHide} backdrop="static">
      <Modal.Body className="p-0">
        <div className="_form_lable">
          <h3 className="modal_heading mb-4">Add Team</h3>

          <div className="form-group">
            <label>Select Team Member</label>
            <br />
            <small className="text-primary d-block mb-3">Only exisiting team members can be added to a project. To add new members to the account, go to settings</small>
            <Select
              value={selectedTeams}
              onChange={handleClientChange}
              options={allTeams}
              isClearable
              placeholder="Select Member"
            />
             {selectedTeamsErr && <small className="text-danger">{selectedTeamsErr}</small>}

          </div>


          <div className="line_or my-4"><span>Or</span></div>


          <div className="form-group mb-4">
            <label>Email<span className="_imp_">*</span></label>
            <input
              disabled
              value={teamsDetails.email}
              type="email"
              onChange={(e) => handleInputChange("email", e.target.value)}
              className="form-control"
            />
            {errors.email && <small className="text-danger">{errors.email}</small>}
          </div>

          <div className="row mb-4">
            <div className="col-md-6">
              <div className="form-group">
                <label>First Name<span className="_imp_">*</span></label>
                <input
                  disabled
                  value={teamsDetails.firstName}
                  type="text"
                  onChange={(e) => handleInputChange("firstName", e.target.value)}
                  className="form-control"
                />
                {errors.firstName && <small className="text-danger">{errors.firstName}</small>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Last Name<span className="_imp_">*</span></label>
                <input
                  disabled
                  value={teamsDetails.lastName}
                  type="text"
                  onChange={(e) => handleInputChange("lastName", e.target.value)}
                  className="form-control"
                />
                {errors.lastName && <small className="text-danger">{errors.lastName}</small>}
              </div>
            </div>
          </div>

        

          <div className="form-group mb-4">
            <label>Mobile Number<span className="_imp_">*</span></label>
            <PhoneNumber
              isDisabled={true}
              value={teamsDetails.phone_number}
              setValue={(value) => handleInputChange("phone_number", value)}
              isError={!!errors.phone_number}
              setError={() => {}}
              isSubmitTouch={true}
            />
            {errors.phone_number && <small className="text-danger">{errors.phone_number}</small>}
          </div>

          <div className="form-group mb-4">
            <label>Address<span>(optional)</span></label>
            <input
              disabled
              value={teamsDetails.address}
              type="text"
              onChange={(e) => handleInputChange("address", e.target.value)}
              className="form-control"
            />
          </div>


          <div className="form-group">
            <label>Team Member Role</label>
           <Select
            isDisabled
              value={teamsDetails.role ? {label: teamsDetails.role, value: teamsDetails.role} : ''}
              onChange={handleRoleChange}
              options={role}
              isClearable
              placeholder="Select Role"
            />
            {errors.role && <small className="text-danger">{errors.role}</small>}
          </div>

          <div className="d-flex justify-content-between align-baseline mt-5">
            <span onClick={onHide} className="cursor-pointer _btn_transparent_ modal_div_button px-5">Cancel</span>
            <span onClick={handleSubmit} className="cursor-pointer _btn_normal_ modal_div_button px-5">Submit</span>
            
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddTeamModal;
