import React, { useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { onboardSuccessService } from "../../services";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const PaymentSuccess = () => {
    const query = useQuery();
    const code = query.get('code');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchOnboardSuccess = async () => {
            if (code) {
                try {
                    const params = { code };
                    const response = await onboardSuccessService(params);
                    navigate('/');
                } catch (error) {
                    console.error("Error:", error);
                }
            }
        };

        fetchOnboardSuccess();
    }, [code, navigate]);

    return (
        <div className="payment_success_page">
            Loading ...
        </div>
    );
};

export default PaymentSuccess;
