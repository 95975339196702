import React, { useState, useEffect, useRef } from "react";
import { invoiceFilterStatus } from "../../utils/constant";
import { capitalizeFirstLetter } from "../../utils/helper";

import searchIcon from "./../../assets/dashboard/Search.svg";
import filterIcon from "./../../assets/dashboard/filter_icon.svg";
import threeDot from './../../assets/logos/three-dot.svg';
import ActionModalComponent from "../client-dashboard/ActionModalComponent";

function formatDate(dateStr) {
    const options = { year: 'numeric', month: 'long', day: '2-digit' };
    return new Date(dateStr).toLocaleDateString('en-US', options);
}

const getStatusText = (statusId) => {    
    const status = invoiceFilterStatus.find(status => status.id === statusId);    
    return status ? { text: (status?.value)?.toLowerCase(), color: status?.color } : { text: "-", color: "#000000" };
};

const InvoicesList = ({ list, showActionModal }) => {
    const [search, setSearch] = useState('');
    const [showFilter, setShowFilter] = useState(false);  // State to show/hide filter dropdown
    const [selectedStatus, setSelectedStatus] = useState({});
    const [activeAction, setActiveAction] = useState({});

    // Ref for the filter dropdown box
    const filterRef = useRef(null);

    // Toggle the filter dropdown
    const toggleFilter = () => {
        setShowFilter(prev => !prev);
    };

    // Handle changes to status filters
    const handleStatusChange = (statusId) => {
        setSelectedStatus(prevStatus => {
            const newStatus = { ...prevStatus, [statusId]: !prevStatus[statusId] };
            // Remove the status from selectedStatus if unchecked
            if (!newStatus[statusId]) {
                delete newStatus[statusId];
            }
            return newStatus;
        });
    };

    // Clear all selected filters
    const clearFilters = () => {
        setSelectedStatus({});
        setShowFilter(false);
    };

    const filteredList = list.filter(invoice => {
        const matchesSearch = invoice.title.toLowerCase().includes(search.toLowerCase());
        const matchesStatus = Object.keys(selectedStatus).length === 0 || selectedStatus[invoice.status];
        return matchesSearch && matchesStatus;
    });

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (filterRef.current && !filterRef.current.contains(event.target)) {
                setShowFilter(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="invoices_list_wrapper mt-3">
            <div className="d-flex">
                <div className="cm_search">
                    <img src={searchIcon} alt="searchicon" />
                    <input
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        className="form-control"
                        type="text"
                        placeholder="Search" />
                </div>

                <div className="pro_drop" ref={filterRef}>
                    <span className="btn btn_outline_primary ms-3" onClick={toggleFilter}>
                        <img src={filterIcon} alt="filter icon" /> Filter
                    </span>

                    {showFilter && (
                        <div className="pro_drop_box">
                            <div className="pro_drop_box_body">
                                <button 
                                    className="btn btn-outline-secondary mb-3" 
                                    onClick={clearFilters}
                                >
                                    Clear Filters
                                </button>
                                <ul className="filter_dropdown_ul">
                                    {invoiceFilterStatus.map((filterItem) => (
                                        <li key={filterItem.id} className="filter_dropdown_li">
                                            <input 
                                                type="checkbox" 
                                                checked={!!selectedStatus[filterItem.id]} 
                                                onChange={() => handleStatusChange(filterItem.id)}
                                            />
                                            <label>{filterItem.value}</label>
                                        </li>
                                    ))}
                                </ul>
                                
                            </div>
                        </div>
                    )}
                </div>

            </div>

            <div className="cm_table_view mt-4 mb-5">
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Invoice ID</th>
                                <th>Date</th>
                                <th>Title</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Sent By</th>
                                <th>Due Date</th>
                                {showActionModal ? <th className="text-start">Action</th> : ''}
                                
                            </tr>
                        </thead>
                        <tbody>
                            {filteredList.map((invoice, index) => (
                                <tr key={invoice?.id}>
                                    <td>{index + 1}</td>
                                    <td>{formatDate(invoice.created_at)}</td>
                                    <td>{invoice.title}</td>
                                    <td>₹ {invoice.invoiceAmount.totalAmount.toFixed(2)}</td>
                                    <td style={{ color: getStatusText(invoice?.status).color }}>
                                        {capitalizeFirstLetter(getStatusText(invoice?.status).text)}
                                    </td>
                                    <td>{invoice.createBy}</td>
                                    <td>{formatDate(invoice.dueDate)}</td>
                                    {showActionModal ? <td onClick={() => setActiveAction(invoice)} className="text-start" style={{ cursor: 'pointer' }}>
                                     <img src={threeDot} alt="three dot" />
                                    </td> : ''}
                                    
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>


            {activeAction.id ? <ActionModalComponent
                                    _data={activeAction}
                                    title={activeAction.title}
                                    onClose={() => setActiveAction({})}
                                    url={activeAction?.pdfRecord?.pdfFile}
                                    viewType="INVOICE" /> : ''}
        </div>
    );
};

export default InvoicesList;
