import React, { useState } from 'react'
import plusblue from "../assets/dashboard/plus_blue.svg";
import CommonButton from './common/CommonButton';
import AddNewProject from '../modals/AddNewProject';
import ClientDetails from '../modals/ClientDetails';
import { useNavigate } from 'react-router-dom';
const QuickActions = ({ refreshPipline }) => {
    const userRole = localStorage.getItem('userRole');
    const navigate = useNavigate()

    const [showProjectForm, setShowProjectForm] = useState(false);
    const [isClientDetailsModalOpen, setClientDetailsModalOpen] = useState(false);
    const handleShow = () => setShowProjectForm(true);


    const openRedirectUrl = url => {
        navigate(url)
    }


  return (
    <div className="dash_action">
        <div className="heading_symbol">
        <h3 className="dashboard_heading">Quick Actions</h3>
        </div>
        <ul className="dash_action_ul">
        <li className="dash_action_li">
            <CommonButton
            label="Projects"
            fontSize="14px"
            width="100%"
            fontWeight="700"
            className="dash_common_btn white_bg_btn"
            icon={plusblue}
            onClick={handleShow}
            />
        </li>

        {(userRole != 'Manager' && userRole != 'Associate') ? (
          <li className="dash_action_li">
          <CommonButton
                label="Invoices"
                fontSize="14px"
                width="100%"
                fontWeight="700"
                className="dash_common_btn white_bg_btn"
                icon={plusblue}
                onClick={() => openRedirectUrl('/invoices')}
                />
            </li>
        ) : ''}


        
        <li className="dash_action_li">
            <CommonButton
            label="Contract"
            fontSize="14px"
            width="100%"
            fontWeight="700"
            className="dash_common_btn white_bg_btn"
            icon={plusblue}
            onClick={() => openRedirectUrl('/templates')}
            />
        </li>
        <li className="dash_action_li">
            <CommonButton
            label="Proposal"
            fontSize="14px"
            width="100%"
            fontWeight="700"
            className="dash_common_btn white_bg_btn"
            icon={plusblue}
            onClick={() => openRedirectUrl('/templates')}
            />
        </li>
        </ul>

        {showProjectForm && 
        <AddNewProject 
        onHide={() => setShowProjectForm(false)}
        isNext={isClientDetailsModalOpen}
        openClientDetailModal={() => setClientDetailsModalOpen(true)}
        isOpen={showProjectForm}/>}

        {isClientDetailsModalOpen && 
        <ClientDetails
        _refreshPipeLine={refreshPipline}
        openProject={() => setShowProjectForm(true)} 
        onHide={() => setClientDetailsModalOpen(false)} 
        isOpen={showProjectForm}/>}
    </div>
  )
}

export default QuickActions
