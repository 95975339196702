import React from 'react'

export default function AppPolicy() {

    const containerStyle = {
        maxWidth: "750px",
        margin: '0 auto',
        padding: '20px',
        fontFamily: 'Arial, sans-serif'
    };
    const marginBottom = {
        marginBottom: "0px"
    }
    const marginTop = {
        marginTop: "0px",
        marginBottom: "0px"

    }
    const marginWrap = {
        marginTop: "0px"
    }
    const paraAlign = {
        display: "inline",
        fontWeight: "400"
    }
    return (
        <div style={containerStyle}>
            <div>
                <h4 style={marginBottom}>Xora Business Solutions Private Limited Privacy Policy</h4>
                <h4 style={marginWrap}>Last updated: October 21, 2024</h4>
                <p>Xora Business Solutions Private Limited ("we," "our," or "us") is committed to protecting the privacy of our users ("you" or "your"). This Privacy Policy outlines how we collect, use, share, and protect your personal information when you interact with our website, software, and services. By using Xera's services, you agree to the terms outlined in this policy.</p>
            </div>
            <div>
                <h3>1. Information We Collect</h3>
                <p style={marginBottom}><b>Personal Information</b></p>
                <p style={marginTop}>When you sign up for Xera, we may collect personal information such as:</p>
                <ul>
                    <li>
                        <p>Full Name</p>
                    </li>
                    <li>
                        <p>Email Address</p>

                    </li>
                    <li><p>Phone Number</p></li>
                    <li><p>Business Name</p></li>
                    <li><p>Payment Information (including credit card or bank account details)</p></li>
                    <li><p>Government-issued IDs for verification purposes (if applicable)</p></li>
                </ul>
            </div>
            <div>
                <p style={marginBottom}><b>Log Data</b></p>
                <p style={marginTop}>Whenever you use Xora, we may collect log data that your browser or device sends to us. This may include information such as:</p>
                <ul>
                    <li>
                        <p>IP Address</p>
                    </li>
                    <li>
                        <p>Browser Type</p>
                    </li>
                    <li><p>Operating System</p></li>
                    <li><p>Date and Time of your visit</p></li>
                    <li><p>Pages or features accessed</p></li>
                    <li><p>Time spent on pages or features </p></li>
                    <li><p>Other diagnostic data</p></li>
                </ul>
                <p>Log data is collected to monitor the usage and performance of our services and to help improve security.</p>
                <h4 style={marginBottom}>Automatically Collected Information</h4>
                <p style={marginTop}>We may call alaged let orain information when you interact with Xor, including:</p>
                <ul>
                    <li>
                        <p>Device information (browser type, operating system, etc.)</p>
                    </li>
                    <li>
                        <p>Usage data (pages visited, features used, etc.)</p>
                    </li>
                    <li><p>Cookies and similar tracking technoloies</p></li>

                </ul>
                <p>Thance ately related information helps us analyze trends, improve our services, and</p>
                <h4 style={marginBottom}>Information from Third Parties</h4>
                <p style={marginTop}>If you connect third-party services to Xora (such as payment processing or messaging services), we may collect limited information from those services, as permitted by their privacy policies.</p>

            </div>
            <div>
                <h3>2. How We Use Your Information</h3>
                <p style={marginTop}>We use your personal information for the following purposes</p>
                <ul>
                    <li><p><sapn><b>Account Creation and Management:</b></sapn> To create and maintain your Xora, account.</p></li>
                    <li><p><span><b>Service Provision:</b></span> To provide you with the tools and services offered by Xora, including invoicing, client management, project management, etc.</p></li>
                    <li><p><span><b>Payment Processing:</b></span> To process transactions through integrated payment gateways (e.g., Gashfree for payments).</p></li>
                    <li><p><span><b>Customer Support:</b></span> To respond to inquiries, troubleshoot problems, and offer assistance.</p></li>
                    <li><p><span><b>Communication:</b></span> To send you information about updates, promotions, or important notices regarding your account or the service.</p></li>
                    <li><p><span><b>Improvement and Analytics:</b>To improve our services based on usage trends, feedback, and analytics.</span></p></li>
                </ul>
            </div>
            <div>
                <h3>3. Marketing Communications</h3>
                <p style={marginTop}>We may send marketing communications about Xora's products, services, promotions, and offers. You can unsubscribe from marketing communications at any time by:</p>
                <ul>
                    <li><p> Clicking the "unsubscribe" link at the bottom of any marketing email; or</p></li>
                    <li><p> Contacting us at <a href="mailto:support@getxora.com">support@getxora.com</a> to request removal from our mailing list.</p></li>
                </ul>
            </div>
            <div>
                <h3>4. Sharing Your Information</h3>
                <p style={marginTop}>We may send marketing communications about Xora's products, services, promotions, and offers. You can unsubscribe from marketing communications at any time by:</p>

                    <ul>
                        <li><p><span><b> Service Providers:</b></span> We may share your information with trusted service providers (such as Gashfree for payment processing and Meta for WhatsApp messaging) to perform services on our behalf.</p></li>
                        <li><p><span><b>Legal Compliance:</b></span>We may disclose your information if required by law, such as in response to a court order or to comply with legal processes.</p></li>
                        <li><p><span><b>Business Transfers:</b></span>If Xora Business Solutions Private Limited undergoes a merger, acquisition, or sale of assets, your information may be transferred to the new entity.</p></li>

                    </ul>
              
            </div>
            <div>
                <h3>5. Data Security</h3>
                <p > We take the security of your personal information seriously. However, please remember: The security of your Personal Information is important to us, but no method of transmission over the Internet or electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.
We encourage you to take additional steps to protect your personal information, such as using strong passwords and logging out after using our services</p>

              
            </div>
            <div>
                <h3>6. International Transfer</h3>
                <p> Your Personal Data may be transferred to and maintained on computers located outside of your state or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.</p>
                <p>Since Xora currently operates in India, data will be transferred and processed within India. By using our services, you consent to this transfer, storage, and processing.</p>

              
            </div>
            <div>
                <h3>7. Your Rights</h3>
                <p>As a user in India, you have the following rights regarding your personal data</p>
               <ul>
                <li><p><span><b>Access:</b></span>You can request access to your personal information.</p></li>
                <li><p><span><b>Correction:</b></span>You can request corrections to inaccurate or incomplete data.</p></li>
                <li><p><span><b>Deletion:</b></span> You can request the deletion of your personal information, subject to certain exceptions under applicable laws.</p></li>
                <li><p><span><b>Withdrawal of Consent:</b></span>If you have consented to the collection and use of your data, you can withdraw that consent at any time.</p></li>
               </ul>
               <p>To exercise any of these rights, please contact us at <a href="mailto:support@getxora.com">support@getxora.com</a>.</p>

              
            </div>
            <div>
                <h3>8.  Data Retention</h3>
                <p>We retain your personal information for as long as your account is active or as needed to provide our services. We may also retain your information to comply with legal obligations, resolve disputes, and enforce agreements.</p>
               

              
            </div>
            <div>
                <h3>9. Cookies and Tracking Technologies</h3>
                <p>Xora uses cookies and similar tracking technologies to improve user experience and analyze website traffic. You can control the use of cookies through your browser settings, though disabling cookies may affect your ability to use some features of Xora</p>
                <h3 style={marginBottom}>Do Not Track Signals</h3>
                <p style={marginWrap}>Currently, Xora does not respond to "Do Not Track" browser settings or signals.</p>
            </div>
            <div>
                <h3>10. Third-Party Links</h3>
                <p>Our website and services may contain links to third-party websites. This Privacy Policy does not apply to third-party websites, and we are not responsible for the privacy practices of those websites. We encourage you to review the privacy policies of those third-party sites before providing any personal information.</p>
                
            </div>
            <div>
                <h3>11. Children's Privacy</h3>
                <p>Xora Business Solutions Private Limited does not intend for its services to be used by children under the age of 18. We do not knowingly collect personal information from children. If we discover that we have collected information from a child, we will take steps to delete the information as soon as possible.</p>
                
            </div>
            <div>
                <h3>12. Changes to This Privacy Policy</h3>
                <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for legal reasons. We will notify you of any significant changes by posting the updated policy on our website and indicating the date of the most recent update.</p>
                
            </div>
            <div>
                <h3>13. Contact Us</h3>
                <p>If you have any questions or concerns about this Privacy Policy, or if you would like to exercise your rights, please contact us at:</p>
                
            </div>
            <h4 style={marginBottom}>Xora Business Solutions Private Limited</h4>
                <h4 style={marginTop}><b>Email:</b> <a href="mailto:support@getxora.com">support@getxora.com</a></h4>
                <h4 style={marginTop}><b><sapn>Address:</sapn></b> <p style={paraAlign}>D-1203 Wadhwa Atmosphere, Mulund Goregaon Link Rd, Mulund W, Mumbai 400080.</p></h4>
        </div>
    )
}
