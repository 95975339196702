import React from "react";
import CommonButton from "../components/common/CommonButton";

const DeleteModal = ({ title, isOpen, deleteId, onClose, onSure }) => {
  if (!isOpen) return null;

  return (
    <div className="signature-modal">
      <div className="modal-content">
        <h3 style={{ fontSize: "16px" }}>{title}</h3>
        <div className="signature-modal-button">
          <CommonButton
            label="No"
            fontSize="14px"
            fontWeight="700"
            className={`dash_common_btn`}
            icon=""
            onClick={onClose}
          />
          <CommonButton
            label="Yes"
            fontSize="14px"
            fontWeight="700"
            icon=""
            className={`dash_common_btn`}
            onClick={() => onSure(deleteId)}
          />
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
