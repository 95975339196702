import React from "react";
import _logo from '../../assets/logos/xora_logo.svg'

const Logo = () => {
    return (
        <div>
            <img src={_logo} alt="xora_logo"/>
        </div>
    )
}

export default Logo;