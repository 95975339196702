import React, { useState, useEffect, useRef } from "react";
import filtercircle from "./../assets/dashboard/filter_cross.svg";
import Accordion from "react-bootstrap/Accordion";
import { getPhaseService } from "../services";


export default function ProjectFilter({ handleFilterClose, filterBy, setFilterBy, saveData }) {
  const [_filter, _setFilter] = useState({
    status: {},
    phase: {}
  });

  const [phaseList, setPhaseList] = useState([]);

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (filterBy) {
      _setFilter(filterBy);
    }
  }, [filterBy]);

  useEffect(() => {
    if (saveData !== null) {
      _setFilter(saveData);
    }
  }, [saveData]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        handleFilterClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleFilterClose]);

  const handleCheckboxChange = (category, value, e) => {
    let temp = { ..._filter };
    temp[category][value] = e.target.checked;
    _setFilter(temp);
  };

  const handleSubmit = () => {
    localStorage.setItem("filterCheks", JSON.stringify(_filter));
    setFilterBy(_filter);
    handleFilterClose();
  };



  const getPhase = () => {
    let userData = JSON.parse(localStorage.getItem('authUserData'))
    let businessID = userData?.businessDetail?.businessId
    getPhaseService(businessID).then(res => {
        if(res.data.status){
          setPhaseList(res?.data?.responseData?.phase || [])
        }
    })
  }

  useEffect(() => {
    getPhase()
  }, [])

  return (
    <div ref={wrapperRef} className="project_filter_main">
      <div className="project_filter_upper">
        <h4 className="filter_popup_heading">Filter</h4>
        <img src={filtercircle} onClick={handleFilterClose} alt="Close filter" />
      </div>
      <div className="project_filter_accor">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Status</Accordion.Header>
            <Accordion.Body>
              <ul className="filter_dropdown_ul">
                {Object.keys(_filter.status).map((statusName) => (
                  <li key={statusName} className="filter_dropdown_li">
                    <input
                      type="checkbox"
                      checked={_filter.status[statusName]}
                      onChange={(e) => handleCheckboxChange("status", statusName, e)}
                    />
                    <label>{statusName}</label>
                  </li>
                ))}
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Phase</Accordion.Header>
            <Accordion.Body>
              <ul className="filter_dropdown_ul">
                {phaseList.map((item) => (
                  <li key={item.name} className="filter_dropdown_li">
                    <input
                      type="checkbox"
                      checked={_filter.phase[item.name]}
                      onChange={(e) => handleCheckboxChange("phase", item.name, e)}
                    />
                    <label>{item.name}</label>
                  </li>
                ))}
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Date</Accordion.Header>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="modal_btn_div project_filter_btn">
        <button className="_btn_transparent_ modal_div_button" onClick={handleFilterClose}>
          <span>Cancel</span>
        </button>
        <button className="_btn_normal_ modal_div_button" onClick={handleSubmit}>
          <span>Submit</span>
        </button>
      </div>
    </div>
  );
}
