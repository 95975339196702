import { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import { Tab, Tabs } from "react-bootstrap";
import { getExpensesList } from "../../services";
import { _contractStatus, contractStatus } from "../../utils/constant";
import threeDotIcon from "../../assets/logos/three-dot.svg";
import editIcon from "../../assets/logos/edit_icon.svg";
import CustomTooltip from "../../components/common/CustomTooltip";
import editBlackIcon from "../../assets/logos/edit_black_icon.svg";
import ReviewExpense from "../projects/expenses/ReviewExpense";


const formatAmountInINR = (amount) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };

  
const dateRender = (date) => {
    try {
      return format(parseISO(date?.replace("Z", "")), "MMMM dd, yyyy");
    } catch (error) {
      return date;
    }
  };

const ClientExpensesComponent = ({ selectedProject }) => {
  const userDetail = JSON.parse(localStorage.getItem("authUserData")) || null;
  const [list, setList] = useState([]);
  const [list2, setList2] = useState([]);
  const [pageLoader, setPageLoader] = useState(true);
  const [isEditView, setIsEditView] = useState(false);
  const [resubmitExpenseData, setResubmitExpenseData] = useState({});
  const [activeTab, setActiveTab] = useState("myExpenses");


  const fetchExpensesList = async () => {
    setPageLoader(true);
    try {
      let params = `?businessId=${userDetail?.businessDetail?.businessId}&projectId=${selectedProject?.value}`;
      const response = await getExpensesList(params);
      if(response?.data?.status){
        setList(response.data?.responseData?.forReviews);
        setList2(response.data?.responseData?.myExpenses);
      }
      
    } catch (error) {
      console.error("Error fetching Expenses list:", error);
    }
    setPageLoader(false);
  };

  useEffect(() => {
      fetchExpensesList()
  }, [selectedProject]);

  const handleResubmitExpensesClose = () => {
    setIsEditView(false);
    setResubmitExpenseData({});
    fetchExpensesList()
  }


  if(pageLoader){
    return <div>Loading...</div>
  }else{

    if(isEditView){
        return <ReviewExpense
                    handleCancel={handleResubmitExpensesClose}
                    expenseReviewData={resubmitExpenseData} />
    }else{
        return (
            <div>
              <h3>Expenses Details</h3>
              <p>Reminders for expenses will be sent every 2 days until paid.</p>
              <Tabs
                className="mt-3 mb-3"
                activeKey={activeTab}
                onSelect={(tab) => setActiveTab(tab)}
              >
                <Tab eventKey="myExpenses" title="My Expenses">
                  <div className="table-responsive">
                <table className="project_table dealroom_table">
                    <thead style={{ position: "sticky", top: -1, zIndex: 1 }}>
                        <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Title</th>
                            <th>Amount</th>
                            <th>Added By</th>
                            <th>Status</th>
                        </tr>
                    </thead>
    
                    <tbody>
                        {
                              list.map((myExpense, i) => {
                                const statusObject = contractStatus.find(
                                  (status) => status.id === myExpense.reviewStatus
                                );
                                return (
                                  <tr style={{ cursor: "pointer" }} onClick={() => (setIsEditView(true), setResubmitExpenseData(myExpense))}>
                                    <td
                                      style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span style={{ margin: "0 23px" }}>
                                        <img
                                          src={threeDotIcon}
                                          alt="action plan"
                                          width={15}
                                          height={15}
                                          style={{
                                            marginRight: 10,
                                            cursor: "pointer",
                                            transform: "rotate(90deg)",
                                          }}
                                        />
                                        {statusObject &&
                                        statusObject.value === "Rejected" ? (
                                          <CustomTooltip text="Re-Submit">
                                            <img
                                              src={editIcon}
                                              alt="edit plan"
                                              style={{
                                                marginLeft: 10,
                                                cursor: "pointer",
                                              }}
                                            />
                                          </CustomTooltip>
                                        ) : (
                                          <img
                                            src={editBlackIcon}
                                            alt="edit plan"
                                            style={{ marginLeft: 10 }}
                                          />
                                        )}
                                      </span>
                                      <span style={{ marginTop: 3 }}>L-{i + 1}</span>
                                    </td>
                                    <td>{dateRender(myExpense?.expenseDate)}</td>
                                    <td className="dealroom_table_title">
                                      {myExpense?.title}
                                    </td>
                                    <td>{formatAmountInINR(myExpense?.amount)}</td>
                                    <td>{myExpense?.createBy || "N/A"}</td>
                                    <td>
                                      <p
                                        className="phases"
                                        style={{
                                          backgroundColor: statusObject.color,
                                        }}
                                      >
                                        {statusObject.value}
                                      </p>
                                    </td>
                                  </tr>
                                );
                              })
                        }
                    </tbody>
                  
                </table>
              </div>
              </Tab>
              <Tab eventKey="forReview" title="For Review">
              <div className="table-responsive">
                <table className="project_table dealroom_table">
                    <thead style={{ position: "sticky", top: -1, zIndex: 1 }}>
                        <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Title</th>
                            <th>Amount</th>
                            <th>Added By</th>
                            <th>Status</th>
                        </tr>
                    </thead>
    
                    <tbody>
                        {
                              list2.map((myExpense, i) => {
                                const statusObject = contractStatus.find(
                                  (status) => status.id === myExpense.reviewStatus
                                );
                                return (
                                  <tr style={{ cursor: "pointer" }} onClick={() => (setIsEditView(true), setResubmitExpenseData(myExpense))}>
                                    <td
                                      style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span style={{ margin: "0 23px" }}>
                                        <img
                                          src={threeDotIcon}
                                          alt="action plan"
                                          width={15}
                                          height={15}
                                          style={{
                                            marginRight: 10,
                                            cursor: "pointer",
                                            transform: "rotate(90deg)",
                                          }}
                                        />
                                        {statusObject &&
                                        statusObject.value === "Rejected" ? (
                                          <CustomTooltip text="Re-Submit">
                                            <img
                                              src={editIcon}
                                              alt="edit plan"
                                              style={{
                                                marginLeft: 10,
                                                cursor: "pointer",
                                              }}
                                            />
                                          </CustomTooltip>
                                        ) : (
                                          <img
                                            src={editBlackIcon}
                                            alt="edit plan"
                                            style={{ marginLeft: 10 }}
                                          />
                                        )}
                                      </span>
                                      <span style={{ marginTop: 3 }}>L-{i + 1}</span>
                                    </td>
                                    <td>{dateRender(myExpense?.expenseDate)}</td>
                                    <td className="dealroom_table_title">
                                      {myExpense?.title}
                                    </td>
                                    <td>{formatAmountInINR(myExpense?.amount)}</td>
                                    <td>{myExpense?.createBy || "N/A"}</td>
                                    <td>
                                      <p
                                        className="phases"
                                        style={{
                                          backgroundColor: statusObject.color,
                                        }}
                                      >
                                        {statusObject.value}
                                      </p>
                                    </td>
                                  </tr>
                                );
                              })
                        }
                    </tbody>
                  
                </table>
              </div>
              </Tab>
            </Tabs>









            
            </div>
          );
    }


  }


};

export default ClientExpensesComponent;
