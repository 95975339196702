import React from 'react';

const DescriptionDropdown = ({ value, placeholder, visible }) => {
  if (!visible) return null;

  return (
    <div className="dropdown">
      <div className="dropdown-content">
        {value || placeholder}
      </div>
    </div>
  );
};

export default DescriptionDropdown;
