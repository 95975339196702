import React, { Fragment, useEffect, useState, useRef } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import Select from "react-select";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';

import plus from "../../assets/dashboard/plus.svg";
import folderIcon from "../../assets/dashboard/folder-icon.svg";
import docIconOutline from "../../assets/dashboard/doc-icon-outline.svg";
import imageIconOutline from "../../assets/dashboard/image-icon-outline.svg";
import pdfIconOutline from "../../assets/dashboard/pdf-icon-outline.svg";
import galleryIconOutline from "../../assets/dashboard/gallery-outline-item.svg";
import threeDotsIcon from "../../assets/dashboard/three-dots.svg";
import fileOutlineIcon from "../../assets/dashboard/file-outline-icon.svg";
import {
    getFileFoldersService,
    getFileFoldersByBusinessService,
    getGalleryListService,
    updateGalleryService,
    updateGalleryBussinesService,
    updateFileFoldersService,
    fileFolderUploadService,
    galleryUpdateFavService,
    getProjectByBusinessService,
    getClientbyProjectIdService,
    shareFileService
} from "../../services";
import { formatDateMMddhhmmss, getFileTypeCode } from "../../utils/helper";
import { DownloadIcon, LikeIcon, ShareIcon } from "../../components/SVG";
import useToast from "../../utils/customHooks/useToast";


const FilesContent = ({ activeTab, isGlobal, isGlobalLibrary, selectedProjectForClient, removeDeleteIcon }) => {
    const projectDetail = JSON.parse(localStorage.getItem('projectDetail'));
    const token = localStorage.getItem('token');
    const userRole = localStorage.getItem('userRole');
    const _selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
    let userDetail = JSON.parse(localStorage.getItem("authUserData")) || null;
    const showToast = useToast()
    const [mainListAll, setMainListAll] = useState([]);
    const [mainList, setMainList] = useState([]);
    const [showFileModal, setShowFileModal] = useState(false);
    const [createFolderModal, setCreateFolderModal] = useState(false);
    const [folderType, setFolderType] = useState('');
    const [folderName, setFolderName] = useState('');
    const [folderNameErr, setFolderNameErr] = useState('');
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [isGalleryView, setIsGalleryView] = useState(false);
    const [activeGallery, setIsActiveGallery] = useState({});
    const [galleryId, setGalleryId] = useState('');
    const [galleryList, setGalleryList] = useState([]);
    const [projectsList, setProjectList] = useState([]);
    const [selectedProject, setSelectedProject] = useState({});
    const [selectedImagesCount, setSelectedImagesCount] = useState(0);
    const [isFileSelected, setIsFileSelected] = useState(false);
    const [fileLoader, setFileLoader] = useState(false);
    const [activeFile, setActiveFile] = useState({});
    const [sharePopup, setSharePopup] = useState(false);
    const [clientList, setClientList] = useState([]);
    const [selectedClients, setSelectedClients] = useState([]);

    const [show, setShow] = useState(false);
    const dropdownRef = useRef(null);


    useEffect(() => {
        if(projectDetail?.id){
            let query = `?projectId=${projectDetail?.id}`
            getClientbyProjectIdService(query).then(res => {
                if (res?.data?.status) {
                    setClientList(res.data.responseData.clients || []);
                }
            })
        }
        
    }, [])

    
  
    const handleToggle = () => {
      setShow((prev) => !prev);
    };
  
    const closeDropdown = () => {
      setShow(false);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          closeDropdown();
        }
      };

  
    useEffect(() => {

      document.addEventListener('mousedown', handleClickOutside);
  
      return () => {
        // Cleanup the event listener on component unmount
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);


 



    const handleImageSelect = (index) => {
        const updatedGalleryList = galleryList.map((item, idx) => 
            idx === index ? { ...item, isSelected: !item.isSelected } : item
        );
        const isAnyFileSelected = updatedGalleryList.filter(item => item.isSelected);
        setSelectedImagesCount(isAnyFileSelected.length)

        if(isAnyFileSelected.length > 0) {
            setIsFileSelected(true);
        }else{
            setIsFileSelected(false);
        }
        
        setGalleryList(updatedGalleryList);
    };
    
    const handleSelectAll = () => {
        const updatedGalleryList = galleryList.map(item => 
            isFileSelected ? { ...item, isSelected: false } : { ...item, isSelected: true }
        );


        const _selectedImagesCount = updatedGalleryList.filter(item => item.isSelected)

        setSelectedImagesCount(_selectedImagesCount.length)

        setIsFileSelected(!isFileSelected);
        setGalleryList(updatedGalleryList);
    };
    


    useEffect(() => {
        const filePopUsStatus = localStorage.getItem('filePopUsStatus') || false;
        if (activeTab === "Files") {
            if (filePopUsStatus) {
                setShowFileModal(false);
            } else {
                setShowFileModal(true);
            }
        }
    }, [activeTab]);

    const filePopUpHandler = (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            localStorage.setItem('filePopUsStatus', true);
        } else {
            localStorage.setItem('filePopUsStatus', false);
        }
    };

    const handleDropDwonClick = type => {
        setShowFileModal(false);
        if (type === 'file') {
            document.getElementById('fileInput').click();
        } else {
            setCreateFolderModal(true);
            setFolderType(type);
        }
    };

    const getFileFolders = async () => {
        

        if(isGlobalLibrary){
            let query = '';
            query = `?businessId=${userDetail.businessDetail.businessId || userDetail.businessDetail.id}`;
            try {
                const res = await getFileFoldersByBusinessService(query);
                if (res.data.status) {
                    setMainListAll(res.data.responseData.data || []);
                    setMainList(res.data.responseData.data || []);  
                }
            } catch (error) {
                console.error('Error fetching file folders:', error);
            }


            
       
        }else{

            let query = '';

            if(!isGlobal){
                query = `?projectId=${projectDetail?.id || selectedProjectForClient?.value}`;
            }else{
                query = `?projectId=${selectedProject?.value}`;
            }

            try {
                const res = await getFileFoldersService(query);
                if (res.data.status) {
                    setMainListAll(res.data.responseData.data || []);
                    setMainList(res.data.responseData.data || []);  
                }
            } catch (error) {
                console.error('Error fetching file folders:', error);
            }
        }


        
        

    };

    useEffect(() => {
        getFileFolders();
    }, [selectedProject]);

    const handleDeleteFolder = async (index) => {
        // Clone the main list
        const updatedMainList = [...mainListAll];
        const _mainList = [...mainList];
    
        // Remove the folder at the specified index
        _mainList.splice(index, 1);
    
        const payload = {
            projectId: projectDetail?.id || selectedProjectForClient?.value,
            data: [],
        };
    
        // Update the main list based on breadcrumbs
        const removeFolderFromList = (folders, breadcrumbIndex = 0) => {
            if (breadcrumbIndex >= breadcrumbs.length) return;
    
            const currentBreadcrumb = breadcrumbs[breadcrumbIndex];
            const targetFolder = folders.find(item => item.name === currentBreadcrumb.name);
    
            if (targetFolder) {
                if (breadcrumbIndex === breadcrumbs.length - 1) {
                    targetFolder.folderList.splice(index, 1); // Remove the folder
                } else {
                    removeFolderFromList(targetFolder.folderList, breadcrumbIndex + 1);
                }
            }
        };
    
        if (breadcrumbs.length > 0) {
            removeFolderFromList(updatedMainList);
            payload.data = updatedMainList;
        } else {
            payload.data = _mainList;
        }
    
        // Update state with the new list
        setMainList(_mainList);
    
        try {
            const res = await updateFileFoldersService(payload);
            if (res.data.status) {
                setMainListAll(res.data.responseData.data);
            }
        } catch (error) {
            console.error('Error deleting folder:', error);
        }
    };
    


    const handleCreateFolder = async (e) => {
        e.preventDefault();
        if (folderName.trim() === '') {
          setFolderNameErr('Folder name is required');
          return;
        }
        setFolderNameErr('');
      
        const updatedMainList = [...mainListAll];
        const _mainList = [...mainList];

        const payload = {
          projectId: projectDetail?.id || selectedProject?.value || selectedProjectForClient?.value,
          data: [],
        };
      
        const newFolder = {
          name: folderName,
          type: folderType === 'folder' ? 5 : 4,
          folderList: [],
          filePath: null,
        };

        if(folderType == 'gallery'){
            newFolder.galleryFrontendId = formatDateMMddhhmmss(new Date());
        }
      
        const findAndAddFolder = (folders, breadcrumbIndex = 0) => {
          if (breadcrumbIndex >= breadcrumbs.length) return;
      
          const currentBreadcrumb = breadcrumbs[breadcrumbIndex];
          const targetFolder = folders.find(item => item.name === currentBreadcrumb.name);
      
          if (targetFolder) {
            if (breadcrumbIndex === breadcrumbs.length - 1) {
              targetFolder.folderList.push(newFolder);
            } else {
              findAndAddFolder(targetFolder.folderList, breadcrumbIndex + 1);
            }
          }
        };

        if (breadcrumbs.length > 0) {
          findAndAddFolder(updatedMainList);
          payload.data = updatedMainList;
        } else {
          payload.data = [newFolder, ...mainList];
        }


        const newMainList = [newFolder, ..._mainList]
        setMainList(newMainList);
      
        try {
        setCreateFolderModal(false);
          const res = await updateFileFoldersService(payload);
          if (res.data.status) {
            setMainListAll(res.data.responseData.data);
            setFolderName('');
          }
        } catch (error) {
          console.error('Error creating folder:', error);
        }
      };




    const handleDocumentView = (item) => {
        if(item.type == 4 || item.type == 5) {
            if(item.type == 4){
                setIsGalleryView(true);
                setIsActiveGallery(item)
            }else{
                setIsGalleryView(false);
            }
            setMainList(item.folderList);
            setBreadcrumbs([...breadcrumbs, item]);
        }else{
            window.open(item.filePath, "_blank");
        }
    };

    const handleBreadcrumbClick = (index) => {
        setGalleryList([]);
        setIsActiveGallery({});
        setIsGalleryView(false);
        const newBreadcrumbs = breadcrumbs.slice(0, index + 1);
        setBreadcrumbs(newBreadcrumbs);
        setMainList(index === -1 ? mainListAll : newBreadcrumbs[newBreadcrumbs.length - 1].folderList);
    };



    const handleFileUpload = async (e) => {
        try {
            const files = e.target.files;
            if (files.length === 0) return;
    
            const updatedMainList = [...mainListAll];
            const _mainList = [...mainList];
            const payload = {
                projectId: projectDetail?.id || selectedProjectForClient.value,
                data: [],
            };
    
            setFileLoader(true);
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const formData = new FormData();
    
                if (file) {
                    const fileTypeCode = getFileTypeCode(file.type);
                    const fileName = file.name;
                    formData.append('businessId', userDetail?.businessDetail?.businessId);
                    formData.append('name', fileName);
                    formData.append('type', fileTypeCode);
                    formData.append('file', file);

                    if(projectDetail?.id){
                        formData.append('projectId', projectDetail?.id);
                    }
    
                    // Upload each file to S3
                    const res = await fileFolderUploadService(formData, token);
                    if (res.data.status) {
                        const newFolder = {
                            name: fileName,
                            type: fileTypeCode,
                            folderList: [],
                            filePath: res.data.responseData.file,
                        };
    
                        const findAndAddFolder = (folders, breadcrumbIndex = 0) => {
                            if (breadcrumbIndex >= breadcrumbs.length) return;
    
                            const currentBreadcrumb = breadcrumbs[breadcrumbIndex];
                            const targetFolder = folders.find(item => item.name === currentBreadcrumb.name);
    
                            if (targetFolder) {
                                if (breadcrumbIndex === breadcrumbs.length - 1) {
                                    targetFolder.folderList.push(newFolder);
                                } else {
                                    findAndAddFolder(targetFolder.folderList, breadcrumbIndex + 1);
                                }
                            }
                        };
    
                        // Handle folder structure
                        if (breadcrumbs.length > 0) {
                            findAndAddFolder(updatedMainList);
                        } else {
                            updatedMainList.unshift(newFolder);
                        }
    
                        _mainList.unshift(newFolder); // Add to the local list
                    }
                }
            }
    
            // Update the payload with the modified folder structure
            payload.data = breadcrumbs.length > 0 ? updatedMainList : _mainList;
    
            // Update state
            setMainList(_mainList);
            setCreateFolderModal(false);
    
            // Save the updated folder structure
            try {
                const res = await updateFileFoldersService(payload);
                if (res.data.status) {
                    setMainListAll(res.data.responseData.data);
                    setFolderName('');
                    setFileLoader(false);
                }
            } catch (error) {
                console.error('Error updating folder structure:', error);
            }
        } catch (error) {
            console.error("Error uploading files:", error);
        }

        setFileLoader(false);
    };
    




      const handleMultiFileUpload = async (event) => {
        const files = Array.from(event.target.files);
    
        if (files.length === 0) {
            return;
        }
    
        const imageList = files.filter(file => {
            if (file.type.startsWith('image/')) {
                return true;
            } else {
                return false;
            }
        });
    
        if (imageList.length === 0) {
            return;
        }


        if(isGlobalLibrary){
            const formData = new FormData();
            formData.append('businessId', userDetail.businessDetail.businessId || userDetail.businessDetail.id);
            formData.append('galleryName', activeGallery.name);
            formData.append('galleryFrontendId', activeGallery.galleryFrontendId);
            imageList.forEach(image => formData.append('imageList', image));
        
            try {
                let query = '';
    
                if(galleryId){
                    query = `?galleryId=${galleryId}`
                }
    
                setFileLoader(true);
                const response = await updateGalleryBussinesService(query, formData, token);
                setFileLoader(false)
                getGalleryList()
            } catch (error) {
                console.error('Error uploading images:', error);
                setFileLoader(false)
            }

        }else{
            const formData = new FormData();
            formData.append('projectId', projectDetail?.id || selectedProjectForClient?.value);
            formData.append('galleryName', activeGallery.name);
            formData.append('galleryFrontendId', activeGallery.galleryFrontendId);
            imageList.forEach(image => formData.append('imageList', image));
        
            try {
                let query = '';
    
                if(galleryId){
                    query = `?galleryId=${galleryId}`
                }
    
                setFileLoader(true);
                const response = await updateGalleryService(query, formData, token);
                setFileLoader(false)
                getGalleryList()
            } catch (error) {
                console.error('Error uploading images:', error);
                setFileLoader(false)
            }
        }

    
       
    };




    const getGalleryList = async () => {
        const query = `?projectId=${projectDetail?.id || selectedProjectForClient?.value}`;
        try {
            const res = await getGalleryListService(query);
            if(res.data.status){
                const resGalleryList = res.data.responseData.data || [];
                const _resGalleryList = resGalleryList.find((item) => item.galleryFrontendId === activeGallery.galleryFrontendId)
                setGalleryList(_resGalleryList.imageList);
                setGalleryId(_resGalleryList.id)
            }
        } catch (error) {
            console.error('Error fetching file folders:', error);
        }
    };


    useEffect(() => {
        if(activeGallery.galleryFrontendId){
            getGalleryList()
        }
    }, [activeGallery])



    const handleDownloadSelectedFile = async () => {
        try {
            let _galleryList = galleryList.filter((item) => item.isSelected);
    
            for (const file of _galleryList) {
                const url = file.imageUrl;
                const fileNameWithExt = url.split('/').pop();
                const fileNameWithoutExt = fileNameWithExt.split('.').slice(0, -1).join('.');
                await downloadFile(file.imageUrl, fileNameWithoutExt); 
            }
        } catch (error) {
            console.error('Error downloading files:', error);
        }
    };
    
    const downloadFile = async (fileUrl, fileName) => {
        const response = await fetch(fileUrl);
        
        if (!response.ok) {
            throw new Error(`Failed to fetch file: ${fileName}`);
        }
    
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
    };



    const handleSingleDownload = async url => {
        try {
            const fileNameWithExt = url.split('/').pop();
            const fileNameWithoutExt = fileNameWithExt.split('.').slice(0, -1).join('.');
            await downloadFile(url, fileNameWithoutExt); 
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };



    const handleFavChange = async (item) => {
        let query = `?imageId=${item.id}`;
        const formData = new FormData();
        formData.append('isFav', !item.isFav);
    
        try {
            const token = localStorage.getItem('token');
            let res = await galleryUpdateFavService(query, formData, token);
            if(res.data.status){
                getGalleryList()
            }
           
        } catch (error) {
            console.error("Error updating favorite status:", error);
        }
    };





    const getAllProjects = async () => {
        try {
            const res = await getProjectByBusinessService(userDetail?.businessDetail?.businessId);
            let _projects = res.data.responseData.projectData || [];
            if(_projects.length > 0) {
                _projects = _projects.map(item => ({...item, label: item.projectName, value: item.id}))
                setProjectList(_projects);
            }
        } catch (error) {
            console.error('Error fetching projects:', error);
        }
    };
    
    
    useEffect(() => {
        if(userDetail?.businessDetail?.businessId && isGlobal){
            getAllProjects();
        }
        
    }, [isGlobal])


    useEffect(() => {
        document.getElementById('remove_btn_class')?.classList?.remove('btn')
    }, [])

useEffect(() => {
    if(_selectedProject && projectsList.length){
        let _res = projectsList.find(i => _selectedProject == i.id);
        setSelectedProject(_res)

    }
}, [_selectedProject, projectsList])


const handleCheckboxChange = (client) => {
    const updatedClientList = clientList.map((item) =>
      item.id === client.id ? { ...item, isChecked: !item.isChecked } : item
    );
    setClientList(updatedClientList);

    const updatedSelectedClients = updatedClientList.filter((item) => item.isChecked)
    setSelectedClients(updatedSelectedClients);
  };


  const shareFileClick = async () => {
    if (selectedClients.length) {
        const fileNameWithoutExtension = activeFile.name.replace(/\.[^/.]+$/, ""); 
        const params = {
            projectId: projectDetail?.id,
            fileName: fileNameWithoutExtension,
            url: activeFile.filePath,
            clients: selectedClients
        };
        setActiveFile({});
        setSharePopup(false);
        try {
            const response = await shareFileService(params, token);
            showToast("File Share successfully", "success");
        } catch (error) {
            console.error("Error sharing file:", error);
        }
    }else{
        showToast("Please select client", "error");
    }
};


    
    return (
        <div className="file_content_wrapper">
            <input
                type="file"
                multiple
                id="fileInput"
                style={{ display: 'none' }}
                onChange={handleFileUpload} />

            <input
                type="file"
                multiple
                accept="image/*"
                id="multifileInput"
                style={{ display: 'none' }}
                onChange={handleMultiFileUpload} />


            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex justify-content-between align-items-center">
                    {isGlobal ?  <Select
                                    classNamePrefix="cm_ui_select"
                                    value={selectedProject.value ? selectedProject : ''}
                                    onChange={e => setSelectedProject(e)}
                                    options={projectsList}
                                    placeholder="Select Project" /> :isGlobalLibrary ? <div className="ps-5">
                                        
                                        <h3>Library</h3>
                                        <p>The library section is your centralized repository for important documents, resources and reusable content that you and your team would use for multiple projects. This is only visible to</p>

                                        </div> : ''}
                </div>
                
                    {
                        isGalleryView ? (
                            isFileSelected ? (
                                <div>
                            
                                    <span onClick={handleDownloadSelectedFile} className="btn btn-primary px-4"><DownloadIcon color="white" /> <b className="ms-1">Download</b></span>
                                    {/* <span className="btn btn-primary ms-3 px-4"><LikeIcon color="white" /> <b className="ms-1">Favorites</b></span>
                                    <span className="btn btn-primary ms-3 px-4"><ShareIcon color="white" /> <b className="ms-1">Share</b></span> */}
                                </div>
                            ) : (
                                <div className="galleries_action">
                                <span onClick={() => document.getElementById('multifileInput').click()} className="btn btn-primary">Add Media</span>
                                </div>
                            )
                            
                        ) : (
                            <div className="cm_drop_dwon">
                                <Dropdown>

                                    {userRole == 'client' ? '' : <Dropdown.Toggle  className="common-button shadow dash_common_btn px-5" variant="primary" id="remove_btn_class">
                                    <img src={plus} alt='icon'/> <b> New</b> 
                                </Dropdown.Toggle>}

                                
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => handleDropDwonClick('file')} href="javascript:void(0)">Upload File</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleDropDwonClick('folder')} href="javascript:void(0)">Create Folder</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleDropDwonClick('gallery')} href="javascript:void(0)">Create Gallery</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        )
                    }
                    
                
            </div>
            
            <ul className="cm_breadcrumbs">
                    {breadcrumbs.length > 0 && (
                        <li onClick={() => handleBreadcrumbClick(-1)}>Root</li>
                    )}
                    {breadcrumbs.map((item, index) => (
                        <li className={breadcrumbs.length == (index + 1) ? 'active' : ''} key={index} onClick={() => (breadcrumbs.length - 1) == index ? null : handleBreadcrumbClick(index)}>/ {item.name}</li>
                    ))}
                </ul>
            <div className="file_content_body">
            {isGalleryView ? (
                <div className="selected_action">
                    {selectedImagesCount ? <span> {selectedImagesCount} Selected </span> : ''}
                    <span className="btn btn-link" onClick={handleSelectAll}>{isFileSelected ? 'Deselect All' : 'Select All'}</span>
                </div>
            ) : ''}


            {fileLoader ? <div>File Uploading...</div> : activeGallery.galleryFrontendId ? (
                        <div className="d-flex flex-wrap">
                            {galleryList.map((image, index) => (
                                <div key={image.id} className="folder_card">
                                <div className="gallery_image">
                                    <div className="media_select">
                                        <input
                                            checked={image.isSelected}
                                            onChange={() => handleImageSelect(index)}
                                            type="checkbox" />
                                        <span />
                                    </div>
                                    <div className="media__img"><img src={image.imageUrl} alt="Image" /></div>
                                </div>

                                <div className="image_sm_action">
                                    <span className="me-3" onClick={() => handleSingleDownload(image.imageUrl)}><DownloadIcon color="#989898" /></span>
                                    <span onClick={() => handleFavChange(image)} className={`me-3 ${image.isFav ? 'active' : ''}`}><LikeIcon color="#989898" /></span>
                                    
                                    <span className="cm_share_drop_opt" ref={dropdownRef}>
                                        <div onClick={handleToggle}>
                                            <ShareIcon color="#989898" />
                                        </div>
                                        {
                                            show ? <div className="cm_share_drop_opt_box">
                                            <div onClick={closeDropdown}>
                                                <FacebookShareButton url={image.imageUrl} quote="Check out this awesome content">
                                                    <FacebookIcon size={40} round />
                                                </FacebookShareButton>
                                            </div>
                                            <div onClick={closeDropdown}>
                                                <TwitterShareButton url={image.imageUrl} quote="Check out this awesome content">
                                                    <TwitterIcon size={40} round />
                                                </TwitterShareButton>
                                            </div>
                                            <div onClick={closeDropdown}>
                                                <LinkedinShareButton url={image.imageUrl} quote="Check out this awesome content">
                                                    <LinkedinIcon size={40} round />
                                                </LinkedinShareButton>
                                            </div>
                                            <div onClick={closeDropdown}>
                                                <WhatsappShareButton url={image.imageUrl} quote="Check out this awesome content">
                                                    <WhatsappIcon size={40} round />
                                                </WhatsappShareButton>
                                            </div>
                                        </div> : ''
                                        }
                                    </span>
                                </div>
                                </div>
                            ))}
                        </div>
                    ) : <Fragment>

                    <div className="d-flex flex-wrap">
                        {mainList.map((item, index) => (
                            <div key={index} className="folder_card">
                                <div className="folder_icon">
                                    {item.type === 5 ? <img onClick={() => handleDocumentView(item)} src={folderIcon} alt="Folder Icon" /> : ''}
                                    {item.type === 4 ? <img onClick={() => handleDocumentView(item)} src={galleryIconOutline} alt="Gallery Icon" /> : ''}
                                    {item.type === 3 ? <img onClick={() => handleDocumentView(item)} src={pdfIconOutline} alt="PDF Icon" /> : ''}
                                    {item.type === 2 ? <img onClick={() => handleDocumentView(item)} src={docIconOutline} alt="DOCX Icon" /> : ''}
                                    {item.type === 1 ? <img onClick={() => handleDocumentView(item)} src={imageIconOutline} alt="Image Icon" /> : ''}

                                    {!removeDeleteIcon ? (
                                        <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                                <img src={threeDotsIcon} alt='icon' />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => handleDeleteFolder(index)} href="javascript:void(0)">Delete</Dropdown.Item>
                                                {(item.type === 3 || item.type === 2 || item.type === 1) && !isGlobalLibrary ? <Dropdown.Item onClick={() => (setActiveFile(item), setSharePopup(true))} href="javascript:void(0)">Share with client</Dropdown.Item> : ''}  
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    ) : ''}
                                    
                                </div>
                                <b>{item.name}</b>
                            </div>
                        ))}
                    </div>

                    </Fragment>}
                


        
                


                
            </div>



            <Modal show={sharePopup} onHide={() => setSharePopup(false)} className="dealroom_intital_modal">
                <Modal.Header closeButton>
                <h3>Share with client</h3>
                </Modal.Header>
                <Modal.Body className="pt-0">

                    <div className="text-start">
                        <hr className="mt-0" />
                        <b>Clients</b>
                    <ul style={{minHeight: '200px'}} className="filter_dropdown_ul">
                        {clientList.map((item) => (
                        <li key={item.id} className="filter_dropdown_li">
                            <input
                            type="checkbox"
                            checked={item.isChecked}
                            onChange={() => handleCheckboxChange(item)}
                            />
                            <label>{item.clientName}</label>
                        </li>
                        ))}
                    </ul>

                    <hr />

                    <div className="d-flex mt-4 justify-content-between">
                            <span className="btn btn-dark px-5" onClick={() => (setSharePopup(false), setActiveFile({}))}> Cancel</span>
                            <span onClick={shareFileClick} className="btn btn-primary px-5">Share File</span>
                        </div>
                    </div>
                   
                </Modal.Body>
            </Modal>



            <Modal show={showFileModal} onHide={() => setShowFileModal(false)} className="dealroom_intital_modal">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <img src={fileOutlineIcon} alt="fileOutlineIcon" />
                    <h3>Files</h3>
                    <p className="font_16">Store all project specific files and folders here. You can choose to share individual files/folders with your clients.</p>
                    <div className="d-flex my-4 justify-content-around">
                        <span onClick={() => handleDropDwonClick('folder')} className="btn btn-primary btn-lg px-5"><img src={plus} alt='icon' /> Folder</span>
                        <span onClick={() => handleDropDwonClick('file')} className="btn btn-primary btn-lg px-5"><img src={plus} alt='icon' /> File</span>
                    </div>
                    <h6 className="font_14"><input type="checkbox" onClick={(e) => filePopUpHandler(e)} /> Don’t show this again</h6>
                </Modal.Body>
            </Modal>

            <Modal
                className="my_modal"
                backdrop='static'
                show={createFolderModal}
                onHide={() => (setCreateFolderModal(false), setFolderNameErr(''))}>
                <Modal.Header closeButton>
                    <h3 className="text-capitalize">Create {folderType}</h3>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleCreateFolder}>
                        <div className="form-group">
                            <label className="text-capitalize">{folderType} Name</label>
                            <input
                                type="text"
                                value={folderName}
                                onChange={e => (setFolderName(e.target.value), setFolderNameErr(''))}
                                className="form-control" />
                            {folderNameErr ? <span className="cm_err">{folderNameErr}</span> : ''}
                        </div>
                        <div className="d-flex mt-4 justify-content-between">
                            <span className="btn btn-dark px-5" onClick={() => (setCreateFolderModal(false), setFolderNameErr(''))}> Cancel</span>
                            <button type="submit" className="btn btn-primary px-5">Create</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default FilesContent;
