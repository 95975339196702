import Spinner from 'react-bootstrap/Spinner';

const Loader = ({ type='' }) => {
    return (
        <Spinner as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            variant={type === '' ? 'light' : 'secondary'} 
        />
    )
}

export default Loader