// SignatureModal.js
import React, { useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import CommonButton from "./common/CommonButton";
// import './SignatureModal.css'; // Add necessary styles

const DynamicInputFieldsModal = ({ isOpen, onClose, onSave, type, fieldName, _fieldType, _valueType }) => {
  const [fieldValue, setFieldValue] = useState('')

//   useEffect(() => {
//     window.alert(fieldName,'fieldName fieldName')
//   },[])

  const handleSave = () => {
    onSave(fieldName,fieldValue, _fieldType, _valueType)
    onClose()
  };

  if (!isOpen) return null;

  return (
    <div className="signature-modal">
      <div className="modal-content">
        <h3 style={{fontSize:'16px'}}>Please add {fieldName}</h3>
        <div className="dynamic-input-fields-wrapper">
          <input className='dynamic-input-fields' type="text" value={fieldValue} onChange={(e) => setFieldValue(e.target.value)}/>
        </div>

        <div className="signature-modal-button">
            <CommonButton
            label="Close"
            fontSize="14px"
            fontWeight="700"
            className={`dash_common_btn`}
            icon=''
            onClick={onClose}/>
            <CommonButton
            label="Save"
            fontSize="14px"
            fontWeight="700"
            icon=''
            className={`dash_common_btn`}
            onClick={handleSave} />
        </div>
      </div>
    </div>
  );
};

export default DynamicInputFieldsModal;
