import React from 'react'

export default function TermsAndCondition() {

    const containerStyle = {
        maxWidth: "750px",
        margin: '0 auto',
        padding: '20px',
        fontFamily: 'Arial, sans-serif'
    };
    const marginBottom = {
        marginBottom: "0px"
    }
    const marginTop = {
        marginTop: "0px",
        marginBottom: "0px"

    }
    const marginWrap = {
        marginTop: "0px"
    }
    const paraAlign = {
        display: "inline",
        fontWeight: "400"
    }
    return (
        <div style={containerStyle}>

            <h2>Terms and conditions</h2>
            <div>
                <h4 style={marginBottom}>Xora Business Solutions Private Limited Terms of Service</h4>
                <h4 style={marginWrap}>Last updated: November 4, 2024</h4>
                <p>Welcome to Xora!<br></br>Xora Business Solutions Private Limited ("Xora." "we," "us," "our") provides its services (described below) to you through its website located at www.getxora.com (the "Site") and through related software applications and services (collectively, the "Service(s)"). By accessing or using the Services, you agree to comply with these Terms of Service ("Terms"). Xora reserves the right to modify these Terms at any time, and we will ratify users by posting the updated Terms on our website. Continued use of the Services after changes are posted constitutes acceptance of the new Terms.</p>
            </div>
            <div>
                <h3>1. Services Description</h3>
                <p>Xora is a business management platform tailored for Indian businesses in service industries.
                    The platform provides tools for managing clients, proposals, contracts, invoicing, and payments through integrated services with third parties, such as Cashfree for payment processing. Xora enables Businesses to connect with clients to streamline project and financial workflows.</p>
                <h3>2. Registration and Account Security</h3>
                <h4>Registration Obligations</h4>
                <p>To access Xora's Services, you must register and provide accurate, current information.
                    Registration details are governed by our Privacy Policy. Users under 18 years old may use the Services only with the consent of a parent or guardian</p>
                <h4>Account Security</h4>
                <p>You are responsible for maintaining the security of your account credentials. Notify us immediately of any unauthorized account use. Xora disclaims liability for any losses arising from unauthorized account access.</p>
                <h3>3. Access and Use of the Services</h3>
                <h4>User Responsibilities</h4>
                <p>By accessing or using Xora, you agree:</p>

                <ul>
                    <li><p>To use the Services solely for lawful purposes.</p></li>
                    <li><p>To respect other users and avoid uploading or sharing any harmful, obscene, or defamatory content.</p></li>
                    <li><p>Not to engage in activities that would harm, disrupt, or interfere with the Services or servers.</p></li>
                    <li><p>Not to impersonate other users or misrepresent your identity.</p></li>
                    <li><p>Not to harvest or collect information about other users without their consent.</p></li>
                    <li><p>Not to violate any local, state, national, or international law.</p></li>
                </ul>
                <p>Violations may result in suspension or termination of your account.</p>
                <h4>Modifications to the Service</h4>
                <p>Xora reserves the right to modify, suspend, or discontinue any part of the Service at any time without prior notice. Xora is not liable to users or third parties for any modifications, suspensions, or discontinuations.</p>
                <h4>Mobile Services</h4>
                <p>Xora includes mobile-accessible Services, subject to your mobile carrier's charges. Xora disclaims liability for fees or restrictions imposed by mobile carriers.</p>
                <h3>4. Payments and Fees</h3>
                <h4>Payment Processing</h4>
                <p>Xora partners with Cashfree to process payments. By making or receiving payments through Xora, you agree to comply with Cashfree's Terms of Service and Xora's payment terms. Xora is not liable for Cashfree-related issues.</p>
                <h4>Recurring Billing</h4>
                <p>Subscriptions renew automatically unless canceled prior to the billing date. You authorize Xora to charge the applicable renewal fees to your payment method.</p>
                <h4>Fees and Subscriptions</h4>
                <p>Xora offers subscription-based Services. Fees are charged according to your chosen plan. Subscriptions renew automatically, and you authorize Xora to bill your selected payment method for the applicable fees. Xora reserves the right to modify fees, with notice of changes provided 30 days before they take effect.</p>
                <h4>Chargebacks and Refunds</h4>
                <p>All fees are non-refundable unless otherwise specified. Chargeback disputes must be reported within 30 days. Xora reserves the right to suspend accounts with chargeback issues.</p>
                <h3>5. Access and Use of Optional Third-Party Tools</h3>
                <p>Xora integrates with third-party tools like Casefree for payment processing and social media platforms for Client communication. Access to these tools is provided "as is" without warranties, and Xora disclaims liability for the functionality or performance of these third-party services. Your interactions with these tools are governed by the respective third-party terms.</p>
                <h3>6. Intellectual Property and Proprietary Rights</h3>
                <h4>Ownership of Content</h4>
                <p>All content, software, and designs provided by Xora, including the Xora logo, trademarks, and copyrighted materials, are the property of Xora, Users may not copy, modify, distribute, or reverse-engineer Xora's proprietary software or content without written permission.</p>
                <h4>User Content</h4>
                <p>Users may upload content to Xora, such as business documents and project files. By uploading content, you grant Xora a non-exclusive, worldwide license to use, store, and display your content to facilitate the Service. You retain ownership of any intellectual property rights in your content.</p>
                <h4>Suggestions and Feedback</h4>
                <p>Any feedback, comments, or suggestions you provide to Xora may be used by us without obligation or compensation to you, and you agree that we may use your feedback to improve our Services.</p>
                <h3>7. Publicity</h3>
                <p>Xora reserves the right to use your business name, logo, and general usage of the Services in our marketing materials, case studies, and testimonials, subject to your prior consent.</p>
                <h3>8. Data Privacy and Security</h3>
                <p>Xora respects the privacy of user data, as outlined in our Privacy Policy. We implement reasonable security measures to protect your data. However, Xora disclaims liability for any unauthorized access to your data or any resulting loss or damage. You agree to notify Xora of any suspected security breach immediately.</p>
                <h3>9. Restrictions on Use</h3>
                <p>You agree not to engage in activities including, but not limited to:</p>
                <ul>
                    <li><p>Uploading unlawful, obscene, or abusive content.</p></li>
                    <li><p>Using the Services to harass or harm others.</p></li>
                    <li><p>Circumventing or attempting to bypass Xora's security measures.</p></li>
                    <li><p>Impersonating other users or entities.</p></li>
                    <li><p>Collecting information from users without consent.</p></li>
                    <li><p>Advertising or promoting non-Xora goods or services.</p></li>
                </ul>
                <p>Xora reserves the right to terminate accounts violating these guidelines.</p>
                <h3>10. Social Networking Services</h3>
                <p>Xora offers integrations with social networking platforms such as WhatsApp or Meta services.
                    By logging into Xora through a social media account, you authorize Xora to access your social media information. All interactions with social networking services are governed by their respective privacy policies, and Xora disclaims liability for data used by these third-party services.</p>
                <h3>11. Copyright Claims</h3>
                <p>If you believe that your work has been copied in a way that constitutes copyright infringement, please notify Xora with the following details:</p>
                <ul>
                    <li><p>A description of the copyrighted work you believe has been infringed.</p></li>
                    <li><p>The URL or location of the allegedly infringing material on our Services.</p></li>
                    <li><p>Your contact information, including email and physical address.</p></li>
                    <li><p>A statement under penalty of perjury that you believe in good faith that the use is unauthorized.</p></li>

                </ul>
                <p>Xora will investigate copyright claims and may remove or disable content infringing on intellectual property rights.</p>
                <h3>12. Third-Party Integrations and Links</h3>
                <h4>Cashfree Payment Integration</h4>
                <p>Xora integrates with Cashtree for payment processing. By using Cashitee services through dora, you agree to comply with Cashfree's terms. Xora is not responsible for third-party service functionality or disputes.</p>
                <h4>External Links</h4>
                <p>Xora may provide links to external websites or services. Xora disclaims liability for external sites, and users are responsible for reviewing the terms and policies of any third-party sites accessed via Xora</p>
                <h3>13. Termination of Service</h3>
                <p>Xora reserves the right to terminate or suspend your account at its discretion if you violate these Terms. You may cancel your subscription by contacting <a href="mailto:support@getxora.com">support@getxora.com</a> at least 30 days in advance.</p>
                <h3>14. Indemnification</h3>
                <p>You agree to indemnify and hold Xora harmless from any claims, damages, liabilities, costs, or expenses (including attorney fees) arising from:</p>
                <ul>
                    <li><p>Your use of the Service.</p></li>
                    <li><p>Content you upload or share via the Service.</p></li>
                    <li><p>Violation of these Terms by you or any third party using your account.</p></li>
                    <li><p>Any infringement of intellectual property or other rights.</p></li>
                </ul>
                <h3>15. Disclaimers and Limitation of Liability</h3>
                <h4>Disclaimer of Warranties</h4>
                <p>Xora provides the Service "as is" without warranties of any kind. Xora disclaims liability for interruptions, delays, or errors, and does not guarantee that the Service will be uninterrupted or error-free.</p>
                <h4>Limitation of Liability</h4>
                <p>To the extent permitted under Indian law, Xora is not liable for indirect, incidental, or consequential damages resulting from use of the Service. Xora's total liability to you will not exceed the amount paid by you to Xora in the six months preceding the event.</p>
                <h3>16. Dispute Resolution and Governing Law</h3>
                <h4>Governing Law</h4>
                <p>These Terms are governed by Indian law, and any legal disputes will be subject to the exclusive jurisdiction of courts in Mumbai, Maharashtra.</p>
                <h4>Arbitration</h4>
                <p>All disputes between Xora and users will be resolved through binding arbitration in Mumbai, conducted in English. Arbitration is a private process, and both parties waive their rights to a jury trial and class action litigation.</p>
                <h3>17. Confidentiality and Non-Disclosure</h3>
                <p>Any non-public information shared between users and Xora shall be treated as confidential. You agree not to disclose confidential information shared through Xora without prior written consent.</p>
                <h3>18. Modifications to the Terms</h3>
                <p>Xora reserves the right to modify these Terms at any time. Updates will be posted on this page, and your continued use of the Services after updates constitutes acceptance of the new Terms.</p>
                <h3>19.  Notices and Contact Information</h3>
                <p>Xora may send notices to you via email or other methods provided during registration. For questions, concerns, or support, contact us at:</p>
                <h4 style={marginBottom}>Xora Business Solutions Private Limited</h4>
                <h4 style={marginTop}><b>Email:</b> <a href="mailto:support@getxora.com">support@getxora.com</a></h4>
                <h4 style={marginTop}><b><sapn>Address:</sapn></b> <p style={paraAlign}>D-1203 Wadhwa Atmosphere, Mulund Goregaon Link Rd, Mulund W, Mumbai 400080.</p></h4>
            </div>
        </div>
    )
}
