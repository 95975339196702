import { useEffect, useState } from "react";
import { getInvoiceList } from "../../services";
import { formatAmount } from "../../utils/helper";
import { _contractStatus } from "../../utils/constant";
import threeDot from '../../assets/logos/three-dot.svg';
import { invoiceFilterStatus } from "../../utils/constant";
import ActionModalComponent from "./ActionModalComponent";

const ClientInvoicesComponent = ({ selectedProject }) => {
  const userDetail = JSON.parse(localStorage.getItem("authUserData")) || null;
  const token = localStorage.getItem("token");
  const [list, setList] = useState([]);
  const [pageLoader, setPageLoader] = useState(true);
  const [activeInvoice, setActiveInvoice] = useState({});

  useEffect(() => {
    const fetchDealrooms = async () => {
      if (!userDetail?.businessDetail?.businessId || !selectedProject?.id) return;
      const params = `?businessId=${userDetail.businessDetail.businessId}&projectId=${selectedProject.id}`;
      try {
        const { data } = await getInvoiceList(params, token);
        if (data?.status) {
          setList(data.responseData?.data || []);
        }
        setPageLoader(false)
      } catch (error) {
        console.error('Error fetching signers:', error);
        setPageLoader(false)
      }
    };
    
    fetchDealrooms();
  }, [selectedProject]);


  const getStatusText = (statusId) => {    
    const status = invoiceFilterStatus.find(status => status.id === statusId);    
    return status ? { text: (status?.value)?.toLowerCase(), color: status?.color } : { text: "-", color: "#000000" };
  };
  const capitalizeFirstLetter = (text) => {
    if (!text) return '';
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  function formatDate(dateStr) {
    const options = { year: 'numeric', month: 'long', day: '2-digit' };
    return new Date(dateStr).toLocaleDateString('en-US', options);
  }

  if(pageLoader){
    return <div>Loading...</div>
  }else{
    return (
        <div>
          <h3>Invoice Details</h3>
          <p>Reminder for invoices will be sent every 2 days until paid</p>
          <div className="table-responsive">
            <table className="project_table dealroom_table">
              <thead>
                <tr>
                  <th className="text-start ps-5">S.No.</th>
                  <th className="text-start">Date</th>
                  <th className="text-start">Title</th>
                  <th className="text-start">Amount</th>
                  <th className="text-start">Status</th>
                  <th className="text-start">Sent By</th>
                  <th className="text-start">Record Amount</th>
                  <th className="text-start">Due Date</th>
                  <th className="text-start">Actions</th>
                </tr>
              </thead>
              <tbody>
                {list?.length === 0 ? (
                  <tr>
                    <td colSpan="8" className="no-data-message">
                      No invoices available.
                    </td>
                  </tr>
                ) 
                 : (
                  list.map((invoice, index) => {
                    const { text, color } = getStatusText(invoice?.status);
                    
                    // Calculate the total offline records amount for the current invoice
                    const offlineRecords = invoice.offlineRecords || [];
                    const totalOfflineAmount = offlineRecords.reduce((sum, record) => sum + record.receiveAmount, 0);
                    
                    return (
                      <tr key={invoice?.id}>
                        <td className="text-start ps-5">{index + 1}</td>
                        <td className="text-start">{formatDate(invoice.created_at)}</td>
                        <td className="text-start">{invoice.title}</td>
                        <td className="text-start">₹ {formatAmount(invoice?.invoiceAmount?.totalAmount)}</td>
                        <td className="text-start" style={{ color: color }}>{capitalizeFirstLetter(text)}</td>
                        <td className="text-start">{invoice.createBy}</td>
                        <td className="text-start">{formatAmount(totalOfflineAmount)}</td>
                        <td className="text-start">{formatDate(invoice.dueDate)}</td>
                        <td className="text-start" style={{ cursor: 'pointer' }}>
                          <span onClick={() => setActiveInvoice(invoice)}>
                            <img src={threeDot} alt="three dot" />
                          </span>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
          </table>
          </div>


          {activeInvoice.id ? <ActionModalComponent
                                     _data={activeInvoice}
                                    title={activeInvoice.title}
                                    onClose={() => setActiveInvoice({})}
                                    url={activeInvoice.pdfRecord.pdfFile}
                                    viewType="INVOICE" /> : ''}
        </div>
      );
  }


};

export default ClientInvoicesComponent;
