import { useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useToast = () => {
    
  const showToast = (message, type, autoClose = 5000) => {
    const options = {
      autoClose
    };

    switch (type) {
      case 'info':
        toast.info(message,options);
        break;
      case 'success':
        toast.success(message,options);
        break;
      case 'warning':
        toast.warn(message,options);
        break;
      case 'error':
        toast.error(message,options);
        break;
      default:
        toast(message,options);
    }
  };

  return showToast;
};

export default useToast;