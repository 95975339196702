
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: {},
};

const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    addStatus: (state, action) => {
      state.status = action.payload;
    },
  },
});

export const { addStatus } = statusSlice.actions;

export default statusSlice.reducer;