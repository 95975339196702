import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import CommonButton from "../../../components/common/CommonButton";
import { postHtmlTemplate } from "./../../../services/index"; // Import the API function
import Contract from "./Contract";
import DealroomSigners from "./DealroomSigners";
// import ReactQuill from "react-quill";
import TextEditor from "../../templates/TextEditor";


const colors = {
  0: '#FFD700', // Gold
  1: '#ADFF2F', // GreenYellow
  2: '#FFB6C1', // LightPink
  3: '#87CEFA', // LightSkyBlue
  4: '#FFFACD', // LemonChiffon
};

// const modules = {
//   toolbar: [
//     [{ header: "1" }, { header: "2" }, { font: [] }],
//     [{ size: [] }],
//     ["bold", "italic", "underline", "strike", "blockquote"],
//     [
//       { list: "ordered" },
//       { list: "bullet" },
//       { indent: "-1" },
//       { indent: "+1" },
//     ],
//     ["link", "image", "video"],
//     ["clean"],
//     [{ align: [] }], // Alignment options
//   ],
// };

const TemplatePreview = ({ previewUrl, cancelHandler, type , _refreshSignersList, crData={} }) => {

  const [valueTemplate, setValueTemplate] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [templateDetails, setTemplateDetails] = useState(null)
  const [isHtmlDocRenders, setIsHtmlDocRenders] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if(type === 1){
      setValueTemplate(previewUrl);
    }
  }, [previewUrl]);


  useEffect(() => {
    let url = localStorage.getItem('pdfUrl')
      if(templateDetails !== 'null' && templateDetails !== null && type === 2){
        setPdfUrl(url)
      }else{
        setPdfUrl('')
      }
  },[templateDetails])

  useEffect(() => {
    if(pdfUrl !== ''){
      setIsHtmlDocRenders(false)
    }
  },[pdfUrl])

  const addSignerContinuewHandler = (data) => {
    setTemplateDetails(data)
  }

  const getRandomColor = () => {
    const letters = 'BCDEF'; // Exclude 'A' to avoid dark colors
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    // Ensure the color is not #90EE90
    return color === '#90EE90' ? getRandomColor() : color;
  };

  const handleCloseContract = () => {
    setTemplateDetails(null)
  }

  const handleHTMLDOCRenderer = () => {
    if(isHtmlDocRenders === false){
      setIsHtmlDocRenders(true)
    }
  }

  const handleAddMagicField = async () => {
    setIsLoading(true)
    const token = localStorage.getItem("token");
    try {
      const response = await postHtmlTemplate({ text: valueTemplate }, token);
      if(response.data.status === 1){
        localStorage.setItem('pdfUrl', response.data?.responseData?.url);
        setPdfUrl(response.data?.responseData?.url);
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
    }
  };


  return !isHtmlDocRenders ? (pdfUrl ? (
    <Contract
      pdfType={type}
      pdfUrl={pdfUrl}
      templateDetails={templateDetails}
      closeContract={handleCloseContract}
      backToDealRoomListing={cancelHandler}
      _refreshSignersList_={_refreshSignersList}
      crData={crData}/>
  ) : 
    <DealroomSigners
      previewUrl={previewUrl}
      templateDetailHandler={addSignerContinuewHandler}
      _cancelHandler={cancelHandler}
      type={type}
      renerHanler={handleHTMLDOCRenderer}/>)
    : <>
       {/* <ReactQuill
          value={valueTemplate}
          onChange={setValueTemplate}
          style={{ height: 600 }}
          modules={modules} /> */}

          <TextEditor val={valueTemplate}
          change={setValueTemplate} />

        <div className="contract_main_button">
          <CommonButton
            label="Cancel"
            fontSize="14px"
            fontWeight="700"
            className="dash_common_btn white_bg_btn"
            icon={false}
            onClick={() => setIsHtmlDocRenders(false)} />
          <CommonButton
            label="View Preview"
            fontSize="14px"
            fontWeight="700"
            className="dash_common_btn"
            icon={false}
            isLoad={isLoading}
            onClick={handleAddMagicField} />
        </div>
        </>
};



export default TemplatePreview;


