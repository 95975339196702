import React from 'react'

export default function CashFree() {

    const containerStyle = {
        maxWidth: "750px",
        margin: '0 auto',
        padding: '20px',
        fontFamily: 'Arial, sans-serif'
    };

    const tableStyle = {
        width: '57%',
        borderCollapse: 'collapse',
        fontFamily: 'Arial, sans-serif'
    };

    const cellStyle = {
        border: '1px solid #000',
        padding: '10px',
        textAlign: 'left'
    };

    const headerCellStyle = {
        ...cellStyle,
        backgroundColor: '#f2f2f2',
        fontWeight: 'bold'
    };

    const linkText = {
        color: "#0000EE"
    }

    return (
        <div style={containerStyle}>
            <h2>Connect with Cashfree to Collect Payments Online</h2>
            <p>Xora makes it easy for business owners to collect payments from clients through our seamless integration with Cashfree, one of India's leading payment gateways. Whether you need to accept credit cards, UPI, or bank transfers, connecting Cashfree with Xora will streamline your invoicing and payment collection process.</p>
            <div>
                <h4>Why Connect Cashfree with Xora?</h4>
                <ul>
                    <li><p><span><b>Convenient Payment Methods:&nbsp;</b></span>Offer your clients multiple payment options, including credit cards, debit cards, UPI, net banking, and wallets.</p></li>
                    <li><p><span><b>Secure Transactions:&nbsp;</b></span>Cashfree provides secure and compliant payment solutions, ensuring your transactions are safe and protected.</p></li>
                    <li><p><span><b>Faster Payment Processing:&nbsp;</b></span>Get your payments processed quickly, allowing you to focus more on your business and less on manual follow-ups for payments.</p></li>
                    <li><p><span><b>Automated Invoice Tracking:&nbsp;</b></span>Once a payment is made, Xora automatically updates the invoice status, keeping your financial records organized.</p></li>
                </ul>
            </div>

            <div>
                <h4>How to Connect Cashfree with Xora</h4>
                <ol>
                    <li><p><span><b>Log in to Xora:&nbsp;</b></span>Go to your Xora dashboard and navigate to the 'Settings' section.</p></li>
                    <li><p><span><b>Access Payment Integrations:&nbsp;</b></span>Click on the 'Payments' option and click "Get Started".</p></li>
                    <li><p><span><b>Create or Log in to Your Cashfree Account:&nbsp;</b></span>Clicking on "Get Started" will open a new window where you can either log-in with your existing Cashfree account or create a new | Cashfree account. You will be directed through all the steps to complete your account setup via Cashfree</p></li>
                    <li><p><span><b>Submit KYC documents:&nbsp;</b></span>Once you complete your Cashfree account setup, you can provide all your KYC documents directly on the Cashfree <a href='https://www.cashfree.com/' style={linkText}>merchant portal.</a></p></li>
                    <li><p><span><b>Check your Cashfree account status:&nbsp;</b></span>Return to Xora's 'Payments' section periodically to check whether your Cashfree account has been fully verified or if you need to provide additional details. You can also check the status of your Cashfree onboarding directly on the Cashfree <a href='https://www.cashfree.com/' style={linkText}>merchant portal.</a></p></li>
                    <li><p><span><b>Complete Setup:&nbsp;</b></span>Once your Cashfree account is connected, you will see the status as
                        "Connected" when you navigate to 'Settings' and go to the 'Payments' section.</p></li>
                </ol>
            </div>

            <div>
                <h2>Frequently Asked Questions</h2>
                <div className='wrapperQA'>
                    <h4 className='question'>Q: Will I receive a notification when a client makes a payment?</h4>
                    <p className='answer'>A: Yes, Xora will send you a notification when a payment is successfully processed, and the invoice will be automatically marked as paid.</p>
                </div>
                <div className='wrapperQA'>
                    <h4 className='question'>Q: What currencies are supported with Cashfree?</h4>
                    <p className='answer'>A: Cashfree supports payments in Indian Rupees (INR) as well as over 100 international currencies. Payment fees vary depending on currencies.</p>
                </div>
                <div className='wrapperQA'>
                    <h4 className='question'>Q: What are the fees associated with using Cashfree?</h4>
                    <p className='answer'>A: Invoices paid using Cashfree are charged the following fees. These fees are subject to change.</p>
                    <table style={tableStyle}>
                        <thead>
                            <tr>
                                <th style={headerCellStyle}>Transaction type</th>
                                <th style={headerCellStyle}>Fees</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={cellStyle}>UPI</td>
                                <td style={cellStyle}>0.3% + 18% GST charged on the 0.3% transaction fee</td>
                            </tr>
                            <tr>
                                <td style={cellStyle}>All domestic cards, wallets, and netbanking</td>
                                <td style={cellStyle}>2% + 18% GST charged on the 2% transaction fee</td>
                            </tr>
                            <tr>
                                <td style={cellStyle}>All international, corporate, AMEX, diners cards</td>
                                <td style={cellStyle}>3% + 18% GST charged on the 3% transaction fee</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='wrapperQA'>
                    <h4 className='question'>Q: Can I still send invoices if I don't want to use Cashfree payment gateway?</h4>
                    <p className='answer'>A: You can generate and send invoices to the client without integrating with Cashfree. However, those invoices will be sent without a payment link. Payments will have to be collected by the business outside of Xora. Businesses can use the "Record offline payment" feature to record payments for invoices received outside of Xora.</p>
                    <p className='answer'>In order to streamline the billing and automate invoice reconciliation, we highly recommend businesses use the Cashfree payment gateway.</p>
                </div>
                <div className='wrapperQA'>
                    <h4 className='question'>Q: How do refunds work with Cashfree?</h4>
                    <p className='answer'>A: You can initiate refunds directly from your Cashfree dashboard. Once processed, Xora will update the invoice status accordingly.</p>
                </div>
                <div>
                    <p>For more detailed information on Cashfree, visit <a href='https://www.cashfree.com/' style={linkText}>Cashfree's website.</a></p>
                </div>
            </div>
        </div>
    )
}
