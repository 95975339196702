import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import docIcon from "../../../assets/logos/doc-svgrepo-com.svg";
import pdfIcon from "../../../assets/logos/pdf-svgrepo-com.svg";
import rupeesIcon from "../../../assets/logos/Rupee-Symbol-Black.svg";
import CommonButton from "../../../components/common/CommonButton";
import DiscardModal from "../../../modals/DiscardModal";
import { updateExpense } from "../../../services";
import { _contractStatus } from "../../../utils/constant";
import useToast from "../../../utils/customHooks/useToast";

const dateRender = (date) => {
  try {
    return format(parseISO(date?.replace("Z", "")), "MMMM dd, yyyy");
  } catch (error) {
    return date;
  }
};

const ReviewExpense = ({ handleCancel, expenseReviewData }) => {
  const [expenseReview, setExpenseReview] = useState("");
  const [cancelExpense, setCancelExpense] = useState(false);
  const [rejectExpense, setRejectExpense] = useState(false);
  const [approveExpense, setApproveExpense] = useState(false);
  const showToast = useToast();

  const handleOpenCancelModal = () => setCancelExpense(true);
  const handleCloseCancelModal = () => {
    setCancelExpense(false);
    handleCancel();
  };
  const handleDiscardReview = async () => {
    setCancelExpense(false);
    await handleSubmitExpense(1);
    handleCancel();
  };

  const renderLinks = (url, index) => {
    const getFileExtension = (url) => {
      const lastDotIndex = url.lastIndexOf(".");
      if (lastDotIndex === -1) return null;
      return url.substring(lastDotIndex + 1).split(/[?#]/)[0];
    };
    const fileExtension = getFileExtension(url);

    let previewContent;

    if (
      fileExtension === "jpg" ||
      fileExtension === "jpeg" ||
      fileExtension === "png" ||
      fileExtension === "svg"
    ) {
      previewContent = <img className="thumb" src={url} alt="Preview" />;
    } else if (fileExtension === "pdf") {
      previewContent = <img className="thumb" src={pdfIcon} alt="PDF Icon" />;
    } else if (fileExtension === "doc" || fileExtension === "docx") {
      previewContent = <img className="thumb" src={docIcon} alt="DOC Icon" />;
    } else {
      previewContent = <div className="thumb">File</div>;
    }

    return (
      <li key={index}>
        <div className="img-wrap">{previewContent}</div>
      </li>
    );
  };

  const handleSubmitExpense = async (key) => {
    const formData = new FormData();
    const token = localStorage.getItem("token");

    if (key === 3) setRejectExpense(true);
    if (key === 4) setApproveExpense(true);
    if (key !== 1 && !expenseReview) {
      showToast("Expense Review is required!", "error");
      if (key === 3) setRejectExpense(false);
      if (key === 4) setApproveExpense(false);
      return;
    }
    if (key !== 1) formData.append("reviewDescription", expenseReview);
    formData.append("reviewStatus", key);
    const reviewExpense = await updateExpense(
      expenseReviewData?.id,
      formData,
      token
    );
    if (reviewExpense.data?.status === 1) {
      if (key === 1) {
        showToast("Expense Drafted Successfully!", "success");
      }
      if (key === 3) {
        showToast("Expense Rejected Successfully!", "success");
        setRejectExpense(false);
      }
      if (key === 4) {
        showToast("Expense Approved Successfully!", "success");
        setApproveExpense(false);
      }
    } else {
      if (key === 1) {
        showToast("Expense Draft Failed!", "error");
      }
      if (key === 3) {
        showToast("Expense Rejected Failed!", "error");
        setRejectExpense(false);
      }
      if (key === 4) {
        showToast("Expense Approved Failed!", "error");
        setApproveExpense(false);
      }
    }
    handleCancel();
  };

  const handleChange = (e, key) => {
    const { value } = e.target;
    switch (key) {
      case "ExpenseReview":
        setExpenseReview(value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (
      expenseReviewData &&
      expenseReviewData.reviewDescription &&
      expenseReviewData.reviewDescription !== undefined
    ) {
      setExpenseReview(expenseReviewData.reviewDescription);
    }
  }, [expenseReviewData]);

  return (
    <>
      <div className="contract_main">
        <div className="contract_left" style={{ width: "100%" }}>
          <div
            className="contract_left_template"
            style={{ width: "100%", maxHeight: "75vh", overflowY: "auto" }}
          >
            <h2 className="dashboard_heading">
              Review Expense
              <span
                style={{
                  backgroundColor:
                    _contractStatus[expenseReviewData?.reviewStatus]?.color,
                  marginLeft: "10px",
                  padding: "6px",
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: "#ffffff",
                  fontWeight: 400,
                  borderRadius: "4px",
                  maxWidth: "120px",
                }}
              >
                {_contractStatus[expenseReviewData?.reviewStatus]?.value}
              </span>
            </h2>
            <ul className="modal_main_ul">
              <li className="modal_main_li" style={{ width: "43%" }}>
                <label className="font_14">Project</label>
                <div className="_editable_">
                  {expenseReviewData && expenseReviewData.projectName ? (
                    <input
                      type="text"
                      placeholder="Project"
                      value={expenseReviewData.projectName}
                      disabled
                    />
                  ) : null}
                </div>
              </li>
              <li className="modal_main_li" style={{ width: "43%" }}>
                <label className="font_14">Expense Date</label>
                {expenseReviewData && expenseReviewData.expenseDate ? (
                  <DatePicker
                    selected={dateRender(expenseReviewData?.expenseDate)}
                    placeholderText="MMMM DD, YYYY"
                    dateFormat="MMMM dd, yyyy"
                    disabled
                  />
                ) : null}
              </li>
            </ul>
            <ul className="modal_main_ul" style={{ marginTop: "0" }}>
              <li className="modal_main_li" style={{ width: "43%" }}>
                <label className="font_14">Expense Name</label>
                <div className="_editable_">
                  {expenseReviewData && expenseReviewData.title ? (
                    <input
                      type="text"
                      placeholder="Expense Name"
                      value={expenseReviewData.title}
                      disabled
                    />
                  ) : null}
                </div>
              </li>
              <li className="modal_main_li" style={{ width: "43%" }}>
                <label className="font_14">Amount</label>
                <div className="dealroom_detail_upper_left">
                  <div className="dashboard_upper_input">
                    {expenseReviewData && expenseReviewData.amount ? (
                      <input
                        type="text"
                        placeholder="Enter Amount"
                        style={{
                          fontSize: "14px",
                          outline: "none !important",
                          width: "100%",
                          padding: "15px 15px 15px 30px",
                          border: "1px solid #e7e8ea",
                          borderRadius: "8px",
                          color: "black",
                        }}
                        value={expenseReviewData.amount || "0"}
                        disabled
                      />
                    ) : null}
                    <img
                      src={rupeesIcon}
                      alt="rupeesIcon"
                      width={10}
                      height={10}
                      style={{ top: "21px" }}
                    />
                  </div>
                </div>
              </li>
            </ul>
            <ul className="modal_main_ul" style={{ marginTop: "0" }}>
              <li className="modal_main_li" style={{ width: "43%" }}>
                <label className="font_14">Category</label>
                {expenseReviewData && expenseReviewData.category ? (
                  <div className="_editable_">
                    <input
                      type="text"
                      placeholder="Category"
                      value={expenseReviewData.category}
                      disabled
                    />
                  </div>
                ) : null}
              </li>
              <li className="modal_main_li" style={{ width: "43%" }}>
                <label className="font_14">Subcategory Name</label>
                {expenseReviewData && expenseReviewData.subCategory ? (
                  <div className="_editable_">
                    <input
                      type="text"
                      placeholder="Sub Category"
                      value={expenseReviewData.subCategory}
                      disabled
                    />
                  </div>
                ) : null}
              </li>
            </ul>
            <ul className="modal_main_ul" style={{ marginTop: "0" }}>
              <li className="modal_main_li" style={{ width: "43%" }}>
                <label className="font_14">Receipt Image</label>
                <div className="_editable_">
                  <div
                    className="upload-logo-wrapper"
                    style={{ width: "100%", justifyContent: "flex-start" }}
                  >
                    <div className="uploaded-logo" style={{ width: "100%" }}>
                      {expenseReviewData &&
                      expenseReviewData.documents &&
                      expenseReviewData.documents.length === 0
                        ? ""
                        : expenseReviewData &&
                          expenseReviewData.documents &&
                          expenseReviewData.documents.length === 1
                        ? "1 file Uploaded"
                        : `${expenseReviewData.documents.length} files Uploaded`}
                    </div>
                  </div>
                </div>
                {expenseReviewData &&
                expenseReviewData.documents &&
                expenseReviewData.documents.length > 0 ? (
                  <ul
                    className="thumb-Images"
                    id="imgList"
                    style={{ marginTop: 10 }}
                  >
                    {expenseReviewData.documents.map((file, index) =>
                      renderLinks(file, index)
                    )}
                  </ul>
                ) : null}
              </li>
            </ul>
            <ul className="modal_main_ul" style={{ marginTop: 0 }}>
              <li className="modal_main_li" style={{ width: "100%" }}>
                <label className="font_14">Description (in 200 words)</label>
                <div className="_editable_">
                  {expenseReviewData && expenseReviewData.description ? (
                    <textarea
                      rows="4"
                      cols="50"
                      className="_text_area_"
                      placeholder="Write the details of the Expense"
                      value={expenseReviewData.description}
                      maxLength={200}
                      style={{ height: "135px" }}
                      disabled
                    />
                  ) : null}
                </div>
              </li>
            </ul>
            <ul className="modal_main_ul" style={{ marginTop: 0 }}>
              <li className="modal_main_li" style={{ width: "100%" }}>
                <label className="font_14">
                  Review Comments (in 500 words)
                </label>
                <div className="_editable_">
                  <textarea
                    rows="5"
                    cols="100"
                    className="_text_area_"
                    placeholder="Write the review of the Expense"
                    value={expenseReview}
                    maxLength={500}
                    style={{ height: "135px" }}
                    onChange={(e) => handleChange(e, "ExpenseReview")}
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="contract_main_button">
        <CommonButton
          label="Cancel"
          fontSize="14px"
          fontWeight="700"
          className="dash_common_btn white_bg_btn"
          icon={false}
          onClick={handleOpenCancelModal}
        />
        <CommonButton
          label="Reject"
          fontSize="15px"
          fontWeight="700"
          className="dash_common_btn ff4500_bg_btn"
          icon={false}
          isLoad={rejectExpense}
          onClick={() => handleSubmitExpense(3)} // reject review number 3
        />
        <CommonButton
          label="Approve"
          fontSize="15px"
          fontWeight="700"
          className="dash_common_btn"
          icon={false}
          isLoad={approveExpense}
          onClick={() => handleSubmitExpense(4)} // approve review number 4
        />
      </div>

      {cancelExpense && (
        <DiscardModal
          title={"Do you want to save the expense as draft?"}
          isOpen={cancelExpense}
          onClose={handleCloseCancelModal}
          onSure={handleDiscardReview}
        />
      )}
    </>
  );
};

export default ReviewExpense;
