import React, { useEffect, useState } from "react";
import "./Tabs.css";
import { useLocation } from "react-router-dom";
import {
  getNotesList,
  createNotes,
  UpdateNotes,
  DeleteNotes,
} from "../../../services";
import DeleteIcon from "./../../../assets/dashboard/delete.svg";
import DeleteModal from "../../../modals/DeleteModal";
import CommonButton from "../../../components/common/CommonButton";
import useToast from "../../../utils/customHooks/useToast";
import groupIcon from "./../../../assets/dashboard/group-icon.svg";
import OnlyMeIcon from "./../../../assets/dashboard/OnlyMe.svg"
import TeamIcon from "./../../../assets/dashboard/Team.svg"
import lockIcon from "./../../../assets/dashboard/lock.svg";
import { Modal } from "react-bootstrap";
import Loader from "../../../components/common/Loader";

const Notes = ({searchTerm, isAdding, setIsAdding, selectedProject, isGlobal }) => {
  let projectDetails = JSON.parse(localStorage.getItem("projectDetail2"));
  const authUserData = JSON.parse(localStorage.getItem("authUserData")) || null;
  const token = localStorage.getItem("token");
  const location = useLocation();
  const { id } = location.state || {};

  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState(null); // To store clicked item
  // const [searchTerm, setSearchTerm] = useState("");
  const [onlyMeFilter, setOnlyMeFilter] = useState(false);
  const [shareOption, setShareOption] = useState(1); // 1 = Only Me, 2 = Team Members, 3 = Both
  const [showDeletePopup, setShowDeletePopup] = useState(false); // To control delete confirmation popup
  const [notesLoader, setnotesLoader] = useState(false);
  const showToast = useToast();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    const projectId = projectDetails?.id || ""; 
    const businessId = authUserData?.businessDetail?.businessId;

    let query = `?businessId=${businessId}`;
    if (!isGlobal || (isGlobal && selectedProject.value)) {
      const effectiveProjectId = isGlobal ? selectedProject.value : projectId;
      query += `&projectId=${effectiveProjectId}`;
    }

    const notesData = await getNotesList(query, token);
    if (notesData?.data?.status === 1) {
      setIsLoading(false);
      setData(notesData?.data?.responseData?.data);
      setSelectedData(notesData?.data?.responseData?.data[0]); // Select first element by default
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [id, selectedProject]);

  useEffect(() => {
    if (isAdding) {
      if (projectDetails?.projectName) {
        setIsAdding(true);
        setSelectedData({
          noteTitle: "",
          notesDescription: "",
          projectName: projectDetails.projectName,
          sharing: 1,
          projectId: id,
        });
      } else {
        if (selectedProject.value) {
          setIsAdding(true);
          setSelectedData({
            noteTitle: "",
            notesDescription: "",
            projectName: selectedProject.label,
            sharing: 1,
            projectId: id,
          });
        } else {
          showToast("Select a project", "error");
        }
      }
    }
  }, [isAdding]);

  // Filter data based on search term and 'Only Me' toggle
  const filteredData = data?.filter((item) => {
    //const matchesSearch = item?.noteTitle?.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesSearch =
    item?.noteTitle?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item?.notesDescription?.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesFilter = onlyMeFilter ? item?.sharing : true;
    return matchesSearch && matchesFilter;
  });

  // const handleSearchChange = (e) => {
  //   setSearchTerm(e.target.value);
  // };

  const toggleOnlyMeFilter = () => {
    setOnlyMeFilter(!onlyMeFilter);
  };

  const handleShareChange = (newShareOption) => {
    setShareOption(newShareOption);

    if (selectedData) {
      setSelectedData((prev) => ({ ...prev, sharing: newShareOption }));
    }

    setShowModal(false);
  };

  const handleTitleChange = (e) => {
    setSelectedData({ ...selectedData, noteTitle: e.target.value });
  };

  const handleDescriptionChange = (e) => {
    setSelectedData({ ...selectedData, notesDescription: e.target.value });
  };

  // Save or Update Note
  const handleSave = async () => {

    if (selectedData?.noteTitle === ""){
      showToast("Notes title is required.", "error")
      return
    }
    // else if (selectedData?.notesDescription === ""){
    //   showToast("Notes description is required.","error")
    //   return
    // }

    setnotesLoader(true);
    const params = {
      projectName: projectDetails?.projectName,
      sharing: shareOption ? shareOption : 1,
      notesDescription: selectedData?.notesDescription,
      noteTitle: selectedData?.noteTitle,
      projectId: projectDetails.id,
      userId: authUserData?.userId
    };

    let notesValue;

    if (isAdding) {
      // Create a new note
      const notesCreatedValue = await createNotes(
        params,
        projectDetails?.businessId,
        token
      );
      notesValue = notesCreatedValue?.data?.responseData; // Get the newly created note
      showToast("Notes created successfully.", "success");
    } else {
      // Update existing note
      const notesUpdatedValue = await UpdateNotes(
        params,
        selectedData?.id,
        token
      );
      notesValue = notesUpdatedValue?.data?.responseData; // Get the updated note
      showToast("Notes updated successfully.", "success");
    }

    // Refetch data after creation or update
    fetchData();

    setnotesLoader(false);
    setIsAdding(false);
  };

  // Cancel operation
  const handleCancel = () => {
    setIsAdding(false);
  };

  const handleDelete = async () => {
    if (selectedData && selectedData.id) {
      await DeleteNotes(selectedData.id, token);
      setShowDeletePopup(false);

      fetchData();
    }
  };

  const getShareLabel = (sharing) => {    
    switch (sharing) {
      case 1:
        return "Me Only";
      case 2:
        return "Team";
      case 3:
        return "Team + Client";
      default:
        return "Me Only";
    }
  };

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  // const toggleDropdown = () => {
  //   setIsDropdownOpen(!isDropdownOpen); // Toggle dropdown visibility
  // };

  function timeAgo(dateStr) {
    const now = new Date();
    const date = new Date(dateStr);
    const seconds = Math.floor((now - date) / 1000);

    let interval = Math.floor(seconds / 31536000); // Years
    if (interval >= 1)
      return interval + " year" + (interval > 1 ? "s" : "") + " ago";

    interval = Math.floor(seconds / 2592000); // Months
    if (interval >= 1)
      return interval + " month" + (interval > 1 ? "s" : "") + " ago";

    interval = Math.floor(seconds / 86400); // Days
    if (interval >= 1)
      return interval + " day" + (interval > 1 ? "s" : "") + " ago";

    interval = Math.floor(seconds / 3600); // Hours
    if (interval >= 1)
      return interval + " hour" + (interval > 1 ? "s" : "") + " ago";

    interval = Math.floor(seconds / 60); // Minutes
    if (interval >= 1)
      return interval + " minute" + (interval > 1 ? "s" : "") + " ago";

    return "just now"; // Less than a minute
  }

  useEffect(() => {
    if (selectedData) {
      setShareOption(selectedData.sharing); // Initialize shareOption from selectedData
    }
  }, [selectedData, shareOption]);

  return (
    <div className="tab-content note_tab_content">
      
      {/* Top Controls: Dropdown, Search, Filter */}
      {selectedData ? (
        <>
          {/* <div className="top-controls" style={{padding: '0px'}}>
            <div className="dealroom_detail_upper _flex_row_spacebtw_">
            <div className="dealroom_detail_upper_left _flex_gap_30">
              <div
                className="dashboard_upper_input"
                style={{ position: "relative" }}
                >
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className="search-input"
                  />

                <img src={searchicon} alt="search icon" />
              </div>
                  </div>
            </div>
          </div> */}

          <div className="controls _flex_gap_30">
            {/* Selected share option displayed next to the button */}
            <p className="selected-share-option">
              <img src={selectedData?.sharing === 1 ? lockIcon : selectedData?.sharing === 2 ? TeamIcon : groupIcon} alt="lockIcon" />
              {getShareLabel(selectedData?.sharing)}
            </p>

            <CommonButton
              label="Share"
              fontSize="14px"
              fontWeight="700"
              icon=""
              className={`dash_common_btn`}
              onClick={handleModalOpen}
              width="100px"
            />

          </div>

          {/* Sidebar and Main Content */}
      
          <div className="content-container">
            {/* Sidebar: List of data */}
            <div className="sidebar">
              {filteredData.map((item) => (
                <div
                  key={item.id}
                  className={`sidebar-item ${
                    selectedData?.id === item.id ? "active" : ""
                  }`}
                  onClick={() => {
                    setSelectedData(item);
                    setIsAdding(false);
                  }}
                >
                  <div className="sidebar-item-content">
                    <p className="sidebar-item-title">
                      {item.noteTitle}
                      <img src={item.sharing === 1 ? OnlyMeIcon : item.sharing === 2 ? TeamIcon : groupIcon} alt="note title icon" />
                    </p>
                    <p className="sidebar-item-subtitle">
                      {item.notesDescription.slice(0, 15)}...
                    </p>
                    <p className="sidebar-item-time">
                      {timeAgo(item?.created_at)}
                    </p>
                  </div>
                  <div className="sidebar-item-icon">
                    <img
                      src={DeleteIcon}
                      style={{
                        width: "15px",
                        height: "15px",
                        cursor: "pointer",
                      }}
                      alt="Delete"
                      onClick={() => setShowDeletePopup(true)}
                    />
                  </div>
                </div>
              ))}
            </div>

            {/* Main Content Area */}
            <div className="main-area">
              {selectedData ? (
                <>
                  {/* Top Section: Editable Title, Only Me Dropdown, and Share Button */}
                  <div className="main-header">
                    <input
                      type="text"
                      value={selectedData.noteTitle}
                      onChange={handleTitleChange}
                      className="editable-title"
                      placeholder="Enter Title"
                      maxLength={50}
                      minLength={1}
                    />
                  </div>

                  {/* Editable Description Section */}
                  <div className="main-body">
                    <textarea
                      value={selectedData.notesDescription}
                      onChange={handleDescriptionChange}
                      className="editable-description"
                      placeholder="Enter description here"
                      maxLength={500}
                      minLength={1}
                    />
                  </div>
                </>
              ) : (
                <div className="main-body">
                  <p>No data found</p>
                </div>
              )}
            </div>
          </div>

          {/* Delete confirmation popup */}
          {showDeletePopup && (
            <DeleteModal
              title={"Are you sure you want to delete this note?"}
              isOpen={showDeletePopup}
              onClose={() => setShowDeletePopup(false)}
              onSure={handleDelete}
            />
          )}

          <div className="contract_main_button">
            <CommonButton
              label="Cancel"
              fontSize="14px"
              fontWeight="700"
              className="dash_common_btn white_bg_btn"
              icon={false}
              onClick={handleCancel}
            />
            <CommonButton
              label="Save"
              fontSize="14px"
              fontWeight="700"
              className="dash_common_btn"
              icon=""
              isLoad={notesLoader}
              onClick={handleSave}
            />
          </div>
          <Modal show={showModal} onHide={handleModalClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>Share with</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="sharing-options">
                {/* Only Me Option */}
                <div
                  className={`share-option ${shareOption === 1 ? 'selected' : ''}`}
                  onClick={() => handleShareChange(1)}
                >
                  <img src={OnlyMeIcon} alt="Only Me" className="option-icon" style={{ width: '15px'}} />
                  <span>Only me</span>
                  {shareOption === 1 && <span className="check-mark">✔</span>}
                </div>

                {/* Project Team Option */}
                <div
                  className={`share-option ${shareOption === 2 ? 'selected' : ''}`}
                  onClick={() => handleShareChange(2)}
                >
                  <img src={TeamIcon} alt="Team" className="option-icon" style={{ width: '19px'}} />
                  <span>Team</span>
                  {shareOption === 2 && <span className="check-mark">✔</span>}
                </div>

                {/* Team + Client Option */}
                <div
                  className={`share-option ${shareOption === 3 ? 'selected' : ''}`}
                  onClick={() => handleShareChange(3)}
                >
                  <img src={groupIcon} alt="Team + Client" className="option-icon" />
                  <span>Team + Client</span>
                  {shareOption === 3 && <span className="check-mark">✔</span>}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="cancel-btn" onClick={handleModalClose}>Cancel</button>
              {/* <button className="share-btn" onClick={handleModalClose}>Share</button> */}
            </Modal.Footer>
          </Modal>
        </>
      ) : (

        <>
        {isLoading === true ? (
          <div className="loader-overlay">
            <Loader type="2" />
          </div>
        ) : 
        (
          <div className="no-data-message">No notes available</div>
        )
        }
        </>
        // <div className="no-data-message">No data is available</div>
      )}
      
    </div>
  );
};

export default Notes;
