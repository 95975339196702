import { combineReducers } from '@reduxjs/toolkit';
import phasesSliceReducer from '../features/phase/phaseSlice';
import statusSliceReducer from '../features/status/statusSlice';
import projectsSliceReducer from '../features/projects/projectsSlice';
import leadSourceSliceReducer from '../features/leadSource/leadSourceSlice';
import templatesSliceReducer from '../features/templates/templatesSlice';
import authSliceReducer, { logout } from '../features/auth/authSlice'

const appReducer = combineReducers({
  phases: phasesSliceReducer,
  status: statusSliceReducer,
  projects: projectsSliceReducer,
  leadSource: leadSourceSliceReducer,
  templates: templatesSliceReducer
//   auth: authSliceReducer
  // Add other reducers here if needed
});

const rootReducer = (state, action) => {
  if (action.type === logout.type) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer; // Ensure this is a default export