import React from 'react'
import Logo from './Logo'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../utils/constant'
import CommonButton from './CommonButton'

const HomeHeader = () => {
  const navigate = useNavigate()
  return (
    <div className='container-fluid'>
      <div className='_header_ shadow'>
          <Logo />
          <div>
            {/* <div className='d-flex gap-2'>
                <CommonButton
                 isTransparent={true}
                 width='100px' 
                 label='SIGNIN' 
                 icon=""
                 fontSize='14px'
                 fontWeight="700"
                 onClick={() => navigate(ROUTES.SIGNIN)}/>

                <CommonButton
                 width='100px' 
                 label='SIGNUP' 
                 icon=""
                 fontSize='14px'
                 fontWeight="700"
                 onClick={() => navigate(ROUTES.SIGNUP_EMAIL)}/>
            </div> */}
          </div>
      </div>
    </div>
  )
}

export default HomeHeader
