import React, { useState, useEffect } from "react";
import Select from "react-select";
import "./Tabs.css";
import Notes from "./Notes";
import Tasks from "./Tasks";
import StatusReports from "./StatusReports";
import notesImg from "../../../assets/notes/Group 1707478809.svg";
import StatusImg from "../../../assets/notes/fi_664477.svg";
import TasksImg from "../../../assets/notes/fi_9741124.svg";
// import { getProjectByBusinessService } from "../../../services";
import searchicon from "./../../../assets/dashboard/Search.svg";
import CommonButton from "../../../components/common/CommonButton";

const Tabs = ({ isGlobal, selectedProject }) => {
  const [activeTab, setActiveTab] = useState(isGlobal ? "Tasks" : "Notes");
  const [isAdding, setIsAdding] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const renderComponent = () => {
    switch (activeTab) {
      case "Notes":
        return (
          <Notes
            isAdding={isAdding}
            setIsAdding={setIsAdding}
            isGlobal={isGlobal}
            selectedProject={selectedProject}
            searchTerm={searchTerm}
          />
        );
      case "Tasks":
        return (
          <Tasks
            isAdding={isAdding}
            setIsAdding={setIsAdding}
            isGlobal={isGlobal}
            selectedProject={selectedProject}
          />
        );
      case "Status Reports":
        return (
          <StatusReports
            isAdding={isAdding}
            setIsAdding={setIsAdding}
            isGlobal={isGlobal}
            selectedProject={selectedProject}
          />
        );
      default:
        return (
          <Notes
            isAdding={isAdding}
            setIsAdding={setIsAdding}
            isGlobal={isGlobal}
            selectedProject={selectedProject}
          />
        );
    }
  };

  const getButtonText = () => {
    if (activeTab === "Notes") {
      return "+ Notes";
    } else if (activeTab === "Tasks") {
      return "+ Tasks";
    } else {
      return "+ Status Reports";
    }
  };

  const handleAddClick = () => {
    setIsAdding(true);
  };


  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };



  return (
    <div className="tabs-container invoice_main_div">
      <div className="tabs-header _flex_row_spacebtw_">
        <div className="dflex tabs-header-left">
          <div
            className={`tab-item ${activeTab === "Notes" ? "active" : ""}`}
            onClick={() => {
              setActiveTab("Notes");
              setIsAdding(false);
            }} // Reset adding state on tab change
          >
            <img src={notesImg} alt="Notes" />
            <span>Notes</span>
          </div>
          <div
            className={`tab-item ${activeTab === "Tasks" ? "active" : ""}`}
            onClick={() => {
              setActiveTab("Tasks");
              setIsAdding(false);
            }}
          >
            <img src={TasksImg} alt="Tasks" />
            <span>Tasks</span>
          </div>
          <div
            className={`tab-item ${
              activeTab === "Status Reports" ? "active" : ""
            }`}
            onClick={() => {
              setActiveTab("Status Reports");
              setIsAdding(false);
            }}
          >
            <img src={StatusImg} alt="Status Reports" />
            <span>Status Reports</span>
          </div>
        </div>

        <div className="dflex tabs-header-right">
          {activeTab === "Notes" && (
          <div className="top-controls" style={{padding: '0px'}}>
              <div className=" _flex_row_spacebtw_">
                <div className="dealroom_detail_upper_left _flex_gap_30">
                  <div
                    className="dashboard_upper_input"
                    style={{ position: "relative" }}
                    >
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={handleSearchChange}
                      className="search-input"
                      style={{
                        height:' 44px',
                        marginTop: '3px'
                      }}
                    />

                    <img src={searchicon} alt="search icon" />
                  </div>
                </div>
              </div>
          </div>
          )}
          <div className="top-controls">
            {activeTab !== "Tasks" && (
              <CommonButton
                label={getButtonText()}
                fontSize="14px"
                fontWeight="700"
                icon=""
                className={`dash_common_btn`}
                onClick={handleAddClick}
                // width="100px"
              />
            )}
          </div>
        </div>
      </div>

      <div className="tab-content">{renderComponent()}</div>
    </div>
  );
};

export default Tabs;
