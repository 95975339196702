import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import TextEditor from "./TextEditor";

import GlobalSearch from "../../components/common/GlobalSearch";
import { logout } from "../../features/auth/authSlice";
import { persistor } from "../../store";

import plus from "../../assets/dashboard/plus.svg";
import templatePlacehoder from "../../assets/dashboard/dealroom/template_gallery.svg";
import threeDotsIcon from "../../assets/dashboard/three-dots.svg";
import { Dropdown, Tabs, Tab } from "react-bootstrap";
import { useEffect, useState } from "react";
import { getTemplatesService, postUploadDoc, createTemplateService, deleteTemplateService, updateTemplateService } from "../../services";
import TemplateFilter from "./TemplateFilter";

const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
      [{ align: [] }],
    ],
  };

const Templates = () => {
    let userDetail = JSON.parse(localStorage.getItem("authUserData")) || null;
    const token = localStorage.getItem('token');
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [myTemplatesList, setMyTemplatesList] = useState([]);
    const [templateGallery, setTemplateGallery] = useState([]);
    const [myTemplatesLisAll, setMyTemplatesListAll] = useState([]);
    const [templateGalleryAll, setTemplateGalleryAll] = useState([]);
    const [isFormView, setIsFormView] = useState(false);

    const [title, setTitle] = useState('');
    const [htmlString, setHtmlString] = useState('');
    const [templateName, setTemplateName] = useState('Proposal');
    const [activeTab, setActiveTab] = useState('myTemplate');
    const [titleErr, setTitleErr] = useState('');
    const [btnLoader, setBtnLoader] = useState(false);
    const [activeId, setActiveId] = useState('');


    const handleLogout = () => {
        localStorage.clear();
        dispatch(logout());
        persistor.purge()
        navigate("/signin");
    };


    useEffect(() => {
        if(!isFormView){
            document.getElementById('remove_btn_class').classList.remove('btn')
        }
       
    }, [])



    const getTemplates = async () => {
        let query = `?businessId=${userDetail?.businessDetail?.businessId}`;
        
        try {
            const res = await getTemplatesService(query);
            if(res?.data?.status){
                let _templates = res?.data?.responseData?.templates || [];
                let _myTemplatesList = _templates.filter(item => item.type == 2);
                let _templateGallery = _templates.filter(item => item.type == 1);
                setTemplateGallery(_templateGallery)
                setMyTemplatesList(_myTemplatesList)
                setTemplateGalleryAll(_templateGallery)
                setMyTemplatesListAll(_myTemplatesList)
            }
        } catch (error) {
            console.error('Error fetching templates:', error);
        }
    };


    useEffect(() => {
        if(userDetail?.businessDetail?.businessId){
            getTemplates();
        }
    }, [])



    const handleDropDwonClick = type => {
        if (type === 'file') {
            document.getElementById('fileInput').click();
        } else {
            setIsFormView(true);
        }
    };



    const handleFileUpload = async (e) => {
        try {
            const file = e.target.files[0];
            const formData = new FormData();
    
            if (file) {
                formData.append('file', file);
                const res = await postUploadDoc(formData);
                if(res?.data?.status){
                    setHtmlString(res.data.responseData.html_content);
                    setIsFormView(true);
                }
                
            }

        } catch (error) {
          console.error("Error saving signature:", error);
        }
    };



    const handleEditView = (item, isNew) => {
        if(!isNew){
            setActiveId(item.id)
        }
        setIsFormView(true);
        setTitle(item.title);
        setHtmlString(item.htmlString);
        setTemplateName(item.templateName);
    }


    const handleCloseFormView = () => {
        setActiveId('')
        setIsFormView(false);
        setTitle('');
        setHtmlString('');
        setTemplateName('');
    }


    const handleValidate = () => {
        let validate = true;

        if(!title){
            setTitleErr('Title is requird');
            validate = false
        }
        

        return validate;

    }



    const handleCreateTemplate = async () => {
        if (handleValidate()) {
            let params = {
                templateName,
                title,
                htmlString,
                type: 2,
                industry: templateName,
                businessId: userDetail?.businessDetail?.businessId
            };    
            try {
                setBtnLoader(true);

                if(activeId){
                    let query = `?templateId=${activeId}`
                    await updateTemplateService(query, params, token);
                }else{
                    await createTemplateService(params, token);
                }
                setActiveTab('myTemplate');
                getTemplates();
                setBtnLoader(false);
                handleCloseFormView();
            } catch (error) {
                setBtnLoader(false);
                console.error("Error creating template:", error);
            }
        }
    };


    const handleDeleteTemplate = async (item) => {
        let query = `/${item.id}`;
        try {
            await deleteTemplateService(query, token);
            getTemplates();
        } catch (error) {
            console.error("Error deleting template:", error);
        }
    }


    const handleFilter = (selectedTypes, selectedIndustry) => {
        let _myTemplatesList = [...myTemplatesLisAll];
        let _templateGallery = [...templateGalleryAll];
        if (selectedTypes.length) {
            selectedTypes = selectedTypes.map(item => item.toLowerCase());
            _myTemplatesList = _myTemplatesList.filter(template => 
                selectedTypes.includes(template.templateName.toLowerCase())
            );
        }

        if (selectedIndustry.length) {
            selectedIndustry = selectedIndustry.map(item => item.toLowerCase());
            _templateGallery = _templateGallery.filter(template => 
                selectedIndustry.includes(template.industry.toLowerCase())
            );
        }

        setMyTemplatesList(_myTemplatesList);
        setTemplateGallery(_templateGallery);

    
    };

    

    return (
        <>
            <input
                type="file"
                id="fileInput"
                style={{ display: 'none' }}
                accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                onChange={handleFileUpload}
                />


<div className="dashboard_upper projectdetails_dash_upper">

            <div className="projectdetails_dash_left">
                {userDetail?.businessDetail?.logo ? <img src={userDetail?.businessDetail?.logo} className="fav_star" alt="Star Toggle"/> : ''}
                
                <h3>{userDetail?.businessDetail?.businessName}</h3>
            </div>
            <div className="projectdetails_dash_left">
                <GlobalSearch />
                <div className="logout_main_div">
                    <div className="name_intials_dash_upper">
                        {userDetail.firstName ? userDetail.firstName.charAt(0).toUpperCase() : ''}{userDetail.lastName ? userDetail.lastName.charAt(0).toUpperCase() : ''}
                    </div>
                    <div className="logout_div">
                    <p onClick={handleLogout}>Logout</p>
                    </div>
                </div>
                </div>
            </div>

            <div className="template_page_wrapper">
                <div className="template_box_wrapper">
                    <div className="template_box_header">
                        <h3>Templates</h3>
                        {
                            isFormView ? '' : (
                                <div className="cm_drop_dwon">
                                    <Dropdown>
                                        <Dropdown.Toggle  className="common-button shadow dash_common_btn px-5" variant="primary" id="remove_btn_class">
                                            <img src={plus} alt='icon'/> <b> New</b> 
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="javascript:void(0)" onClick={() => handleDropDwonClick('file')}>Upload Your Template</Dropdown.Item>
                                            <Dropdown.Item href="javascript:void(0)" onClick={() => handleDropDwonClick('blank')}>Create From Blank File</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            )
                        }
                        
                    </div>


                    {
                        isFormView ? (
                                    <div className="d-flex flex-column justify-content-between" style={{minHeight: 'calc(100vh - 300px)'}}>
                                        <div className="row">
                                            <div className="col-md-4 mt-5">
                                                <div className="form-group mb-5">
                                                    <label>Template Name</label>
                                                    <input
                                                        value={title}
                                                        onChange={e => (setTitle(e.target.value), setTitleErr(''))}
                                                        type="text"
                                                        className="form-control" />

                                                    {titleErr ? <span className="cm_err">{titleErr}</span> : ''}
                                                </div>
                                            </div>

                                            <div className="col-md-4 mt-5">
                                                <div className="form-group mb-5">
                                                    <label>Template Type</label>
                                                    <select value={templateName} onChange={e => setTemplateName(e.target.value)} className="form-control">
                                                        <option value="Proposal">Proposal</option>
                                                        <option value="Contract">Contract</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-11 mb-5 mt-5">
                                                {/* <ReactQuill
                                                    value={htmlString}
                                                    onChange={setHtmlString}
                                                    style={{ height: 300 }}
                                                    modules={modules} /> */}

                                                    <TextEditor val={htmlString} change={setHtmlString} />
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-end mt-5">
                                            <span className="common-button shadow   dash_common_btn white_bg_btn px-5" onClick={handleCloseFormView}><b>Cancel</b></span>
                                            <span onClick={() => btnLoader ? null : handleCreateTemplate()} className="common-button shadow dash_common_btn px-5 ms-3"><b>Save In My Templates</b></span>
                                        </div>
                                    </div>
                        ) : (

                            <Tabs
                                onSelect={e => setActiveTab(e)}
                                defaultActiveKey="myTemplate"
                                id="uncontrolled-tab-example"
                                className="mb-3">
                                <Tab eventKey="myTemplate" title="My Templates">
                                    <div className="d-flex">
                                        <TemplateFilter viewType="myTemplate" callback={handleFilter} />
                                        <div className="d-flex flex-wrap">
                                            {
                                                myTemplatesList.map(item => (
                                                    <div key={item.id} className="_template_box">
                                                        <div className="_template_box_inner">
                                                            <img onClick={() => handleEditView(item, '')} src={templatePlacehoder} />
                                                            <div className="dropdown_option">
                                                                <Dropdown>
                                                                    <Dropdown.Toggle id="dropdown-basic">
                                                                        <img src={threeDotsIcon} alt='icon' />
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item onClick={() => handleDeleteTemplate(item)} href="javascript:void(0)">Delete</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                            
                                                        </div>
                                                        <h5>{item.title}</h5>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    
                                </Tab>
                                <Tab eventKey="templateGallery" title="Template Gallery">
                                    <div className="d-flex">
                                        <TemplateFilter viewType="templateGallery" callback={handleFilter} />
                                        <div className="d-flex flex-wrap">
                                            {
                                                templateGallery.map(item => (
                                                    <div key={item.id} className="_template_box">
                                                        <div className="_template_box_inner">
                                                            <img onClick={() => handleEditView(item, 'new')} src={templatePlacehoder} />
                                                        </div>
                                                        <h5>{item.title}</h5>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>


                                   
                                </Tab>
                            </Tabs>

                        )
                    }
 
                </div>
            </div>


          
  
        
      </>
    )
}

export default Templates;




