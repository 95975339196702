import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import GlobalSearch from "../../components/common/GlobalSearch";
import { persistor } from "../../store";
import { logout } from "../../features/auth/authSlice";
import { getClientsByBusinessService, clientDeleteService } from "../../services";
import { useEffect, useState } from "react";
import { RemoveIcon } from "../../components/SVG";
import searchIcon from "../../assets/dashboard/Search.svg";
import plus from "../../assets/dashboard/plus.svg";
import penIcon from "../../assets/logos/pen.svg";
import AddClientModal from "../projects/project-team/AddClientModal";
import DeleteModal from "../../modals/DeleteModal";

const GlobalClients = () => {
  let projectDetail = JSON.parse(localStorage.getItem("projectDetail"));
  let authUserData = JSON.parse(localStorage.getItem("authUserData"));
  const token = localStorage.getItem('token');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [clientList, setClientList] = useState([]);
  const [searchTerm, setSearchTerm] = useState(''); 
  const [showClientModal, setShowClientModal] = useState(''); 
  const [showDeletePopup, setShowDeletePopup] = useState(false); 
  const [activeId, setActiveId] = useState(''); 
  const [activeClient, setActiveClient] = useState({}); 



  const handleLogout = () => {
    localStorage.clear();
    dispatch(logout());
    persistor.purge()
    navigate("/signin");
  };



  const getAllClients = async () => {
    if(authUserData?.businessDetail?.businessId){
      let query = `/?businessId=${authUserData?.businessDetail?.businessId}`
      try {
        const res = await getClientsByBusinessService(query, token);
        if(res?.data?.status){
          setClientList(res?.data?.responseData?.clients || [])
        }
      
      } catch (error) {
        console.error(error);
      }
    }
    
  };


  useEffect(() => {
    getAllClients()
  }, [])



  const cmCallback = (type) => {
    setShowClientModal(false);
    getAllClients();
  }


  const handleDeleteClient = () => {
    let params = `${activeId}`;
    setShowDeletePopup(false)
    clientDeleteService(params).then(res => {
      getAllClients();
      setActiveId('')
    })
  }


  const filteredClients = clientList.filter((client) => {
    const lowerCaseSearch = searchTerm.toLowerCase();
    return (
      client.firstName?.toLowerCase().includes(lowerCaseSearch) ||
      client.lastName?.toLowerCase().includes(lowerCaseSearch) ||
      client.email?.toLowerCase().includes(lowerCaseSearch)
    );
  });

  
  

  return (

    <>
        <div className="dashboard_upper projectdetails_dash_upper">
            <div className="projectdetails_dash_left">
              {authUserData?.businessDetail?.logo ? <img src={authUserData?.businessDetail?.logo} className="fav_star" alt="Star Toggle"/> : ''}
                
                <h3>{authUserData?.businessDetail?.businessName}</h3>
            </div>
            <div className="projectdetails_dash_left">
                <GlobalSearch />
                <div className="logout_main_div">
                    <div className="name_intials_dash_upper">
                        {authUserData.firstName ? authUserData.firstName.charAt(0).toUpperCase() : ''}{authUserData.lastName ? authUserData.lastName.charAt(0).toUpperCase() : ''}
                    </div>
                    <div className="logout_div">
                        <p onClick={handleLogout}>Logout</p>
                    </div>
                </div>
            </div>
        </div>

        <div className="page_wrapper" style={{background: '#fff'}}>
          <div className="mb-3">
              <h3>Client</h3>
              <p>Whenever a client is added to a project, they are automatically saved into the client section making it easy to access and use their information for future projects.</p>
          </div>



          <div className="d-flex justify-content-between align-items-center mt-5">
              <div className="cm_search">
                  <img src={searchIcon} alt="searchicon" />
                  <input
                      className="form-control"
                      type="text"
                      placeholder="Search"
                      value={searchTerm} // Bind input to searchTerm
                      onChange={(e) => setSearchTerm(e.target.value)} // Update searchTerm on input change
                  />
              </div>
              <span onClick={() => setShowClientModal(true)} className="common-button shadow dash_common_btn px-5">
                  <img src={plus} alt='icon'/> <b> Add New Client</b> 
              </span>
          </div>



          <div className="cm_table_view mt-4 mb-5">
            <div className="table-responsive">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>S.No.</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Project</th>
                        <th>Address</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th className="text-center">Action</th>
                    </tr>
                </thead>
                <tbody>

                    {
                        filteredClients.map((item, index) => (
                            <tr key={item.id}>
                                <td>{index + 1}</td>
                                <td>{item.firstName || ''}</td>
                                <td>{item.lastName || ''}</td>
                                <td>-</td>
                                <td>{item.address || '-'}</td>
                                <td>{item.country_code}{item.phone_number}</td>
                                <td>{item.email}</td>
                                <td className="text-center">
                                    <span onClick={() => setActiveClient(item)} className="me-3 cursor-pointer"><img src={penIcon} /></span>
                                    <span onClick={() => (setShowDeletePopup(true), setActiveId(item.id))} className="cursor-pointer"><RemoveIcon /></span>
                                </td>
                            </tr>
                        ))
                    }
                    
                    
                </tbody>
                </table>
            </div>
        </div>


        <DeleteModal
          title={"Are you sure you want to delete this client?"}
          isOpen={showDeletePopup}
          onClose={() => setShowDeletePopup(false)}
          onSure={handleDeleteClient}
        />


        </div>

        {(showClientModal || activeClient.id) && <AddClientModal
                              activeClient={activeClient}
                              isFromGlobal={true}
                              cmCallback={cmCallback}
                              onHide={() => (setShowClientModal(false), setActiveClient({}))} />}



        
    </>

   
  );
};

export default GlobalClients;
