import React from "react";
import HomeHeader from "../components/common/HomeHeader";
import error from "./../assets/images/404-error.svg";
import CommonButton from "../components/common/CommonButton";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <div>
      <HomeHeader />
      <div className="error_main_div">
        <img src={error} className="error_img" />
        <CommonButton
          type="button"
          width="180px"
          label="Back to Home"
          icon=""
          fontSize="16px"
          fontWeight="700"
          onClick={()=>{navigate("/")}}
        />
      </div>
    </div>
  );
}
