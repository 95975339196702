'use client'
import React, { createContext, useState, useContext } from 'react';

const StepContext = createContext();

export const StepProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});
  const nextStep = () => setCurrentStep(currentStep + 1);
  const prevStep = () => setCurrentStep(currentStep - 1);
  const updateFormData = (data) => setFormData({ ...formData, ...data });
  const resetSteps = () => setCurrentStep(0) ;
  const setStep = (step) => setCurrentStep(step)

  return (
    <StepContext.Provider
      value={{
        formData,
        currentStep,
        resetSteps,
        setCurrentStep,
        nextStep,
        prevStep,
        updateFormData,
        setStep
        }}>
      {children}
    </StepContext.Provider>
  );
};

export const useStep = () => useContext(StepContext);