import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import phasesSliceReducer from './features/phase/phaseSlice';
import statusSliceReducer from './features/phase/phaseSlice';
import rootReducer from './redux/rootReducer';

const persistConfig = {
  key: 'root',
  storage,
  // Optionally, you can whitelist/blacklist specific reducers
  // whitelist: ['businessPhases'], // only 'businessPhases' reducer will be persisted
  // blacklist: ['auth'], // 'auth' reducer will not be persisted
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

export default store;