import { RemoveIcon } from "../../../components/SVG";


const TableView = ({list, deleteCallback}) => {
    const userRole = localStorage.getItem('userRole');
    return(
        <div className="cm_table_view mt-4 mb-5">
            <div className="table-responsive">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th></th>
                        <th className="ps-5">Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Role</th>
                        {userRole != 'Associate' ? <th className="text-center">Action</th> : ''}
                        
                    </tr>
                </thead>
                <tbody>

                    {
                        list.map((item, index) => (
                            <tr key={item.id}>
                                <td></td>
                                <td className="ps-5">{(!item.firstName && !item.lastName) ? '-' : item.firstName} {item.lastName}</td>
                                <td>{item.email || ''}</td>
                                <td>{item.country_code || ''} {item.phone_number || '-'}</td>
                                <td>{item.role || 'Client'}</td>
                                {userRole != 'Associate' ? <td className="text-center">
                                    <b onClick={() => deleteCallback(item.id)} className="cross_icon"></b>
                                </td> : ''}
                            </tr>
                        ))
                    }
                    
                    
                </tbody>
                </table>
            </div>
        </div>
    )
}

export default TableView;