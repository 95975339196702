
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  phases: {},
};

const phasesSlice = createSlice({
  name: 'phases',
  initialState,
  reducers: {
    addPhases: (state, action) => {
      state.phases = action.payload;
    },
  },
});

export const { addPhases } = phasesSlice.actions;

export default phasesSlice.reducer;