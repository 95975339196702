import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import CommonButton from "../../../components/common/CommonButton";
import { getChangeRequestList, getProjectByBusinessService } from "../../../services";
import { _contractStatus, contractStatus } from "../../../utils/constant";
import searchIcon from "./../../../assets/dashboard/Search.svg";
import changeIcon from "./../../../assets/dashboard/change_blue.svg";
import filterIcon from "./../../../assets/dashboard/filter_icon.svg";
import plusIcon from "./../../../assets/dashboard/plus.svg";
import ChangeRequestFilter from "./../../../modals/ChangeRequestFilter";
import AddChangeRequest from "./AddChangeRequest";
import threeDot from './../../../assets/logos/three-dot.svg'
import ActionModal from "../../../modals/ActionModal";

const formatDate = (comingDate) => {
  const date = new Date(comingDate);
  const options = { day: "2-digit", month: "long", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-GB", options);
  const [day, month, year] = formattedDate.split(" ");
  return `${month} ${day}, ${year}`;
};

const ChangeRequest = ({ activeTab, isGlobal }) => {
  const projectDetail = JSON.parse(localStorage.getItem('projectDetail'));
  const userDetail = JSON.parse(localStorage.getItem('authUserData'));

  const [showChangeRequestModal, setShowChangeRequestModal] = useState(false);
  const [showChangeRequestFilter, setShowChangeRequestFilter] = useState(false);
  const [addChangeRequest, setAddChangeRequest] = useState(false);
  const [notShowAgain, setNotShowAgain] = useState(false);
  const [searchTitle, setSearchTitle] = useState("");
  const [filterStatus, setFilterStatus] = useState([
    ...contractStatus.map((item) => ({ ...item, isChecked: false })),
  ]);
  const [changeRequestList, setChangeRequestList] = useState([]);
  const [changeRequestListFilter, setChangeRequestListFilter] = useState([]);
  const [staticDetails, setStaticDetails] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const debounceTimeout = useRef(null);
  const [projectsList, setProjectList] = useState([]);
  const [selectedProject, setSelectedProject] = useState({});
  const [selectedProjectErr, setSelectedProjectErr] = useState('');
  const [showProjectListModal, setShowProjectListModal] = useState(false);

  const handleChangeRequestModalClose = () => setShowChangeRequestModal(false);
  const handleChangeRequestFilterClick = () => setShowChangeRequestFilter(true);
  const handleAddChangeRequestClick = () => setAddChangeRequest(true);
  const handleAddChangeRequestClose = () => setAddChangeRequest(false);
  const [isActionModalOpen, setIsActionModalOpen] = useState(false)
  const [dealroomId, setDealRoomId] = useState('')
  const [actionTitle, setActionTitle] = useState('')
  const [currentPdf, setCurrentPdf] = useState('')
  const [activeProject, setActiveProject] = useState({})
  const handleNotShowAgain = (e) => {
    localStorage.setItem("showChangeRequestModal", e.target.checked);
    setNotShowAgain(e.target.checked);
  };
  const handleSearchTitle = (e) => {
    const value = e.target.value;
    setSearchTitle(value);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      applyFilters(value, filterStatus);
    }, 500);
  };

  const applyFilters = (searchQuery, filters) => {
    const checkedFilters = filters
      .filter((filter) => filter.isChecked)
      .map((filter) => filter.id);

    let filteredList = changeRequestList;

    if (checkedFilters.length > 0) {
      filteredList = filteredList.filter((item) =>
        checkedFilters.includes(item.status)
      );
    }

    if (searchQuery) {
      filteredList = filteredList.filter((item) =>
        item.documentTitle.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    setChangeRequestListFilter(filteredList || []);
  };

  useEffect(() => {
    applyFilters(searchTitle, filterStatus);
  }, [filterStatus]);

  useEffect(() => {
    if (activeTab === "Change Requests") {
      const showChangeRequestModal = localStorage.getItem("showChangeRequestModal") || false;
      setAddChangeRequest(false);
      if (showChangeRequestModal) {
        setShowChangeRequestModal(false);
      } else {
        setShowChangeRequestModal(true);
      }
      setStaticDetails(projectDetail);
      setUserDetails(userDetail);
      fetchChangeRequestList();

      if(selectedProject.value){
        localStorage.setItem('selectedProject', selectedProject.value)
      }


    }
  }, [activeTab, isGlobal, selectedProject]);










  const fetchChangeRequestList = async () => {
    try {
      const projectId = isGlobal ? selectedProject.value || '' : projectDetail.id;
      const query = `?businessId=${userDetail?.businessDetail?.businessId}${projectId ? `&projectId=${projectId}` : ''}`;
      const response = await getChangeRequestList(query);
      const data = response.data?.responseData?.data;
      setChangeRequestList(data);
      setChangeRequestListFilter(data);
    } catch (error) {
      console.error("Error fetching change request list:", error);
    }
  };


  const getAllProjects = async () => {
    try {
        const res = await getProjectByBusinessService(userDetail?.businessDetail?.businessId);
        let _projects = res.data.responseData.projectData || [];
        if(_projects.length > 0) {
            _projects = _projects.map(item => ({...item, label: item.projectName, value: item.id}))
            setProjectList(_projects);
        }
    } catch (error) {
        console.error('Error fetching projects:', error);
    }
};


useEffect(() => {
    if(userDetail?.businessDetail?.businessId && isGlobal){
        getAllProjects();
    }
    
}, [isGlobal])

  if (addChangeRequest) {
    return (
      <AddChangeRequest
      selectedProject={selectedProject}
        handleCancel={handleAddChangeRequestClose}
        staticDetails={staticDetails}
        userDetails={userDetails}
        refreshChangeRqstList={fetchChangeRequestList}
      />
    );
  }



  const actionModalHandler = item => {
    if(item.id && item.documentTitle && item?.pdfRecord?.rawpdfFile){
      setIsActionModalOpen(true);
      setDealRoomId(item.id)
      setActionTitle(item.documentTitle)
      setCurrentPdf(item.pdfRecord.rawpdfFile)
      setActiveProject(item)
    }
  }


  const actionHandler = (id, type) => {

  }

  const handleCloseActionModal = () => {
    setDealRoomId('')
    setActionTitle('')
    setIsActionModalOpen(false)
  }


  const handleSelectProject = () => {
    if(selectedProject?.value){
      localStorage.setItem('projectDetail', JSON.stringify(selectedProject));
      setAddChangeRequest(true)
      setShowProjectListModal(false)
    }else{
      setSelectedProjectErr('Select a project')
    }
  }


  const handleUploadClick = () => {
    if(isGlobal){
      setShowProjectListModal(true)
    }else{
      setAddChangeRequest(true)
    }
  };

  return (
    <>


      <Modal show={showProjectListModal} onHide={() => setShowProjectListModal(false)} className="dealroom_intital_modal">
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="p-5 pt-0 text-start">
            <h5 className="mb-5">Select project for which you want to create a dealroom doument</h5>
            <Select
              classNamePrefix="cm_ui_select "
              value={selectedProject.value ? selectedProject : ''}
              onChange={e => (setSelectedProject(e), setSelectedProjectErr(''))}
              options={[
                  ...projectsList
              ]}
            placeholder="Select project" /> 

            {selectedProjectErr ? <h6 className="res_err">{selectedProjectErr}</h6> : ''}

            <div className="justify-content-between align-items-center d-flex mt-5">
              <span onClick={() => setShowProjectListModal(false)} className="btn btn-dark px-4">Cancel</span>
              <span onClick={handleSelectProject} className="btn btn-primary px-4">Create</span>
            </div>
          </div>
        </Modal.Body>
      </Modal>



      <div className="dealroom_main_div">
        <div className="dealroom_detail_upper _flex_row_spacebtw_">
          <div className="dealroom_detail_upper_left _flex_gap_30">
          {isGlobal ?  <Select
                          classNamePrefix="cm_ui_select min_width"
                          value={selectedProject.value ? selectedProject : ''}
                          onChange={e => setSelectedProject(e)}
                          options={projectsList}
                          placeholder="Select Project" /> : ''}
            <div className="dashboard_upper_input ">
              <input
                type="text"
                placeholder="Search"
                value={searchTitle}
                onChange={handleSearchTitle}
              />
              <img src={searchIcon} alt="searchIcon" />
            </div>
            <CommonButton
              label="Filter"
              fontSize="14px"
              fontWeight="700"
              className="dash_common_btn white_bg_btn"
              icon={filterIcon}
              onClick={handleChangeRequestFilterClick}
            />
            {showChangeRequestFilter && (
              <ChangeRequestFilter
                handleFilterClose={() => setShowChangeRequestFilter(false)}
                filterBy={filterStatus}
                setFilterBy={setFilterStatus}
              />
            )}
          </div>
          <div className="dealroom_detail_upper_right _flex_gap_30">
            <CommonButton
              label="Change Request"
              fontSize="14px"
              fontWeight="700"
              className="dash_common_btn"
              icon={plusIcon}
              onClick={handleUploadClick}
            />
          </div>
        </div>
        <h2 className="dashboard_heading">Change Request</h2>
        <div className="table-scrolling" style={{ marginTop: "40px" }}>
          <table
            className="project_table dealroom_table"
            style={{ marginTop: 0 }}
          >
            <thead style={{ position: "sticky", top: -1, zIndex: 1 }}>
              <tr>
                <th className="text-start ps-4">S.No.</th>
                <th className="text-start">Created</th>
                <th className="text-start">Title</th>
                <th className="text-start">Status</th>
                <th className="text-start">Sent to</th>
                <th className="text-start">Sent By</th>
                <th className="text-start">Action</th>
              </tr>
            </thead>
            <tbody>
              {changeRequestListFilter?.length > 0 ? (
                changeRequestListFilter.map((changeRequest, i) => {
                  const statusObject = contractStatus.find(
                    (item) => item.id === changeRequest.status
                  );
                  return (
                    <tr>
                      <td className="text-start ps-4">{i + 1}</td>
                      <td className="text-start">{formatDate(new Date(changeRequest.created_at).toISOString().split("T")[0])}</td>
                      <td className="dealroom_table_title text-start"><span onClick={() => window.open(`/view-template?createRequestId=${changeRequest.id}`, "_blank")}>{changeRequest?.documentTitle}</span></td>
                      
                      <td className="text-start">
                        <p className="phases text-center ms-0" style={{backgroundColor: _contractStatus[`${changeRequest.status}`].color}}>{_contractStatus[`${changeRequest.status}`].value}</p>
                      </td>
                   
               
                      <td className="text-start" style={{textAlign: 'start'}}>
                        <div style={{display:'flex', justifyContent:'center',flexDirection:'column'}}>
                          {changeRequest.signers.map((signer, idx) => (
                            <div key={idx}>
                              <span style={{color : _contractStatus[signer.status]?.color}}>{_contractStatus[signer.status]?.value}</span> : {signer.name}
                            </div>
                          ))}
                        </div>
                      </td>
                      <td className="text-start">{userDetails.firstName} {userDetails.lastName}</td>
                      <td className="text-start" style={{cursor:'pointer'}}><span onClick={() => actionModalHandler(changeRequest)}><img src={threeDot} alt="three dot"/></span></td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={6} style={{ textAlign: "center" }}>
                    No Change Request
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        show={showChangeRequestModal}
        onHide={handleChangeRequestModalClose}
        className="dealroom_intital_modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img src={changeIcon} alt="changeIcon" />
          <h3>Change Request</h3>
          <p className="font_16">
            We understand change is inevitable. Document and Secure approvals
            for any changes requested by you or your client.
          </p>
          <div
            className="dealroom_intital_modal_div"
            style={{ justifyContent: "center" }}
          >
            <CommonButton
              label="Create New Change Request"
              fontSize="14px"
              fontWeight="700"
              className="dash_common_btn"
              icon={false}
              width={"52%"}
              onClick={handleAddChangeRequestClick}
            />
          </div>
          <h6 className="font_14">
            <input
              type="checkbox"
              value={notShowAgain}
              onClick={handleNotShowAgain}
            />
            Don’t show this again
          </h6>
        </Modal.Body>
      </Modal>


      {isActionModalOpen && <ActionModal
          isOpen={isActionModalOpen}
          title={actionTitle}
          isCRView={true}
          onClose={handleCloseActionModal}
          onSave={actionHandler}
          dealRoomID={dealroomId}
          pdf={currentPdf}
          activeProject={activeProject}
      />}




    </>
  );
};

export default ChangeRequest;
