import React from "react";

const Terms = () => {
  return (
    <>
      <div style={{ maxWidth: "750px", margin: "0 auto" }}>
        <h2>
          <a id="_488lt9i8y1nm\"></a>
          <strong>Terms and Conditions </strong>
        </h2>
        <h3>
          <a id="_vixk3isat2pn\"></a>
          <strong>1. Introduction</strong>
        </h3>
        <p>
          Welcome to Xora Business Solutions Private Limited (&quot;Xora&quot;,
          &quot;we&quot;, &quot;our&quot;, &quot;us&quot;). These terms and
          conditions (&quot;Terms&quot;) outline the rules and regulations for
          the use of our website, located at
          <a href="http://www.getxora.com\"> www.getxora.com</a> (the
          &quot;Website&quot;), and our services.
        </p>
        <p>
          By accessing this Website, we assume you accept these Terms in full.
          Do not continue to use Xora’s Website if you do not accept all of the
          Terms stated on this page.
        </p>
        <h3>
          <a id="_hvdx60uo7g7u\"></a>
          <strong>2. Definitions</strong>
        </h3>
        <ul>
          <li>
            <strong>Services</strong>: Refers to the various business management
            tools provided by Xora through the Website.
          </li>
          <li>
            <strong>User</strong>: Refers to anyone who accesses or uses the
            Website and Services.
          </li>
          <li>
            <strong>Client</strong>: Refers to businesses or individuals who
            subscribe to Xora’s Services.
          </li>
        </ul>
        <h3>
          <a id="_yr76pmq8yrgs\"></a>
          <strong>3. Use of the Website</strong>
        </h3>
        <p>
          By using this Website, you represent and warrant that you are at least
          18 years old and capable of forming a binding contract under Indian
          Contract Act, 1872. You agree to use the Website only for lawful
          purposes and in a way that does not infringe the rights of, restrict,
          or inhibit anyone else's use and enjoyment of the Website.
        </p>
        <h3>
          <a id="_mesmli76pwmb\"></a>
          <strong>4. Services and Subscriptions</strong>
        </h3>
        <p>
          Xora offers various subscription plans for its Services. Details of
          the subscription plans, including the fees, features, and duration,
          are available on the Website. By subscribing to our Services, you
          agree to pay the subscription fees and adhere to the terms associated
          with the selected plan.
        </p>
        <h3>
          <a id="_29ml8i4qpg0d\"></a>
          <strong>5. User Accounts</strong>
        </h3>
        <p>
          To access certain features of the Services, you may be required to
          create an account. You agree to provide accurate and complete
          information when creating your account. You are responsible for
          maintaining the confidentiality of your account credentials and for
          all activities that occur under your account.
        </p>
        <h3>
          <a id="_3whynic6v0v9\"></a>
          <strong>6. Intellectual Property</strong>
        </h3>
        <p>
          Unless otherwise stated, Xora and/or its licensors own the
          intellectual property rights for all material on the Website. All
          intellectual property rights are reserved. You may view and/or print
          pages from the Website for your own personal use subject to
          restrictions set in these Terms.
        </p>
        <h3>
          <a id="_wjmyw1bdio4i\"></a>
          <strong>7. Prohibited Uses</strong>
        </h3>
        <p>You are expressly prohibited from:</p>
        <ul>
          <li>Republishing material from the Website</li>
          <li>Selling, renting, or sub-licensing material from the Website</li>
          <li>
            Reproducing, duplicating, or copying material from the Website
          </li>
          <li>
            Redistributing content from Xora (unless content is specifically
            made for redistribution)
          </li>
        </ul>
        <h3>
          <a id="_vim5zl1lpmsc\"></a>
          <strong>8. Limitation of Liability</strong>
        </h3>
        <p>
          In no event shall Xora, nor any of its officers, directors, and
          employees, be liable to you for anything arising out of or in any way
          connected with your use of this Website, whether such liability is
          under contract, tort, or otherwise, and Xora, including its officers,
          directors, and employees, shall not be liable for any indirect,
          consequential, or special liability arising out of or in any way
          related to your use of this Website.
        </p>
        <h3>
          <a id="_76pmjfcwy7u2\"></a>
          <strong>9. Indemnification</strong>
        </h3>
        <p>
          You hereby indemnify to the fullest extent Xora from and against any
          and all liabilities, costs, demands, causes of action, damages, and
          expenses (including reasonable attorney's fees) arising out of or in
          any way related to your breach of any of the provisions of these
          Terms.
        </p>
        <h3>
          <a id="_5cdqamw4ta\"></a>
          <strong>10. Severability</strong>
        </h3>
        <p>
          If any provision of these Terms is found to be invalid under any
          applicable law, such provisions shall be deleted without affecting the
          remaining provisions herein.
        </p>
        <h3>
          <a id="_d5xor69pjkw\"></a>
          <strong>11. Variation of Terms</strong>
        </h3>
        <p>
          Xora is permitted to revise these Terms at any time as it sees fit,
          and by using this Website you are expected to review these Terms on a
          regular basis.
        </p>
        <h3>
          <a id="_mga5byfexgw\"></a>
          <strong>12. Governing Law &amp; Jurisdiction</strong>
        </h3>
        <p>
          These Terms will be governed by and interpreted in accordance with the
          laws of India, and you submit to the non-exclusive jurisdiction of the
          state and federal courts located in Mumbai, India for the resolution
          of any disputes.
        </p>
        <h3>
          <a id="_cq3ru84g5s2w\"></a>
          <strong>13. Contact Information</strong>
        </h3>
        <p>For any questions about these Terms, please contact us at:</p>
        <p>
          <strong>
            Xora Business Solutions Private Limited
            <br />
          </strong>
          D-1203, Ciana Towers, Wadhwa Atmosphere,
          <br />
          Mumbai 400080, India
          <br />
          Email: uday.sathe@getxora.com
          <br />
          Phone: +1-979-739-6032
        </p>
      </div>
    </>
  );
};

export default Terms;
