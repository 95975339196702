import React from "react";

const ContactUs = () => {
  return (
    <>
      <div style={{maxWidth: '750px', margin: '0 auto'}}>
        <a id="kix.3h6148lg8e9p"></a>
        <h2>
          <a id="_k8470qorhknw"></a>
          <strong>
            Contact Us <br />
            <br />
          </strong>
          We're here to help! Whether you have a question about our services,
          need assistance, or just want to give feedback, we’re all ears. Reach
          out to us through any of the methods below.
        </h2>
        <h3>
          <a id="_zf0p5py6ibwa"></a>
          <strong>Email Us</strong>
        </h3>
        <p>Feel free to drop us an email at:</p>
        <p>
          <strong>uday.sathe@getxora.com</strong>
        </p>
        <p>We strive to respond to all emails within 24 hours.</p>
        <h3>
          <a id="_z413slmvh29a"></a>
          <strong>Call Us</strong>
        </h3>
        <p>You can also reach us by phone at:</p>
        <p>
          <strong>+1-979-739-6032</strong>
        </p>
        <p>Our phone lines are open from Monday to Friday, 9 AM to 6 PM IST.</p>
        <h3>
          <a id="_3dwixs7kh86l"></a>
          <strong>Registered Office Address:</strong>
        </h3>
        <p>
          Xora Business Solutions Private Limited
          <br />
          D-1203, Ciana Towers, Wadhwa Atmosphere,
          <br />
          Mumbai 400080, India
        </p>
        <p>
          <strong>Operational Address:</strong>
        </p>
        <p>
          Xora Business Solutions Private Limited
          <br />
          D-1203, Ciana Towers, Wadhwa Atmosphere,
          <br />
          Mumbai 400080, India
        </p>
        <h3>
          <a id="_40uvul484tts"></a>
          <strong>Connect with Us</strong>
        </h3>
        <p>
          Follow us on our social media channels to stay updated with the latest
          news and offers:
        </p>
        <p>
          <a href="https://www.facebook.com/profile.php?id=61559091500747">
            Facebook
          </a>{" "}
          | <a href="https://www.instagram.com/getxora/">Instagram</a> |{" "}
          <a href="https://x.com/getxora">Twitter</a>
        </p>
      </div>
    </>
  );
};

export default ContactUs;
