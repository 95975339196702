import React, { useEffect } from "react";
import { formatAmount } from "../../../utils/helper";

export default function InvoiceAmountDisplay({ formValue, onErrorChange }) {
  const amount = formValue?.invoiceAmount?.subTotalAmount || 0;
  const exceedsThreshold = amount > 1000000;

  useEffect(() => {
    if (onErrorChange) {
      onErrorChange(exceedsThreshold);
    }
  }, [exceedsThreshold, onErrorChange]);

  return (
    <>
      <div>
        <input
          type="text"
          placeholder="Rs. 25000"
          value={`₹ ${formatAmount(amount)}`}
          readOnly
          // style={{ border: "1px solid #e7e8ea", padding: "10px", width: "180px" }}
        />
      </div>
      {exceedsThreshold && (
        <div className="error error_para">Amount can't Exceeds ₹10 Lakhs</div>
      )}
    </>
  );
}
