import React, { useEffect, useState } from "react";
import "./Tabs.css";
import { useLocation } from "react-router-dom";
import {
  getMembersByBusinessIdService,
  getTasksList,
  createTask,
  UpdateTask,
  DeleteTask,
  getMembersbyProjectIdService,
} from "../../../services";
import ReactSelect from "react-select";
import TableDateComponent from "../projectManagement/TableDateComponent";
import useToast from "../../../utils/customHooks/useToast";
import DeleteIcon from "./../../../assets/dashboard/delete.svg";
import DeleteModal from "../../../modals/DeleteModal";
import CommonButton from "../../../components/common/CommonButton";
import Loader from "../../../components/common/Loader";

const Tasks = ({ isGlobal, selectedProject }) => {
  let projectDetails = JSON.parse(localStorage.getItem("projectDetail2"));
  let authUserData = JSON.parse(localStorage.getItem("authUserData"));
  const [tasks, setTasks] = useState([]);
  const showToast = useToast();
  const location = useLocation();
  const { id } = location.state || {};
  
  const [taskLoader, setTaskLoader] = useState({});


  const token = localStorage.getItem("token");
  const [dropDownData, setDropDownData] = useState([]);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [taskToDeleteIndex, setTaskToDeleteIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [dueDateErrors, setDueDateErrors] = useState(
    Array(tasks.length).fill("")
  );
  const [taskTitleErrors, setTaskTitleErrors] = useState(
    Array(tasks.length).fill("")
  );

  // Define sharing options
  const sharingOptions = [
    { value: 1, label: "Me Only" },
    { value: 2, label: "Team" },
    { value: 3, label: "Team + Client" },
  ];

  useEffect(() => {
    const fetchMembersData = async () => {
      try {
        const authUser = {
          firstName: authUserData?.firstName,
          lastName: authUserData?.lastName,
          email: authUserData?.emailId,
          label: "Login",
        };

        const data = await getMembersbyProjectIdService(`?projectId=${projectDetails.id}`)
        // const data = await getMembersByBusinessIdService(
        //   authUserData?.businessDetail?.businessId
        // );
        const membersData = data?.data?.responseData?.members || [];
        const membersList = membersData.map((member) => ({
          firstName: member.firstName,
          lastName: member.lastName,
          email: member.email,
          label: "Member",
        }));

        const clientList = (projectDetails?.clients || []).map((client) => ({
          firstName: client.firstName,
          lastName: client.lastName,
          email: client.email,
          label: "Client",
        }));

        const combinedData = [authUser, ...membersList, ...clientList];
        setDropDownData(combinedData);
      } catch (error) {
        console.error("Error fetching members or clients data:", error);
      }
    };
    fetchMembersData();
  }, []);

  const fetchTaskData = async () => {
    setIsLoading(true)
    const projectId = projectDetails?.id || "";
    const businessId = authUserData?.businessDetail?.businessId;

    let query = `?businessId=${businessId}`;
    if (!isGlobal || (isGlobal && selectedProject.value)) {
      const effectiveProjectId = isGlobal ? selectedProject.value : projectId;
      query += `&projectId=${effectiveProjectId}`;
    }

    const getList = await getTasksList(query, token);
    setTasks(getList?.data?.responseData?.data);
    setIsLoading(false)
  };

  useEffect(() => {
    fetchTaskData();
  }, [id, selectedProject]);

  function checkValidation(index, field, value) {
    const newDueDateErrors = [...dueDateErrors];
    const newTaskTitleErrors = [...taskTitleErrors];

    if (field === "dueDate") {
      if (value === "") {
        newDueDateErrors[index] = "Due Date is required.";
      } else {
        newDueDateErrors[index] = "";
      }
    }

    if (field === "taskTitle") {
      if (value === "") {
        newTaskTitleErrors[index] = "Task Title is required.";
      } else {
        newTaskTitleErrors[index] = "";
      }
    }

    setTaskTitleErrors(newTaskTitleErrors);
    setDueDateErrors(newDueDateErrors);
  }

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#f0f0f0", // Dropdown background color
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#c4c4c4" : "#f0f0f0", // Background color when hovered
      color: "#000", // Text color
    }),
  };

  const handleNewTaskChange = (index, field, value) => {
    // Update the tasks array
    const updatedTasks = [...tasks];
    if (field === "assingTo") {
      updatedTasks[index] = {
        ...updatedTasks[index],
        [field]: value
          ? {
              firstName: value.firstName,
              lastName: value.lastName,
              email: value.value,
            }
          : {},
      };
    } else {
      updatedTasks[index] = { ...updatedTasks[index], [field]: value };
    }

    // Call validation for the current field
    checkValidation(index, field, value);

    // If "Me Only" is selected, auto-assign to the logged-in user
    if (field === "sharing" && value === 1) {
      updatedTasks[index].assingTo = {
        firstName: authUserData?.firstName,
        lastName: authUserData?.lastName,
        email: authUserData?.emailId,
      };
    }

    setTasks(updatedTasks);
  };

  const addNewTask = () => {
    const newTask = {
      id: null,
      taskTitle: "",
      taskDescription: "",
      assingTo: {},
      dueDate: new Date().toISOString(),
      projectId: id,
      sharing: 1,
      taskStatus: 1,
    };

    if (newTask.sharing === 1) {
      newTask.assingTo = {
        firstName: authUserData?.firstName,
        lastName: authUserData?.lastName,
        email: authUserData?.emailId,
      };
    }

    setTasks([...tasks, newTask]);
  };

  const handleSaveTask = async (index) => {
    const task = tasks[index];

    // Set loader for the current task
    setTaskLoader((prev) => ({ ...prev, [index]: true }));
   
    if (task?.taskTitle === "" || task?.dueDate === "") {
        if (
            (taskTitleErrors[index] && taskTitleErrors[index] !== "") ||
            taskTitleErrors[index] === undefined ||
            (dueDateErrors[index] && dueDateErrors[index] !== "") ||
            dueDateErrors[index] === undefined
        ) {
            setTaskLoader((prev) => ({ ...prev, [index]: false }));
            showToast("Please fill all the required fields", "error");
            return;
        }
    }

    const params = {
        projectName: selectedProject?.label || projectDetails?.projectName,
        sharing: task.sharing,
        assingTo: task.assingTo,
        dueDate: task.dueDate,
        taskDescription: "",
        taskTitle: task.taskTitle,
        taskStatus: 6,
        projectId: id || selectedProject?.value,
        userId: authUserData?.userId
    };

    try {
        if (task.id) {
            await UpdateTask(params, task.id, token);
            showToast("Task updated successfully:", "success");
        } else {
            await createTask(params, token);
            showToast("Task created successfully", "success");
        }

        fetchTaskData();
    } catch (error) {
        console.error("Error saving task:", error);
    } finally {
        // Reset loader for the current task
        setTaskLoader((prev) => ({ ...prev, [index]: false }));
    }
};


  const confirmDeleteTask = (index) => {
    setTaskToDeleteIndex(index);
    setShowDeletePopup(true);
  };

  const handleDeleteTask = async () => {
    const newTasks = [...tasks];
    const taskToDelete = newTasks[taskToDeleteIndex];

    try {
      await DeleteTask(taskToDelete.id, token);
      newTasks.splice(taskToDeleteIndex, 1);
      setTasks(newTasks);
      setShowDeletePopup(false);
      showToast("Task deleted successfully", "success");
    } catch (error) {
      console.error("Error deleting task:", error);
    }
  };

  const handleCheckboxChange = async (index, checked) => {
    const task = tasks[index];
    const newStatus = checked ? 11 : 6;
    const params = {
      projectName: selectedProject?.label || projectDetails?.projectName,
      sharing: task.sharing,
      assingTo: task.assingTo,
      dueDate: task.dueDate,
      taskDescription: "",
      taskTitle: task.taskTitle,
      taskStatus: newStatus,
      projectId: id || selectedProject.value,
    };

    try {
      await UpdateTask(params, task.id, token);
      const updatedTasks = [...tasks];
      updatedTasks[index] = { ...updatedTasks[index], taskStatus: newStatus };
      showToast("Task Updated successfully", "success");
      setTasks(updatedTasks);
    } catch (error) {
      console.error("Error updating task status:", error);
    }
  };

  const formattedOptions = Array.from(
    new Map(
      dropDownData
        .filter((person) => person?.email && (person?.firstName || person?.lastName))
        .map((person) => [person.email, person]) // Use email as the unique key
    ).values()
  ).map((person) => ({
    value: person.email,
    label: (
      <div className="tabs_dropdown-option">
        <div className="name">{`${person.firstName || ''} ${person.lastName || ''}`}</div>
        <div className="email">{person.email}</div>
      </div>
    ),
    firstName: person.firstName,
    lastName: person.lastName,
  }))
  // ?.filter((person) => person?.email && (person?.firstName || person?.lastName))
  // .map((person) => ({
  //   value: person?.email,
  //   label: (
  //     <div className="tabs_dropdown-option">
  //       <div className="name">{`${person?.firstName || ''} ${person?.lastName || ''}`}</div>
  //       <div className="email">{person?.email}</div>
  //     </div>
  //   ),
  //   firstName: person.firstName,
  //   lastName: person.lastName,
  // }));

  return (
    <div className="task_table_div">
      {isLoading && (
        <div className="loader-overlay">
          <Loader type="2" />
        </div>
      )}
      <table className="project_table dealroom_table task_table" style={{marginTop:"20px"}}>
        <thead>
          <tr>
            <th  style={{ width: "5%"}}></th>
            <th  style={{ width: "15%" }}>Task Name</th>
            <th  style={{ width: "10%" }}>Project Name</th>
            <th  style={{ width: "15%" }}>Shared With</th>
            <th  style={{ width: "20%" }}>Assigned To</th>
            <th  style={{ width: "15%" }}>Due Date</th>
            <th  style={{ width: "12%" }}></th>
          </tr>
        </thead>
          <tbody >
          {tasks.map((task, index) => (

            <tr key={index}>
              <td>
                <input
                  type="checkbox"
                  checked={task.taskStatus === 11}
                  onChange={(e) =>
                    handleCheckboxChange(index, e.target.checked)
                  }
                />
              </td>
              <td>
                  <input
                      type="text"
                      placeholder="Task Title"
                      style={{
                          border: 'none',
                          background: 'none',
                          textDecoration: task.taskStatus === 11 ? 'line-through' : 'none',
                          color: task.taskStatus === 11 ? 'gray' : 'black'
                      }}
                      value={task.taskTitle}
                      onChange={(e) =>
                          handleNewTaskChange(index, "taskTitle", e.target.value)
                      }
                      maxLength={50}
                  />
                  {taskTitleErrors[index] && (
                      <div className="error">{taskTitleErrors[index]}</div>
                  )}
              </td>

              <td>
              {/* <div> */}
                  <input
                    type="text"
                    style={{border:'none', background: 'none'}}
                    placeholder="Project"
                    value={task?.projectName || projectDetails.projectName}
                    onChange={(e) =>
                      handleNewTaskChange(index, "project", e.target.value)
                    }
                  />
                {/* </div> */}
              </td>
              <td>
                <select
                  value={task.sharing}
                  onChange={(e) =>
                    handleNewTaskChange(
                      index,
                      "sharing",
                      parseInt(e.target.value)
                    )
                  }
                  className="tabs_dropdown"
                >
                  {sharingOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <ReactSelect
                  value = {
                    task?.assingTo
                      ? {
                          value: task?.assingTo.email || task?.assingTo.emailId, // Checks both 'email' and 'emailId'
                          label: `${task?.assingTo?.firstName || task?.assingTo?.name} ${task?.assingTo?.lastName || ''}`.trim(),
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleNewTaskChange(index, "assingTo", selectedOption)
                  }
                  options={formattedOptions}
                  className="styled-select"
                  styles={customStyles}
                  placeholder="Select Assignee"
                />
              </td>
              <td>
                <div className="bordered-date-picker">
                  <TableDateComponent
                    date={task.dueDate}
                    onChange={(e) =>
                      handleNewTaskChange(index, "dueDate", e.target.value)
                    }
                  />
                </div>
                {dueDateErrors[index] && (
                  <div className="error">{dueDateErrors[index]}</div>
                )}
              </td>
              <td className="save_btn_td">
                {/* <button onClick={() => handleSaveTask(index)}>
                  {task.id ? "Update" : "Save"}
                </button> */}
                <CommonButton
                    label={task.id ? "Update" : "Save"}
                    icon=""
                    fontSize="14px"
                    fontWeight="700"
                    className="dash_common_btn"
                    isLoad={taskLoader[index] || false}
                    onClick={() => handleSaveTask(index)}
                />

                <img
                  src={DeleteIcon}
                  style={{ width: "22px", height: "24px", cursor: "pointer" }}
                  alt="Delete"
                  onClick={() => confirmDeleteTask(index)}
                />
              </td>
            </tr>
          ))}

            <tr>
              <td colSpan={6} className="add_task_td">
                <p onClick={addNewTask}><span>+</span> Add Task</p>
              </td>
            </tr>
          </tbody>
      </table>
      <div className="tasks-container">
        
        <DeleteModal
          title={"Are you sure you want to delete this task?"}
          isOpen={showDeletePopup}
          onClose={() => setShowDeletePopup(false)}
          onSure={handleDeleteTask}
        />
      </div>
    </div>
  );
};

export default Tasks;
