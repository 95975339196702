import { useEffect, useState } from "react";
import { getSignersServices, getInvoiceList } from "../../services";
import { clientformatDate, formatAmount } from "../../utils/helper";
import { _contractStatus } from "../../utils/constant";
import threeDot from '../../assets/logos/three-dot.svg';
import { invoiceFilterStatus } from "../../utils/constant";
import ActionModalComponent from "./ActionModalComponent";

const ClientOverviewComponent = ({ selectedProject }) => {
  const userDetail = JSON.parse(localStorage.getItem("authUserData")) || null;
  const token = localStorage.getItem("token");
  const [list, setList] = useState([]);
  const [list2, setList2] = useState([]);
  const [pageLoader, setPageLoader] = useState(true);
  const [pageLoader2, setPageLoader2] = useState(true);
  const [activeDealroom, setActiveDealroom] = useState({});
  const [activeInvoice, setActiveInvoice] = useState({});

  const fetchDealrooms = async () => {
    if (!userDetail?.businessDetail?.businessId || !selectedProject?.id) return;
    const params = `?businessId=${userDetail.businessDetail.businessId}&projectId=${selectedProject.id}`;
    try {
      const { data } = await getSignersServices(params);
      if (data?.status) {
        setList(data.responseData?.data || []);
      }
      setPageLoader(false)
    } catch (error) {
      console.error('Error fetching signers:', error);
      setPageLoader(false)
    }
  };



  const fetchDealrooms2 = async () => {
    if (!userDetail?.businessDetail?.businessId || !selectedProject?.id) return;
    const params = `?businessId=${userDetail.businessDetail.businessId}&projectId=${selectedProject.id}`;
    try {
      const { data } = await getInvoiceList(params, token);
      if (data?.status) {
          setList2(data.responseData?.data || []);
      }
      setPageLoader2(false)
    } catch (error) {
      console.error('Error fetching signers:', error);
      setPageLoader(false)
    }
  };


  useEffect(() => {
    fetchDealrooms();
    fetchDealrooms2();
  }, [selectedProject]);



  const getStatusText = (statusId) => {    
    const status = invoiceFilterStatus.find(status => status.id === statusId);    
    return status ? { text: (status?.value)?.toLowerCase(), color: status?.color } : { text: "-", color: "#000000" };
  };
  const capitalizeFirstLetter = (text) => {
    if (!text) return '';
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  function formatDate(dateStr) {
    const options = { year: 'numeric', month: 'long', day: '2-digit' };
    return new Date(dateStr).toLocaleDateString('en-US', options);
  }


    return (
        <div>
            <h3>Pending Approvals</h3>
            <div className="table-responsive">
            <table className="project_table dealroom_table">
              <thead>
                <tr>
                  <th className="text-start ps-5">S.No.</th>
                  <th className="text-start">Created</th>
                  <th className="text-start">Title</th>
                  <th className="text-start">Status</th>
                  <th className="text-start">Sent to</th>
                  <th className="text-start">Requested By</th>
                  <th className="text-start">Action</th>
                </tr>
              </thead>
              <tbody>
                {list.map((project, index) => (
                  <tr key={project.id || index}>
                    <td className="text-start ps-5">{index + 1}</td>
                    <td className="text-start">
                      {clientformatDate(new Date(project.created_at).toISOString().split("T")[0])}
                    </td>
                    <td className=" dealroom_table_title text-start">
                      <span onClick={() => window.open(`/view-template?dealroomId=${project.id}`, "_blank")}>
                        {project.documentTitle}
                      </span>
                    </td>
                    <td className="text-start">
                      <p className="phases text-center ms-0" style={{ backgroundColor: _contractStatus[project.status]?.color }}>
                        {_contractStatus[project.status]?.value}
                      </p>
                    </td>
                    <td className="text-start">
                      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        {project.signers.map((signer, idx) => (
                          <div key={idx}>
                            <span style={{ color: _contractStatus[signer.status]?.color }}>
                              {_contractStatus[signer.status]?.value}
                            </span> : {signer.name}
                          </div>
                        ))}
                      </div>
                    </td>
                    <td className="text-start">{userDetail?.firstName} {userDetail?.lastName}</td>
                    <td onClick={() => setActiveDealroom(project)} className="text-start" style={{ cursor: 'pointer' }}>
                      <img src={threeDot} alt="three dot" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>





            <h3 className="mt-5">Pending Invoices</h3>
            <div className="table-responsive">
            <table className="project_table dealroom_table">
              <thead>
                <tr>
                  <th className="text-start ps-5">S.No.</th>
                  <th className="text-start">Date</th>
                  <th className="text-start">Title</th>
                  <th className="text-start">Amount</th>
                  <th className="text-start">Status</th>
                  <th className="text-start">Sent By</th>
                  <th className="text-start">Record Amount</th>
                  <th className="text-start">Due Date</th>
                  <th className="text-start">Actions</th>
                </tr>
              </thead>
              <tbody>
                {list?.length === 0 ? (
                  <tr>
                    <td colSpan="8" className="no-data-message">
                      No invoices available.
                    </td>
                  </tr>
                ) 
                 : (
                  list2.map((invoice, index) => {
                    const { text, color } = getStatusText(invoice?.status);
                    
                    // Calculate the total offline records amount for the current invoice
                    const offlineRecords = invoice.offlineRecords || [];
                    const totalOfflineAmount = offlineRecords.reduce((sum, record) => sum + record.receiveAmount, 0);
                    
                    return (
                      <tr key={invoice?.id}>
                        <td className="text-start ps-5">{index + 1}</td>
                        <td className="text-start">{formatDate(invoice.created_at)}</td>
                        <td className="text-start">{invoice.title}</td>
                        <td className="text-start">₹ {formatAmount(invoice?.invoiceAmount?.totalAmount)}</td>
                        <td className="text-start" style={{ color: color }}>{capitalizeFirstLetter(text)}</td>
                        <td className="text-start">{invoice.createBy}</td>
                        <td className="text-start">{formatAmount(totalOfflineAmount)}</td>
                        <td className="text-start">{formatDate(invoice.dueDate)}</td>
                        <td className="text-start" style={{ cursor: 'pointer' }}>
                          <span  onClick={() => setActiveInvoice(invoice)}>
                            <img src={threeDot} alt="three dot" />
                          </span>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
          </table>
          </div>
          </div>

        {activeDealroom.id ? <ActionModalComponent
                                     _data={activeDealroom}
                                    title={activeDealroom.documentTitle}
                                    onClose={() => setActiveDealroom({})}
                                    url={activeDealroom.id}
                                    viewType="DEALROOM" /> : ''}

        {activeInvoice.id ? <ActionModalComponent
                                    title={activeInvoice.title}
                                    onClose={() => setActiveInvoice({})}
                                    url={activeInvoice.pdfRecord.pdfFile}
                                    viewType="INVOICE" /> : ''}

          
        </div>
    )
}

export default ClientOverviewComponent;