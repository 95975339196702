import React from 'react';
import Loader from './Loader';

const CommonButton = ({ 
  onClick, 
  width, 
  label, 
  icon, 
  fontSize, 
  fontWeight ,
  isLoad,
  type,
  isTransparent,
  isIconBack=false,
  className='',
}) => {

  const handleButtonClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const buttonStyle = {
    width,
    fontSize
  };

  const innerStyle = {
    fontSize,
    fontWeight
  };

  const disable = {
    cursor: 'none',
    pointerEvents: 'none'
  }


  return (
      <button
      className={`${isTransparent ? 'tranparent-button' : 'common-button'} shadow  ${isLoad ? '_disable_': ''} ${className !== '' ? className : ''}`}
      style={buttonStyle}
      onClick={handleButtonClick}
      type={type}
    >
      <div className='_flex_row_ _gap_ btn-inner-content'>
        <h4 style={innerStyle}>{isLoad ? <Loader /> : ''} {label}</h4>
        {icon != "" && <img src={icon} alt='icon'/>}
      </div>
    </button>
  );
};

export default CommonButton;