import React, { useState } from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import SunEditor CSS


const TextEditor = ({val, change}) => {
    return (
      <div>
        <SunEditor
          setOptions={{
            height: 200,
            buttonList: [
              ['undo', 'redo'],
              ['font', 'fontSize', 'formatBlock'],
              ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
              ['fontColor', 'hiliteColor', 'textStyle'],
              ['removeFormat'],
              ['outdent', 'indent'],
              ['align', 'horizontalRule', 'list', 'table'],
              ['link', 'image', 'video', 'audio'],
              ['fullScreen', 'showBlocks', 'codeView'],
              ['preview', 'print'],
            ],
            // buttonList: [
            //   ['bold', 'italic', 'underline'],
            //   ['list', 'align', 'fontSize'],
            //   ['link', 'image'],
            //   ['table'],
            // ],
          }}
          onChange={change}
          defaultValue={val}
        />
      </div>
    );
};
export default TextEditor;
