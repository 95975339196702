import React, { useState, useEffect, useRef } from "react";
import "./Tabs.css"; // Import the CSS file
import {
  createStatusReport,
  deleteStatusReportService,
  getMembersByBusinessIdService,
  getStatusReportList,
} from "../../../services";
import { useLocation } from "react-router-dom";
import CommonButton from "../../../components/common/CommonButton";
import useToast from "../../../utils/customHooks/useToast";
import ReactSelect from "react-select";
import { projectStatus } from "../../../utils/constant";
import docIcon from "../../../assets/logos/doc-svgrepo-com.svg";
import pdfIcon from "../../../assets/logos/pdf-svgrepo-com.svg";
import Loader from "../../../components/common/Loader";
import arrowOpenIcon  from "../../../assets/logos/openArrowIcon.svg"
import arrowCloseIcon  from "../../../assets/logos/closeArrowIcon.svg"
import DeleteIcon from "../../../assets/dashboard/delete.svg";
import DeleteModal from "../../../modals/DeleteModal";

const styles = {
  control: (base) => ({
    ...base,
    width: "85%",
    height: "54px",
    fontSize: "14px",
    justifyContent: "center",
    border: "1px solid #e7e8ea",
    borderRadius: "8px",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#e7e8ea",
    },
  }),
  valueContainer: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  singleValue: (base, state) => ({
    ...base,
    color: state.data.color,
  }),
  menu: (base) => ({
    ...base,
    width: "85%",
    borderRadius: 5,
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    maxHeight: 3 * 40,
    overflowY: "auto",
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: "white",
    color: state.data.color,
    height: 40,
    display: "flex",
    alignItems: "center",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    transition: "transform 0.2s ease-in-out",
    transform: state.selectProps.menuIsOpen
      ? "rotate(-180deg)"
      : "rotate(0deg)",
  }),
};

const StatusReport = ({ isAdding, setIsAdding, isGlobal, selectedProject }) => {
  const projectDetails = JSON.parse(localStorage.getItem("projectDetail2"));
  const authUserData = JSON.parse(localStorage.getItem("authUserData"));
  const token = localStorage.getItem("token");
  const location = useLocation();
  const { id } = location.state || {};
  const showToast = useToast();

  const [selectedReport, setSelectedReport] = useState(null);
  const [reportData, setReportData] = useState([]);
  const [reportTitle, setReportTitle] = useState("");
  const [reportDate, setReportDate] = useState(new Date());
  const [overallStatus, setOverallStatus] = useState("");
  const [todayStatus, setTodayStatus] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [accomplishments, setAccomplishments] = useState("");
  const [risks, setRisks] = useState("");
  const [nextSteps, setNextSteps] = useState("");
  const [isAccomplishmentsOpen, setIsAccomplishmentsOpen] = useState(false);
  const [isRisksOpen, setIsRisksOpen] = useState(false);
  const [isNextStepsOpen, setIsNextStepsOpen] = useState(false);
  const [notesLoader, setNotesLoader] = useState(false);
  const [dropDownData, setDropDownData] = useState([]);
  const [overallStatusColor, setOverallStatusColor] = useState("");
  const [assingTo, setAssingTo] = useState(null); // New state for assignee
  const [isLoading, setIsLoading] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false); 
  const [activeStatusReport, setActiveStatusReport] = useState({}); 

  const fetchData = async () => {
    setIsLoading(true)
    const projectId = projectDetails?.id || "";
    const businessId = authUserData?.businessDetail?.businessId;

    let query = `?businessId=${businessId}`;
    if (!isGlobal || (isGlobal && selectedProject.value)) {
      const effectiveProjectId = isGlobal ? selectedProject.value : projectId;
      query += `&projectId=${effectiveProjectId}`;
    }

    const notesData = await getStatusReportList(query, token);
    if (notesData?.data?.status === 1) {
      setReportData(notesData?.data?.responseData?.data);
      if (notesData?.data?.responseData?.data.length > 0) {
        const firstReport = notesData?.data?.responseData?.data[0];
        setSelectedReport(firstReport);
        handleReportSelect(firstReport);
        //setSelectedReport(notesData?.data?.responseData?.data[0]);
      } else {
        setIsAdding(true); // Open form if no reports exist
      }
    }
    setIsLoading(false)
  };

  useEffect(() => {
    fetchData();
  }, [id, selectedProject, setIsAdding]);

  useEffect(() => {
    const fetchMembersData = async () => {
      try {
        const authUser = {
          firstName: authUserData?.firstName,
          lastName: authUserData?.lastName,
          email: authUserData?.emailId,
          label: "Login",
        };

        const data = await getMembersByBusinessIdService(
          projectDetails?.businessId
        );
        const membersData = data?.data?.responseData?.members || [];

        const membersList = membersData.map((member) => ({
          firstName: member.firstName,
          lastName: member.lastName,
          email: member.email,
          label: "Member",
        }));

        const clientList = (projectDetails?.clients || []).map((client) => ({
          firstName: client.firstName,
          lastName: client.lastName,
          email: client.email,
          label: "Client",
        }));

        const combinedData = [authUser, ...membersList, ...clientList];
        setDropDownData(combinedData);
      } catch (error) {
        console.error("Error fetching members or clients data:", error);
      }
    };
    fetchMembersData();
  }, []);

 // Track unique emails using a Set
  const uniqueEmails = new Set();

  const formattedOptions = dropDownData
  .filter((person) => {
    // Exclude if either firstName or lastName is missing
    if (!person.firstName || !person.lastName) return false;

    // Check if email is already in the Set
    if (uniqueEmails.has(person.email)) {
      return false; // Duplicate email, exclude from the results
    }

    // Add the email to the Set and include the person
    uniqueEmails.add(person.email);
    return true;
  })
  .map((person) => ({
    value: person.email,
    label: (
      <div className="tabs_dropdown-option">
        <div className="tabs_name">{`${person.firstName} ${person.lastName}`}</div>
        <div className="tabs_email">{person.email}</div>
      </div>
    ),
    firstName: person.firstName,
    lastName: person.lastName,
  }));

  useEffect(() => {
    if (isAdding) {
      resetForm();
    }
  }, [isAdding]);

  const resetForm = () => {
    setSelectedReport(null);
    setReportTitle("");
    setReportDate("");
    setOverallStatus("");
    setOverallStatusColor("");
    setTodayStatus("");
    setAttachments([]);
    setAccomplishments("");
    setRisks("");
    setNextSteps("");
    setAssingTo(null); // Reset assignee
  };
  const getMimeType = (type) => {
    switch (type) {
      case 1:
        return "image/png";
      case 2:
        return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      case 3:
        return "application/pdf";
      default:
        return "application/octet-stream";
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (!reportTitle || !reportDate || !overallStatus || !todayStatus) {
      showToast("All fields are mandatory.", "error");
      return;
    }

    setNotesLoader(true);
    const formData = new FormData();

    formData.append("projectName", projectDetails?.projectName);
    formData.append("sharing", 1);
    formData.append("reportDate", new Date(reportDate).toISOString());
    formData.append("projectStatus", overallStatus);
    formData.append("projectStatusColor", overallStatusColor);
    formData.append("reportTitle", reportTitle);
    formData.append("reportStatus", todayStatus);
    formData.append("accomplishments", accomplishments);
    formData.append("risks", risks);
    formData.append("nextSteps", nextSteps);
    formData.append("projectId", id);
    formData.append("name", assingTo?.firstName + " " + assingTo?.lastName);
    formData.append("emailId", assingTo?.value);
    formData.append("profilePhoto", "");

    // for (let index = 0; index < attachments.length; index++) {
    //   const element = attachments[index];
    //   formData.append('imageFiles', element.file);
    // }
    if (attachments.length > 0) {
      attachments.forEach((element) => {
        formData.append(
          "imageFile",
          element.file,
          `${element.file.name};type=${getMimeType(element.type)}`
        );
      });
    }
    try {
      const responseValue = await createStatusReport(id, formData, token);
      showToast("Status report created successfully.", "success");
      const projectId = projectDetails?.id || "";
      const businessId = authUserData?.businessDetail?.businessId;

      let query = `?businessId=${businessId}`;
      if (!isGlobal || (isGlobal && selectedProject.value)) {
        const effectiveProjectId = isGlobal ? selectedProject.value : projectId;
        query += `&projectId=${effectiveProjectId}`;
      }
      const notesData = await getStatusReportList(
        query,
        token
      );
      if (notesData?.data?.status === 1) {
        setReportData(notesData?.data?.responseData?.data);
        setSelectedReport(responseValue?.data?.responseData);
      }
    } catch (error) {
      showToast("Error creating status report.", "error");
    } finally {
      setNotesLoader(false);
      setIsAdding(false);
    }
  };
  const handleReportSelect = (report) => {
    setIsAdding(false);
    setSelectedReport(report);
    setReportTitle(report?.reportTitle);
    setReportDate(report?.reportDate);
    setOverallStatus(report?.projectStatus);
    setTodayStatus(report?.reportStatus || "");
    setAccomplishments(report?.accomplishments);
    setOverallStatusColor(report?.projectStatusColor);
    setRisks(report?.risks);
    setNextSteps(report?.nextSteps);

    // Assuming report.imageFile is an array of URLs or files
    const urls = report?.imageFile || []; // URLs from API
    setAttachments([...urls]); // Combine URLs and local files

    const nameParts = report?.name ? report?.name?.split(" ") : ["", ""];
    const assignee = {
      firstName: nameParts[0],
      lastName: nameParts[1] || "",
      email: report?.emailId,
      label: report?.name || "Unknown",
    };
    setAssingTo(assignee);
  };

  function timeAgo(dateStr) {
    const now = new Date();
    const date = new Date(dateStr);
    const seconds = Math.floor((now - date) / 1000);

    let interval = Math.floor(seconds / 31536000); // Years
    if (interval >= 1)
      return interval + " year" + (interval > 1 ? "s" : "") + " ago";

    interval = Math.floor(seconds / 2592000); // Months
    if (interval >= 1)
      return interval + " month" + (interval > 1 ? "s" : "") + " ago";

    interval = Math.floor(seconds / 86400); // Days
    if (interval >= 1)
      return interval + " day" + (interval > 1 ? "s" : "") + " ago";

    interval = Math.floor(seconds / 3600); // Hours
    if (interval >= 1)
      return interval + " hour" + (interval > 1 ? "s" : "") + " ago";

    interval = Math.floor(seconds / 60); // Minutes
    if (interval >= 1)
      return interval + " minute" + (interval > 1 ? "s" : "") + " ago";

    return "just now"; // Less than a minute
  }

  const handleStatusChange = (selectedOption) => {
    setOverallStatus(selectedOption.value);
    setOverallStatusColor(selectedOption.color); // Set the color based on selected option
  };

  const handleAssigneeChange = (selectedOption) => {
    setAssingTo(selectedOption); // Update assignee selection
  };

  const fileInputRef = useRef(null);

  const openImageFile = (files) => {
    const newFiles = Array.from(files);
    const validFiles = newFiles.map((file) => {
      const fileType = getFileType(file); // Function to determine the file type
      return { file, type: fileType };
    });

    setAttachments((prevAttachments) => [...prevAttachments, ...validFiles]);
    fileInputRef.current.value = "";
  };

  const getFileType = (file) => {
    if (file.type.startsWith("image/")) return 1; // Image
    if (file.type === "application/pdf") return 3; // PDF
    if (
      file.type === "application/msword" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    )
      return 2; // DOCX
    return 4; // Folder or any other type
  };

  useEffect(() => {
    if (attachments) {
    }
  }, [attachments]);

  const renderPreview = (file, index) => {
    let previewContent;
    
    if (typeof file === "string") {
      // Check if file is a URL
      const fileExtension = file.split(".").pop();

      if (
        fileExtension === `net/xora-dev/${projectDetails?.projectName}_png` ||
        fileExtension === `net/xora-dev/${projectDetails?.projectName}_jpg` ||
        fileExtension === `net/xora-dev/${projectDetails?.projectName}_jpeg`
      ) {
        previewContent = (
          <img className="thumb" src={file} alt={`Attachment ${index}`} />
        );
      } else if (
        fileExtension === `net/xora-dev/${projectDetails?.projectName}_pdf`
      ) {
        previewContent = <img className="thumb" src={pdfIcon} alt="PDF Icon" />;
      } else {
        previewContent = <img className="thumb" src={docIcon} alt="DOC Icon" />;
      }
    } else {
      const fileURL = URL.createObjectURL(file.file); // For local files
      const fileType = file?.file?.type?.split("/")[0];

      if (fileType === "image") {
        previewContent = (
          <img className="thumb" src={fileURL} alt={file?.file?.name} />
        );
      } else if (file?.file?.type === "application/pdf") {
        previewContent = <img className="thumb" src={pdfIcon} alt="PDF Icon" />;
      } else if (
        file?.file?.type === "application/msword" ||
        file?.file?.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        previewContent = <img className="thumb" src={docIcon} alt="DOC Icon" />;
      } else {
        previewContent = <div className="thumb">File</div>;
      }
    }

    
    return (
      <li key={index}>
        <div className="img-wrap">
          <span className="close" onClick={() => removeAttachment(index)}>
            &times;
          </span>
          {previewContent}
        </div>
      </li>
    );
  };
  
  const formattedDate = reportDate instanceof Date 
  ? reportDate.toISOString().split('T')[0] 
  : (typeof reportDate === 'string' && reportDate.includes('T') 
      ? reportDate.split('T')[0] 
      : reportDate);
  const removeAttachment = (index) => {
    setAttachments((prevAttachments) =>
      prevAttachments.filter((_, i) => i !== index)
    );
  };


  const handleDelete = async () => {
    if (activeStatusReport && activeStatusReport.id) {
      await deleteStatusReportService(activeStatusReport.id, token);
      setShowDeletePopup(false);
      setActiveStatusReport({})
      fetchData();
    }
  };


  const handleCancel = () => {
  
    const _firstReport = reportData[0];
    handleReportSelect(_firstReport);
  }

  return (
    <div className="status_report_main">

          {showDeletePopup && (
            <DeleteModal
              title={"Are you sure you want to delete this status report?"}
              isOpen={showDeletePopup}
              onClose={() => setShowDeletePopup(false)}
              onSure={handleDelete}
            />
          )}


      {isLoading === true ? (
        <div className="loader-overlay">
          <Loader type="2" />
        </div>
      ) : (
        <>
        <div style={{padding:'none'}} className="controls-status _flex_gap_30"></div>
          <div className="content-container">
          <div className="sidebar">
            {reportData.map((report) => (
              <div
                key={report.id}
                className="tabs_reportItem"
                style={{
                  backgroundColor:
                    selectedReport && selectedReport.id === report.id
                      ? "#e0f7fa"
                      : "#fff",
                  fontWeight:
                    selectedReport && selectedReport.id === report.id
                      ? "bold"
                      : "normal",
                }}
                onClick={() => handleReportSelect(report)}
              >
                <h4 className="tabs_reportTitle">{report?.reportTitle} <span>Sent</span></h4>
                <p>{timeAgo(report.created_at)}</p>
                <img
                      src={DeleteIcon}
                      style={{
                        width: "15px",
                        height: "15px",
                        cursor: "pointer",
                        position: 'absolute',
                        right: '12px',
                        top: '12px'
                      }}
                      alt="Delete"
                      onClick={() => (setShowDeletePopup(true), setActiveStatusReport(report))}
                    />
              </div>
            ))}
          </div>
          <div className="main-area status_main_area">
            <div className="tabs_reportDetailsContent">
              <h2 className="status_report_heading">
              <input
                  type="text"
                  value={reportTitle}
                  onChange={(e) => setReportTitle(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.target.placeholder = ''; // Clear the placeholder
                    }
                  }}
                  className="tabs_editableTitle"
                  placeholder="Enter Title"
                  disabled={!!selectedReport} // Make it non-editable if a report is selected
                />
                {/* <CommonButton
                  label="Send"
                  fontSize="14px"
                  fontWeight="700"
                  icon=""
                  className={`dash_common_btn`}
                  // onClick={handleAddClick}
                  // width="100px"
                /> */}
              </h2>
              {(isAdding  || selectedReport) && (
                <form className="tabs_form" onSubmit={handleSave}>
                  <div className="tabs_formGroup">
                    <label>Project:</label>
                    <input
                      className="tabs_input"
                      defaultValue={projectDetails?.projectName}
                      disabled
                    >
                      {/* <option>{projectDetails?.projectName}</option> */}
                    </input>
                  </div>
                  <div className="tabs_formGroup" style={{justifyContent:'end'}}>
                  <label>Select Assignee:</label>
                    <ReactSelect
                      value={
                        assingTo
                          ? {
                              value: assingTo.email,
                              label: `${assingTo.firstName} ${assingTo.lastName}`,
                            }
                          : null
                      }
                      options={formattedOptions}
                      onChange={handleAssigneeChange}
                      className="tabs_styled-select"
                      placeholder="Select Assignee"
                      isDisabled={!!selectedReport}
                    />
                  </div>

                  <div className="tabs_formGroup">
                    <label>Report Date:</label>
                    <input
                      type="date"
                      className="tabs_input"
                      value={formattedDate}
                      onChange={(e) => setReportDate(e.target.value)}
                      disabled={!!selectedReport}
                    />
                  </div>

                  <div className="tabs_formGroup">
                    <label>Overall Project Status:</label>
                    <ReactSelect
                      options={projectStatus}
                      // style={{width:' calc(100% - -214px - 200px)'    }}
                      className="tabs_styled-select"
                      styles={styles}
                      placeholder="Select Project Status"
                      onChange={handleStatusChange}
                      isDisabled={!!selectedReport}
                      // value={projectStatus.find(
                      //   (option) => option.value === overallStatus
                      // )}
                      value={
                        overallStatus
                          ? projectStatus.find((option) => option.value === overallStatus)
                          : null // Set to null if there's no valid status
                      }
                    />
                  </div>

                  <div className="tabs_formGroup">
                    <label>Today's Status:</label>
                    <textarea
                      placeholder="Enter today's status"
                      className="tabs_textArea"
                      value={todayStatus}
                      onChange={(e) => setTodayStatus(e.target.value)}
                      disabled={!!selectedReport} // Make it non-editable if a report is selected
                      maxLength="500"
                    />
                  </div>

                  <div className="tabs_formGroup">
                    <label>Attachments:</label>
                    
                    <input
                      type="file"
                      multiple
                      onChange={(e) => openImageFile(e.target.files)}
                      disabled={!!selectedReport}
                      ref={fileInputRef}
                      style={{ display: 'none' }} // Hide the default input
                      id="file-upload" // Add an ID for the label
                    />

                      { selectedReport ? ''
                      : 
                      (
                        <label htmlFor="file-upload" className="custom-file-upload">
                          Choose Files
                        </label>
                      )

                      }

                    {attachments.length > 0 && (
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          marginTop: "10px",
                        }}
                      >
                        {attachments.map((item, index) => (
                          <div
                            key={index}
                            className=""
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            <div className="thumb-Images">
                              {renderPreview(item, index)}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>


                  <div className="tabs_collapsibleSection">
                    <div
                      onClick={() =>
                        setIsAccomplishmentsOpen(!isAccomplishmentsOpen)
                      }
                      className="tabs_collapsibleHeader tabs_formGroup"
                    >
                    <label> Accomplishments {isAccomplishmentsOpen ?  (<img src={arrowOpenIcon} alt="Star Icon" />) : (<img src={arrowCloseIcon} alt="Star Icon" />)}</label>
                    </div>
                    {isAccomplishmentsOpen && (
                      <textarea
                        placeholder="Enter accomplishments"
                        className="tabs_textArea"
                        value={accomplishments}
                        onChange={(e) => setAccomplishments(e.target.value)}
                        disabled={!!selectedReport} // Make it non-editable if a report is selected
                      />
                    )}
                  </div>

                  <div className="tabs_collapsibleSection">
                    <div
                      onClick={() => setIsRisksOpen(!isRisksOpen)}
                      className="tabs_collapsibleHeader"
                    >
                    <label>Risks {isRisksOpen ?  (<img src={arrowOpenIcon} alt="Star Icon" />) : (<img src={arrowCloseIcon} alt="Star Icon" />)}</label> 
                    </div>
                    {isRisksOpen && (
                      <textarea
                        placeholder="Enter risks"
                        className="tabs_textArea"
                        value={risks}
                        onChange={(e) => setRisks(e.target.value)}
                        disabled={!!selectedReport} // Make it non-editable if a report is selected
                      />
                    )}
                  </div>

                  <div className="tabs_collapsibleSection">
                    <div
                      onClick={() => setIsNextStepsOpen(!isNextStepsOpen)}
                      className="tabs_collapsibleHeader"
                    >
                    <label> Next Steps {isNextStepsOpen ? (<img src={arrowOpenIcon} alt="Star Icon" />) : (<img src={arrowCloseIcon} alt="Star Icon" />)}</label>
                    </div>
                    {isNextStepsOpen && (
                      <textarea
                        placeholder="Enter next steps"
                        className="tabs_textArea"
                        value={nextSteps}
                        onChange={(e) => setNextSteps(e.target.value)}
                        disabled={!!selectedReport} // Make it non-editable if a report is selected
                      />
                    )}
                  </div>

                  {/* Only show buttons when creating a new report */}
                  {isAdding && (
                    <div className="contract_main_button">
                      <CommonButton
                        type="button"
                        label="Back"
                        fontSize="14px"
                        fontWeight="700"
                        className="dash_common_btn white_bg_btn"
                        icon={false}
                        onClick={handleCancel}
                      />
                      <CommonButton
                        label="Send"
                        icon=""
                        fontSize="14px"
                        fontWeight="700"
                        className="dash_common_btn"
                        isLoad={notesLoader}
                        type="submit"
                      />
                    </div>
                  )}
                </form>
              )}
            </div>
          </div>
        </div>
      </>
      )
    }
    </div>
  );
};

export default StatusReport;
