import React, { useEffect, useState, useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import filtercircle from "./../assets/dashboard/filter_cross.svg"; // Ensure the correct path to your image asset

export default function ProjectDetailsFilter({
  handleFilterClose,
  filterStatus,
  filterPriority,
  filterOwner,
  setFilterStatus,
  setFilterPriority,
  setFilterOwner,
}) {
  const wrapperRef = useRef(null);
  const [_filterStatus, _setFilterStatus] = useState([]);
  const [_filterPriority, _setFilterPriority] = useState([]);
  const [_filterOwner, _setFilterOwner] = useState([]);

  useEffect(() => {
    if (filterStatus && filterStatus.length > 0) {
      _setFilterStatus(filterStatus);
    }
  }, [filterStatus]);

  useEffect(() => {
    if (filterPriority && filterPriority.length > 0) {
      _setFilterPriority(filterPriority);
    }
  }, [filterPriority]);

  useEffect(() => {
    if (filterOwner && filterOwner.length > 0) {
      _setFilterOwner(filterOwner);
    }
  }, [filterOwner]);

  const handleCheckboxChange = (e, id, key) => {
    switch (key) {
      case "status":
        const updatedStatusFilter = _filterStatus.map((item) => {
          if (item.id === id) {
            return { ...item, isChecked: e.target.checked };
          }
          return item;
        });
        _setFilterStatus(updatedStatusFilter);
        break;
      case "priority":
        const updatedPriorityFilter = _filterPriority.map((item) => {
          if (item.id === id) {
            return { ...item, isChecked: e.target.checked };
          }
          return item;
        });
        _setFilterPriority(updatedPriorityFilter);
        break;
      default:
        const updatedOwnerFilter = _filterOwner.map((item) => {
          if (item.id === id) {
            return { ...item, isChecked: e.target.checked };
          }
          return item;
        });
        _setFilterOwner(updatedOwnerFilter);
        break;
    }
  };

  const handleSubmit = () => {
    setFilterStatus(_filterStatus);
    setFilterPriority(_filterPriority);
    setFilterOwner(_filterOwner);
    handleFilterClose();
  };

  useEffect(() => {
    // Close filter when clicking outside of the component
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        handleFilterClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleFilterClose]);

  return (
    <div ref={wrapperRef} className="project_filter_main">
      <div className="project_filter_upper">
        <h4 className="filter_popup_heading">Filter</h4>
        <img
          src={filtercircle}
          onClick={handleFilterClose}
          alt="Close filter"
        />
      </div>
      <div className="project_filter_accor">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Status</Accordion.Header>
            <Accordion.Body>
              <ul className="filter_dropdown_ul">
                {_filterStatus.map((filterItem) => (
                  <li key={filterItem.id} className="filter_dropdown_li">
                    <input
                      type="checkbox"
                      checked={filterItem.isChecked}
                      onChange={(e) =>
                        handleCheckboxChange(e, filterItem.id, "status")
                      }
                    />
                    <label>{filterItem.value}</label>
                  </li>
                ))}
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Priority</Accordion.Header>
            <Accordion.Body>
              <ul className="filter_dropdown_ul">
                {_filterPriority.map((filterItem) => (
                  <li key={filterItem.id} className="filter_dropdown_li">
                    <input
                      type="checkbox"
                      checked={filterItem.isChecked}
                      onChange={(e) =>
                        handleCheckboxChange(e, filterItem.id, "priority")
                      }
                    />
                    <label>{filterItem.value}</label>
                  </li>
                ))}
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Owner</Accordion.Header>
            <Accordion.Body>
              <ul className="filter_dropdown_ul">
                {_filterOwner.map((filterItem) => (
                  <li key={filterItem.id} className="filter_dropdown_li">
                    <input
                      type="checkbox"
                      checked={filterItem.isChecked}
                      onChange={(e) => handleCheckboxChange(e, filterItem.id)}
                    />
                    <label>{filterItem.value}</label>
                  </li>
                ))}
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="modal_btn_div project_filter_btn">
        <button
          className="_btn_transparent_ modal_div_button"
          onClick={handleFilterClose}
        >
          <span>Cancel</span>
        </button>
        <button
          className="_btn_normal_ modal_div_button"
          onClick={handleSubmit}
        >
          <span>Submit</span>
        </button>
      </div>
    </div>
  );
}
