import React from "react";
import "./styles.css";

const CustomTooltip = ({ text, children }) => {
  let className = "";
  if (text === "Delete" || text === "Review") {
    className = "tooltip-text-delete";
  } else if (text === "Review") {
    className = "tooltip-text-review";
  } else {
    className = "tooltip-text";
  }
  return (
    <span className="tooltip-container">
      {children}
      <span className={className}>{text}</span>
    </span>
  );
};

export default CustomTooltip;
