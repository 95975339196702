import { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css'
import imageIconOutline from "../../assets/dashboard/image-icon-outline.svg";
import { getUserDetailService, updateUserProfileService, updateUserProfilePhotoService } from "../../services";
import useToast from "../../utils/customHooks/useToast";

const AccountDetails = () => {
    const authUserData = JSON.parse(localStorage.getItem("authUserData")) || null;
    const token = localStorage.getItem('token');
    const showToast = useToast()

    const [userDetails, setUserDetails] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        profilePhoto: '',
    });

    const [errors, setErrors] = useState({});
    const [fileErr, setFileErr] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);


    const getUserDetail = async () => {
        try {
            let query = `/?userId=${authUserData.userId}`;
            const res = await getUserDetailService(query, token);
            if (res?.data?.status) {
                setUserDetails(res.data.responseData);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (authUserData?.userId) {
            getUserDetail();
        }
    }, []);

    const handleChange = (e) => {
        setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
        setErrors({...errors, [e.target.name]: ''})
    };




    const handleValidate = () => {
        let validate = true;

        const nameRegex = /^[A-Za-z\s]+$/;

        let _errors = {}
    
        if (!userDetails.firstName?.trim()) {
            _errors.firstName = 'First name is required';
            validate = false
        } else if (!nameRegex.test(userDetails.firstName)) {
            _errors.firstName = 'First name cannot contain special characters.';
            validate = false
        }

        if (!userDetails.lastName?.trim()) {
            _errors.lastName = 'Last name is required'
            validate = false
        } else if (!nameRegex.test(userDetails.lastName)) {
            _errors.lastName = 'Last name cannot contain special characters.'
            validate = false
        }

        setErrors(_errors);


        return validate
    }



    const handleSubmit = async () => {

        if(handleValidate()){
            let params = {
                firstName: userDetails.firstName,
                lastName: userDetails.lastName,
            }
            setIsSubmitting(true);
            try {
                const res = await updateUserProfileService(params, token);
                if (res?.data?.status) {
                    showToast(`Your profile has been successfully updated.`, "success");
                }
            } catch (error) {
                console.error(error);
            }
            setIsSubmitting(false);
        }


    };


    const handleFileChange = async (event) => {

        setFileErr('');
        const file = event.target.files[0];
        const fileSizeInMB = file.size / (1024 * 1024);
        if (fileSizeInMB > 50) {
        setFileErr('File size exceeds 50 MB. Please upload a smaller image.');
        return;
        }


   
        let query = `?userId=${authUserData?.userId}`;
        const formData = new FormData();
        formData.append('profilePhoto', file);
        updateUserProfilePhotoService(query, formData, token).then(resIn => {
            if(resIn.data.status){
            showToast(`Your profile photo has been successfully updated.`, "success");
            getUserDetail();
            }
        })
      };

    return (
        <div className="setting_inner_page">
            <h3>Account Details</h3>

            <div className="row">
                <div className="col-lg-10">
                    <div className="cm_profile_upload">
                        <div className="cm_profile_upload_inner">
                            <input value="" accept="image/*" onChange={handleFileChange} type="file" />
                            {userDetails.profilePhoto ? <img className="pmm1" src={userDetails.profilePhoto} alt="Profile" /> : <img className="pmm2" src={imageIconOutline} alt="Upload Icon" />}
                        </div>
                        
                    </div>
                    {fileErr && <p className="error-text text-center">{fileErr}</p>}
                </div>
            </div>

            <div className="row">
                <div className="col-lg-5">
                    <div className="form-group mb-4">
                        <label>First Name</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            name="firstName"
                            value={userDetails.firstName}
                            onChange={handleChange}
                        />
                        {errors.firstName && <p className="error-text">{errors.firstName}</p>}
                        
                    </div>
                </div>

                <div className="col-lg-5">
                    <div className="form-group mb-4">
                        <label>Last Name</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            name="lastName"
                            value={userDetails.lastName}
                            onChange={handleChange}
                        />
                        {errors.lastName && <p className="error-text">{errors.lastName}</p>}
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-5">
                    <div className="form-group mb-4">
                        <label>Email</label>
                        <input
                            type="text"
                            disabled
                            className="form-control"
                            placeholder="Email"
                            name="email"
                            value={userDetails.emailId}
                        />
                    </div>
                </div>

                <div className="col-lg-5">
                    <div className="form-group mb-4">
                        <label>Phone Number</label>
                        <PhoneInput
                            className="cm_my_phone_input form-control"
                            inputComponent={(props) => <input {...props} disabled />}
                            international={false}
                            countryCallingCodeEditable={false}
                            defaultCountry="IN"
                            value={`${userDetails.countryCode} ${userDetails.phoneNumber}`}/>
                       
                       
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-10 mt-5 text-end">
                    <button disabled={isSubmitting} className="btn btn-primary px-5" onClick={handleSubmit}>Save</button>
                </div>
            </div>
        </div>
    );
};

export default AccountDetails;
