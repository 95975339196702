import React from "react";
import "../projects/notesAndTasks/Tabs.css";
import { useNavigate } from "react-router-dom";

import GlobalSearch from "../../components/common/GlobalSearch";
import { useDispatch } from "react-redux";
import { logout } from "../../features/auth/authSlice";
import { persistor } from "../../store";
import FilesContent from "../projects/FilesContent";

const GlobalLibrary = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
  let authUserData = JSON.parse(localStorage.getItem("authUserData"));


  const handleLogout = () => {
    localStorage.clear();
    dispatch(logout());
    persistor.purge()
    navigate("/signin");
};


  

  return (

    <>
        <div className="dashboard_upper projectdetails_dash_upper">
            <div className="projectdetails_dash_left">
                {authUserData?.businessDetail?.logo ? <img src={authUserData?.businessDetail?.logo} className="fav_star" alt="Star Toggle"/> : ''}
                
                <h3>{authUserData?.businessDetail?.businessName}</h3>
            </div>
            <div className="projectdetails_dash_left">
                <GlobalSearch />
                <div className="logout_main_div">
                    <div className="name_intials_dash_upper">
                        {authUserData.firstName ? authUserData.firstName.charAt(0).toUpperCase() : ''}{authUserData.lastName ? authUserData.lastName.charAt(0).toUpperCase() : ''}
                    </div>
                    <div className="logout_div">
                        <p onClick={handleLogout}>Logout</p>
                    </div>
                </div>
            </div>
        </div>

        <div className="p-3 global_library">
        <FilesContent isGlobalLibrary={true} />
        </div>

    

    
        
    </>

   
  );
};

export default GlobalLibrary;
