// SignatureModal.js
import React, { useEffect, useState } from "react";
import crossIcon from "../../assets/dashboard/filter_cross.svg";
import CommonButton from "../../components/common/CommonButton";
import { generateSignatureService, invoiceRemindService } from "../../services";
import useToast from "../../utils/customHooks/useToast";

const ActionModalComponent = ({ title, onClose, url, viewType, _data}) => {
    let userDetail = JSON.parse(localStorage.getItem("authUserData")) || null;
  const [actionType, setActionType] = useState(null);
  const [linkLoader, setLinkLoader] = useState(false);
  const showToast = useToast();

  useEffect(() => {
    if (actionType === 3 || actionType === 4 || actionType === 5) {
      if(viewType == 'CR'){
        window.open(`/view-template?createRequestId=${url}`, "_blank");
      }
      if(viewType == 'DEALROOM'){
        window.open(`/view-template?dealroomId=${url}`, "_blank");
      }
      if(viewType == 'INVOICE'){
        window.open(url, "_blank");
      }

    }
  }, [actionType]);


  const callSignatureDocument = () => {
    setLinkLoader(true);
    let query = '';
    if(viewType == 'CR'){
        query = `?emailId=${userDetail.emailId}&createRequestId=${_data.id}`
    }else{
        query = `?emailId=${userDetail.emailId}&dealroomId=${_data.id}`
    }

    generateSignatureService(query).then(res => {
      if(res?.data?.status){
        window.open(res.data.responseData.invite_url, "_blank");
      }
        setLinkLoader(false);
    })
  }


  const callPaymentLink = () => {
    setLinkLoader(true);

    let params = {
        invoiceId: _data.id,
    }

    invoiceRemindService(params).then(res => {
        setLinkLoader(false);
        onClose();
        showToast(`Payment link generated successfully`, "success");

    })
  }



  return (
    <div className="signature-modal">
      <div className="modal-content">
        <div className="action-modal-wrapper">
            <div className="action-cross-icon" onClick={onClose}>
                <img src={crossIcon} alt="crossIcon" />
            </div>
            <div className="action-buttons">
                <p>{title}</p>
                <CommonButton label="View" fontSize="14px" fontWeight="700" className="dash_common_btn white_bg_btn" icon={""} onClick={() => setActionType(3)} />
                <CommonButton label="Download" fontSize="14px" fontWeight="700" className="dash_common_btn white_bg_btn" icon={""} onClick={() => setActionType(4)} />
                <CommonButton label="Print" fontSize="14px" fontWeight="700" className="dash_common_btn white_bg_btn" icon={""} onClick={() => setActionType(5)} />
                {(viewType == 'DEALROOM' || viewType == 'CR') ? <CommonButton isLoad={linkLoader} onClick={callSignatureDocument}  label="Signature Document" fontSize="14px" fontWeight="700" className="dash_common_btn white_bg_btn" icon={""} /> : ''}
                {viewType == 'INVOICE' ? <CommonButton isLoad={linkLoader} onClick={callPaymentLink}  label="Payment Link" fontSize="14px" fontWeight="700" className="dash_common_btn white_bg_btn" icon={""} /> : ''}
                
            </div>
        </div>
      </div>
    </div>
  );
};

export default ActionModalComponent;
