import React, { useState, useEffect, useCallback } from 'react';
import searchicon from "../../assets/dashboard/Search.svg";
import { globalSearchService } from '../../services';
import { formatDate } from '../../utils/helper';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const GlobalSearch = () => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const projects = useSelector(state => state?.projects?.projects)
  const navigate = useNavigate()

  const debounce = (func, delay) => {
    let debounceTimer;
    return function(...args) {
      const context = this;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const handleFocus = () => {
    setIsDropdownVisible(true);
  };

  const handleBlur = () => {
    // Delay hiding the dropdown to allow for item click
    if(searchResults && searchResults.length === 0){
      setTimeout(() => setIsDropdownVisible(false), 100);
    }
  };

  const fetchSearchResults = async (query) => {
    if (query.trim()) {
      try {
        const res = await globalSearchService(query);
        if(res.statusCode === 200){
            if(res.data.status === 1){
                setSearchResults(res.data.responseData.projects || []);
            }
        }
        // setSearchResults(results);
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    } else {
      setSearchResults([]);
    }
  };

  const debouncedFetchSearchResults = useCallback(debounce(fetchSearchResults, 300), []);

  useEffect(() => {
    debouncedFetchSearchResults(searchInput);
  }, [searchInput, debouncedFetchSearchResults]);

  const handleChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleRedirection = (id) => {
    // Check if projects array exists and is not empty
    if (!projects || projects.length === 0) {
      console.error('Projects array is empty or not defined');
      return;
    }
  
    // Find the project with the given id
    const project = projects.find(project => project.id === id);
  
    // Handle case where project is not found
    if (!project) {
      console.error(`Project with id ${id} not found`);
      return;
    }
  
    // Save project to localStorage
    localStorage.setItem('projectDetail', JSON.stringify(project));
    
    setTimeout(() => {
      navigate('/projectdetails', { state : { id: id}})
    },500)
  };

  return (
    <div className="dashboard_upper_input" style={{ position: 'relative' }}>
      <input 
        type="text" 
        placeholder="Search" 
        value={searchInput}
        onFocus={handleFocus} 
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <img src={searchicon} alt="search icon" />
      {/* {isDropdownVisible && 
        (searchResults.length > 0 ? 
            <div className="__dropdown">
            <ul>
                {searchResults.map((result, index) => (
                  <li key={index} className="dash_fav_li">
                    <div className="dash_fav_li_div">
                      <h4 className="font_16">{result.model}</h4>
                      <h5 className="font_14">Created on {formatDate(result.timestamp)}</h5>
                    </div>
                  </li>
                ))}
            </ul>
            </div>
        : 
        <div className="__dropdown">
        <ul>
            <li>No Data Found</li>
            
        </ul>
        </div>
    )
      } */}
      {isDropdownVisible && 
        (searchResults.length > 0 ? 
          <div className="__dropdown">
            <ul>
              {searchResults.map((result, index) => {
                const formattedDate = formatDate(result.created_at);
                const isToday = formattedDate.startsWith("Today");
                return (
                  <li key={index} className="dash_fav_li" onClick={() => handleRedirection(result.id)}>
                    <div className="dash_fav_li_div">
                      <h4 className="font_16">{result.projectName}</h4>
                      <h5 className="font_14" >
                        {isToday ? `Created ${formattedDate}` : `Created on ${formattedDate}`}
                      </h5>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        : 
          <div className="__dropdown">
            <ul>
              <li>No Data Found</li>
            </ul>
          </div>
        )
      }
    </div>
  );
};

export default GlobalSearch;
