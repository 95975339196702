import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

// Register necessary Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const InvoicesPieCharts = ({data, title}) => {
  const chartData = {
    labels: ['Red', 'Blue'],
    datasets: [
      {
        label: 'Color Distribution',
        data: [
          data?.submitted?.amount,
          data?.approved?.amount
           ],
        backgroundColor: [
          'rgba(112, 134, 253, 1)',
          'rgba(111, 209, 149, 1)',
          // 'rgba(255, 174, 76, 1)',
          // 'rgba(7, 219, 250, 1)',
        ],
        borderWidth: 0,
      },
    ],
  };

  // Define options for the pie chart
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      tooltip: {
        enabled: false, // Disable tooltips
      },
      title: {
        display: true,
        text: 'Color Distribution in the Dataset', // Chart title
      },
    },
  };

  return (
    <div className="invoices_pie_charts mt-5">
        <p>{title} details for last 30 days ( Apr 1 - Apr 30 2024 )</p>
      <div className="row align-items-center">
        <div className="col-md-6 d-flex align-items-center justify-content-center border-right py-5">
          <div className='cm_doughnut_wrap'>
            <Doughnut data={chartData} options={options} />
          </div>
        </div>
        <div className="col-md-6 align-items-center justify-content-center py-5 px-5">
            <ul className='m-0 p-0'>
                <li className='d-flex align-items-center mb-4'><div style={{background: 'rgba(112, 134, 253, 1)', width: '30px', height: '30px'}}></div><b className='ms-3'>Submitted({data?.submitted?.count || 0})</b><span className='ms-auto'>₹ {data?.submitted?.amount ? data?.submitted?.amount?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0'} </span></li>
                <li className='d-flex align-items-center mb-4'><div style={{background: 'rgba(111, 209, 149, 1)', width: '30px', height: '30px'}}></div><b className='ms-3'>Approved({data?.approved?.count || 0})</b><span className='ms-auto'>₹ {data?.approved?.amount ? data?.approved?.amount?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0'} </span></li>
         
            </ul>
        </div>
      </div>
    </div>
  );
};

export default InvoicesPieCharts;



