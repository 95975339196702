import { React, useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import searchicon from "./../../../assets/dashboard/Search.svg";
import CommonButton from "../../../components/common/CommonButton";
import filtericon from "./../../../assets/dashboard/filter_icon.svg";
import arrowdown from "./../../../assets/logos/arrow-down.svg";
import DealroomFilter from "./../../../modals/DealroomFilter";
import dealroomIcon from "./../../../assets/logos/dealroom-icon.svg";

import SignatureUpload from "./SignatureUpload";

import threeDot from './../../../assets/logos/three-dot.svg'
import {deleteDealRoomService, getProjectByBusinessService, getProjectTemplate, getSignersServices, updateDealRoomService} from './../../../services/index'
import DealroomTemplate from "./DealroomTemplate";
import { _contractStatus, contractStatus, contractStatusForDealroom } from "../../../utils/constant";
import ActionModal from "../../../modals/ActionModal";
import { clientformatDate } from "../../../utils/helper";

const Dealroom = ({ activeTab, isGlobal }) => {
  const wrapperRef = useRef(null);
  let userDetail = JSON.parse(localStorage.getItem("authUserData")) || null;
  const projectDetails = JSON.parse(localStorage.getItem('projectDetail'));
  const [createNewDiv, setCreateNewDiv] = useState(false);
  const [createNewDiv2, setCreateNewDiv2] = useState(false);
  const [isCreateNewButton, setIsCreateNewButton] = useState(false);
  const [isCreateNewButton2, setIsCreateNewButton2] = useState(false);
  const [showSignatureDiv, setShowSignatureDiv] = useState(false);
  const [showDealroomFilter, setShowDealroomFilter] = useState(false);
  const [showDealroomModal, setShowDealroomModal] = useState(false);
  const [dealroomCreateNew,setDealroomCreateNew]=useState("");
  const [signersList, setSignersList] = useState([])
  const [userDetails, setUserDetails] = useState({});

  const [getTemplates,setGetTemplates]=useState([]);
  const [searchTerm, setSearchTerm] = useState("")
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(""); 
  const [filteredSigners, setFilteredSigners] = useState([])
  const [searchProjects, setSearchProjects] = useState([])
  const [isFiterRun, setIsFilterRun] = useState(false)
  const [filterError, setFilterError] = useState(false);
  const [allSigners, setAllSigners] = useState([]);
  const [isActionModalOpen, setIsActionModalOpen] = useState(false)
  const [action, setAction] = useState(null)
  const [dealroomId, setDealRoomId] = useState('')
  const [actionTitle, setActionTitle] = useState('')
  const [currentPdf, setCurrentPdf] = useState('')
  const [activeProject, setActiveProject] = useState({})
  const [projectsList, setProjectList] = useState([]);
  const [selectedProject, setSelectedProject] = useState({});
  const [selectedProjectErr, setSelectedProjectErr] = useState('');
  const [showProjectListModal, setShowProjectListModal] = useState(false);
  const [dealCat, setDealCat] = useState('');


  useEffect(() => {
    // Close filter when clicking outside of the component
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setCreateNewDiv(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [createNewDiv]);



  const getAllProjects = async () => {
    try {
        const res = await getProjectByBusinessService(userDetail?.businessDetail?.businessId);
        let _projects = res.data.responseData.projectData || [];
        if(_projects.length > 0) {
            _projects = _projects.map(item => ({...item, label: item.projectName, value: item.id}))
            setProjectList(_projects);
        }
    } catch (error) {
        console.error('Error fetching projects:', error);
    }
};


useEffect(() => {
    if(userDetail?.businessDetail?.businessId && isGlobal){
        getAllProjects();
    }
    
}, [isGlobal])


  useEffect(() => {
      const authUserData = localStorage.getItem("authUserData")
      setUserDetails(JSON.parse(authUserData));

      localStorage.removeItem('isCR');

  }, []);

  const initialStatus = [
    ...(contractStatusForDealroom?.map(item => ({
      statusName: item.value,
      status: false,
    })) || []),
  ];


  const [filter, setFilter] = useState({
    status: initialStatus.reduce((acc, item) => {
      acc[item.statusName] = item.status;
      return acc;
    }, {})
  });

  useEffect(() => {
    if(isFiterRun){
      applyFilter();
    }

   
  }, [filter]);

  useEffect(() => {
    getSigners();

    if(selectedProject.value){
      localStorage.setItem('selectedProject', selectedProject.value)
    }
    
  },[activeTab, isGlobal, selectedProject])

  useEffect(() => {
    setCreateNewDiv(false);
    setCreateNewDiv2(false);
    const dealRoomPopUpStatus = localStorage.getItem('dealRoomPopUsStatus') || false
    if (activeTab === "Dealroom") {
      if(dealRoomPopUpStatus){
        setShowDealroomModal(false);
      }else{
        setShowDealroomModal(true);
      }
      fetchData();
    }
  }, [activeTab]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300);
  
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      const searchTermLower = debouncedSearchTerm.toLowerCase();
      const filtered = signersList.filter(project => {
        const projectNameMatch = project.documentTitle.toLowerCase().includes(searchTermLower);
        return projectNameMatch
      });
      setSearchProjects(filtered)
    } 
  }, [debouncedSearchTerm]);


  useEffect(() => {
    if(dealroomId!=='' && actionTitle !== '' && currentPdf !== ''){
      setIsActionModalOpen(true)
    }
  },[dealroomId, action, actionTitle])

  const getSigners = async () => {
    const projectId = projectDetails?.id || '';
    const businessId = userDetail?.businessDetail?.businessId;
    
    let params = `?businessId=${businessId}`;
  
    if (!isGlobal || (isGlobal && selectedProject.value)) {
      const effectiveProjectId = isGlobal ? selectedProject.value : projectId;
      params += `&projectId=${effectiveProjectId}`;
    }
  
    try {
      const res = await getSignersServices(params);
      if (res.data.status === 1) {
        const signersData = res.data.responseData.data || [];
        setSignersList(signersData);
        setAllSigners(signersData);
        setFilteredSigners(signersData);
      }
    } catch (error) {
      console.error('Error fetching signers:', error);
    }
  };
  

  const toggleCreateNewDiv = () => {
    setCreateNewDiv(!createNewDiv);
    setIsCreateNewButton(!isCreateNewButton);
  };
  const toggleCreateNewDiv2 = () => {
    setCreateNewDiv2(!createNewDiv2);
    setIsCreateNewButton2(!isCreateNewButton2);
  };

  const handleUploadClick = () => {
    if(isGlobal){
      setShowProjectListModal(true)
    }else{
      setShowSignatureDiv(true);
      setShowDealroomModal(false)
    }
  };




  const handleDealroomFilterClick = () => {
    setShowDealroomFilter(true);
  };

  const handleDealroomModalClose = () => setShowDealroomModal(false);

  const fetchData = async () => {
    try {
        const res = await getProjectTemplate(); 
        setGetTemplates(res.data?.responseData?.templates || []);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };





  
const dealRoomPopUpHandler = (e) => {
  const isChecked = e.target.checked
  if(isChecked){
    localStorage.setItem('dealRoomPopUsStatus',true)
  }else{
    localStorage.setItem('dealRoomPopUsStatus',false)
  }
}

const handleSearchChange = (e) => {
  setSearchTerm(e.target.value);
};

const applyFilter = () => {
  const statusFiltersActive = Object.values(filter.status).some(value => value);

  if (!statusFiltersActive) {
    setFilterError(false); 
    setIsFilterRun(false); 
    setFilteredSigners(allSigners);
    return;
  }
  
  const filtered = allSigners.filter((project) => {
    const statusFilter = Object.entries(filter.status).some(([key, value]) => value && _contractStatus[project.status].value === key);
 
    if (statusFiltersActive) {
      return statusFilter;
    } else {
      return statusFilter;
    }
  });

  if (filtered.length === 0) {
    setFilterError(true);
  } else {
    setFilterError(false);
  }
  setFilteredSigners(filtered);
};

const handleCloseActionModal = () => {
  setDealRoomId('')
  setActionTitle('')
  setIsActionModalOpen(false)
}

const actionModalHandler = (id, title, _pdfUrl, _activeProject) => {
    if(id && title && _pdfUrl){
      setDealRoomId(id)
      setActionTitle(title)
      setCurrentPdf(_pdfUrl)
      setActiveProject(_activeProject)

    }
}

const actionHandler = (id, type) => {
  if(type === 2){
    try {
      deleteDealRoomService(id).then(res => {
        if(res.data.status === 1){
          getSigners()
        }
      })
    } catch (error) {
      
    }
  }
  if(type === 1){
    let data = {
      dealroomId:id,
      status:5
    }
    try {
      updateDealRoomService(data).then(res => {
        if(res.data.status === 1){
          getSigners()
        }
      })
    } catch (error) {
      
    }
  }
}



const handleDealroomClick = (value) => {
  if(isGlobal){
    setShowProjectListModal(true)
    setDealCat(value)
  }else{
    setDealroomCreateNew(value)
    setCreateNewDiv(false);
    setShowDealroomModal(false)
  }
}



const handleSelectProject = () => {
  if(selectedProject?.value){
    localStorage.setItem('projectDetail', JSON.stringify(selectedProject))
    if(dealCat){
      setDealroomCreateNew(dealCat);
      setCreateNewDiv(false);
      setShowDealroomModal(false)
    }else{
      setShowSignatureDiv(true);
      setShowDealroomModal(false);
    }
    setShowProjectListModal(false)
  }else{
    setSelectedProjectErr('Select a project')
  }
}


  return (
    <>
    {dealroomCreateNew === '' ? (
    showSignatureDiv ? (
        <SignatureUpload
          closeSignatureModule={() => setShowSignatureDiv(false)}
          type={2}
          refreshSignersList={getSigners}/>
      ) : (
        <div className="dealroom_main_div">
          <div className="dealroom_detail_upper _flex_row_spacebtw_">
            <div className="dealroom_detail_upper_left _flex_gap_30">

              {isGlobal ?  <Select
                              classNamePrefix="cm_ui_select min_width"
                              value={selectedProject.value ? selectedProject : ''}
                              onChange={e => setSelectedProject(e)}
                              options={[
                                  {label: 'All Projects', value: ''},
                                  ...projectsList
                              ]}
                            placeholder="All Projects" /> : ''}


              <div className="dashboard_upper_input ">
                <input type="text" placeholder="Search" value={searchTerm} onChange={handleSearchChange}/>
                <img src={searchicon} alt="searchicon" />
              </div>
              <CommonButton
                label="Filter"
                fontSize="14px"
                fontWeight="700"
                className="dash_common_btn white_bg_btn"
                icon={filtericon}
                onClick={handleDealroomFilterClick}
              />
              {showDealroomFilter && (
                <DealroomFilter
                  filterBy={filter}
                  setFilterBy={setFilter}
                  handleFilterClose={() => {
                    setShowDealroomFilter(false);
                    setIsFilterRun(true)
                  }}
                />
              )}
              
            </div>
            <div className="dealroom_detail_upper_right _flex_gap_30">
              <CommonButton
                label="Create New"
                fontSize="14px"
                fontWeight="700"
                className={`dash_common_btn dash_common_reverse ${isCreateNewButton ? "isCreateNewButtonActive" : ""}`}
                icon={arrowdown}
                onClick={toggleCreateNewDiv}
              />
              {createNewDiv && (
                <ul ref={wrapperRef} className="create_new_ul">
                  <li onClick={() => handleDealroomClick("questionnaire")}>Questionnaire</li>
                  <li onClick={() => handleDealroomClick("proposal")}>Proposal</li>
                  <li onClick={() => handleDealroomClick("contract")}>Contract</li>
                  <li onClick={() => handleDealroomClick("form")}>Form</li>
                  <li onClick={() => handleDealroomClick("blankFile")}>Blank File</li>
                  
                </ul>
              )}
              <CommonButton
                label="Upload Docs for Signature"
                fontSize="14px"
                fontWeight="700"
                className="dash_common_btn"
                icon={false}
                onClick={handleUploadClick}
              />
            </div>
          </div>
          <h2 className="dashboard_heading">Details</h2>
          <p className="font_14">
            Reminders for documents will be sent every 2 days until signed.
          </p>
          <div className="table-scrolling">
          <table className="project_table dealroom_table">
            <thead>
              <tr>
                <th className="text-start ps-4">S.No.</th>
                <th className="text-start">Created</th>
                <th className="text-start">Title</th>
                <th className="text-start">Status</th>
                <th className="text-start">Sent to</th>
                <th className="text-start">Requested By</th>
                <th className="text-start">Action</th>
              </tr>
            </thead>
            
            <tbody>
            {searchTerm === '' ?
            filteredSigners && filteredSigners.length > 0 ? 
            filteredSigners.map((project, index) => (
              <tr key={index}>
                <td className="text-start ps-4">{index + 1}</td>
                <td className="text-start">

                {clientformatDate(
                    new Date(project.created_at)
                      .toISOString()
                      .split("T")[0]
                  )}
                </td>
                <td className="dealroom_table_title text-start"><span onClick={() => window.open(`/view-template?dealroomId=${project.id}`, "_blank")}>{project?.documentTitle}</span></td>
                <td className="text-start">
                  <p className="phases text-center ms-0" style={{backgroundColor: _contractStatus[`${project.status}`].color}}>{_contractStatus[`${project.status}`].value}</p>
                </td>
                <td className="text-start">
                  <div style={{display:'flex', justifyContent:'center',flexDirection:'column'}}>
                    {project.signers.map((signer, idx) => (
                      <div key={idx}>
                        <span style={{color : _contractStatus[signer.status]?.color}}>{_contractStatus[signer.status]?.value}</span> : {signer.name}
                      </div>
                    ))}
                  </div>
                </td>
                <td className="text-start">{userDetails.firstName} {userDetails.lastName}</td>
                <td className="text-start" style={{cursor:'pointer'}}><span onClick={() => actionModalHandler(project.id, project?.documentTitle, project?.pdfRecord?.updatepdfFile, project)}><img src={threeDot} alt="three dot"/></span></td>
           
              </tr>
            )):
            <tr>
              <td colSpan="7" className="loader_table">
                {filterError ? 'No results match the filter criteria' : 'NO DATA FOUND'}
              </td>
            </tr>
                
          :
          searchProjects.length > 0 ? 
          searchProjects.map((project, index) => (
            <tr key={index}>
                <td className="text-start ps-4">{index + 1}</td>
                <td className="text-start">
                {clientformatDate(
                    new Date(project.created_at)
                      .toISOString()
                      .split("T")[0]
                  )}
                </td>
                <td className="dealroom_table_title text-start"><span onClick={() => window.open(`/view-template?dealroomId=${project.id}`, "_blank")}>{project?.documentTitle}</span></td>
                <td className="text-start">
                  <p className="phases text-center ms-0" style={{backgroundColor: _contractStatus[`${project.status}`].color}}>{_contractStatus[`${project.status}`].value}</p>
                </td>
                <td className="text-start">
                  <div style={{display:'flex', justifyContent:'center',flexDirection:'column'}}>
                    {project.signers.map((signer, idx) => (
                      <div key={idx}>
                        <span style={{color : _contractStatus[signer.status]?.color}}>{_contractStatus[signer.status]?.value}</span> : {signer.name}
                      </div>
                    ))}
                  </div>
                </td>
                <td className="text-start">{userDetails.firstName} {userDetails.lastName}</td>
                <td className="text-start" style={{cursor:'pointer'}}><span onClick={() => actionModalHandler(project.id, project?.documentTitle, project?.pdfRecord?.updatepdfFile, project)}><img src={threeDot} alt="three dot"/></span></td>
           
              </tr>
          )) : 
              <tr>
                <td colSpan="7" className="loader_table">
                  NO DATA FOUND
                </td>
              </tr>
          }
            </tbody>
          </table>
          </div>
        </div>
      )) : (
        <DealroomTemplate
          dealroomCreateNew={dealroomCreateNew}
          resetDealRoomCreateNew={() => setDealroomCreateNew('')}
          getTemplates={getTemplates}
          type={1}
          signerLisingRefreshHandler={getSigners} />
      )}
     
      <Modal show={showDealroomModal} onHide={handleDealroomModalClose} className="dealroom_intital_modal">
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <img src={dealroomIcon} alt="dealroomIcon"/>
            <h3>Dealroom</h3>
            <p className="font_16">Create new forms, quotes, proposals & contracts in dealroom.<br/> Make it official with e-signatures.</p>
            <div className="dealroom_intital_modal_div">
                <CommonButton label="Create New" fontSize="14px" fontWeight="700" className={`dash_common_btn dash_common_reverse ${isCreateNewButton2 ? "isCreateNewButtonActive" : ""}`} icon={arrowdown} onClick={toggleCreateNewDiv2}/>
                {createNewDiv2 && (
                <ul className="create_new_ul create_new_ul2">
                  <li onClick={() => handleDealroomClick("questionnaire")}>Questionnaire</li>
                  <li onClick={() => handleDealroomClick("proposal")}>Proposal</li>
                  <li onClick={() => handleDealroomClick("contract")}>Contract</li>
                  <li onClick={() => handleDealroomClick("form")}>Form</li>
                  <li onClick={() => handleDealroomClick("blankFile")}>Blank File</li>
                </ul>
                )}
              <CommonButton label="Upload Docs" fontSize="14px" fontWeight="700" className="dash_common_btn" icon={false} onClick={handleUploadClick}/>
            </div>
            <h6 className="font_14"><input type="checkbox" onClick={(e) => dealRoomPopUpHandler(e)}/>Don’t show this again</h6>
        </Modal.Body>
      </Modal>



      <Modal show={showProjectListModal} onHide={() => setShowProjectListModal(false)} className="dealroom_intital_modal">
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="p-5 pt-0 text-start">
            <h5 className="mb-5">Select project for which you want to create a dealroom doument</h5>
            <Select
              classNamePrefix="cm_ui_select "
              value={selectedProject.value ? selectedProject : ''}
              onChange={e => (setSelectedProject(e), setSelectedProjectErr(''))}
              options={[
                  ...projectsList
              ]}
            placeholder="Select project" /> 

            {selectedProjectErr ? <h6 className="res_err">{selectedProjectErr}</h6> : ''}

            <div className="justify-content-between align-items-center d-flex mt-5">
              <span onClick={() => setShowProjectListModal(false)} className="btn btn-dark px-4">Cancel</span>
              <span onClick={handleSelectProject} className="btn btn-primary px-4">Create</span>
            </div>
          </div>
        </Modal.Body>
      </Modal>

        {isActionModalOpen && <ActionModal
          isOpen={isActionModalOpen}
          title={actionTitle}
          onClose={handleCloseActionModal}
          onSave={actionHandler}
          dealRoomID={dealroomId}
          pdf={currentPdf}
          activeProject={activeProject}
      />}
      </>
  );
};

export default Dealroom;