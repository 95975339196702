import React, { useState } from "react";
import CommonButton from "../components/common/CommonButton";

const DiscardModal = ({ title, isOpen, onClose, onSure, saveLabel = "Save Draft" }) => {
  const [isYes, setIsYes] = useState(false);

  const handleSaveDraft = async () => {
    setIsYes(true);
    await onSure();
    setIsYes(false);
  };

  if (!isOpen) return null;

  return (
    <div className="signature-modal">
      <div className="modal-content">
        <h3 style={{ fontSize: "16px" }}>{title}</h3>
        <div className="signature-modal-button">
          <CommonButton
            label="Discard"
            fontSize="14px"
            fontWeight="700"
            className={`dash_common_btn`}
            icon=""
            onClick={() => onClose("discard")}
          />
          <CommonButton
            label={saveLabel}
            fontSize="14px"
            fontWeight="700"
            icon=""
            isLoad={isYes}
            className={`dash_common_btn`}
            onClick={handleSaveDraft}
          />
        </div>
      </div>
    </div>
  );
};

export default DiscardModal;
