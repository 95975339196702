import React, { useState } from "react";
import Select, { components } from "react-select";
import arrowBottomIcon from "../../../assets/logos/arrow-bottom.svg";

const SingleValue = (props) => {
  const { children, data, showDot, ...rest } = props;
  return (
    <components.SingleValue {...rest}>
      {showDot && (
        <span
          style={{
            display: "inline-block",
            width: 8,
            height: 8,
            backgroundColor: data.color,
            borderRadius: "50%",
            marginRight: 8,
            marginTop: 5,
          }}
        ></span>
      )}
      {children || "Select"}
    </components.SingleValue>
  );
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img
        src={arrowBottomIcon}
        alt="collapse"
        style={{
          transform: props.selectProps.menuIsOpen
            ? "rotate(-180deg)"
            : "rotate(0deg)",
          transition: "transform 0.3s ease",
          marginRight: 10,
          cursor: "pointer",
        }}
      />
    </components.DropdownIndicator>
  );
};

const TableSelectComponent = ({
  value,
  options,
  onDropDownChange,
  showDot = false,
}) => {
  const [selectedValue, setSelectedValue] = useState(value);

  const handleSelectChange = (newSelectedValue) => {
    setSelectedValue(newSelectedValue);

    const e = { target: { value: newSelectedValue.value } };
    onDropDownChange(e);
  };

  return (
    <Select
      classNamePrefix="slelect_zindex"
      value={selectedValue}
      onChange={handleSelectChange}
      styles={{
        container: (base) => ({
          ...base,
          border: "none",
          backgroundColor: "transparent",
        }),
        control: (base) => ({
          ...base,
          border: "none",
          boxShadow: "none",
          backgroundColor: "transparent",
          "&:hover": {
            border: "none",
          },
        }),
        singleValue: (base) => ({
          ...base,
          padding: 5,
          borderRadius: 5,
          background: showDot
            ? "transparent"
            : selectedValue.color || "transparent",
          color: showDot
            ? selectedValue.color
            : selectedValue.color
            ? "white"
            : "black",
          display: "flex",
          justifyContent: "center",
        }),
        menu: (base) => ({
          ...base,
          borderRadius: 5,
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
        }),
        menuList: (base) => ({
          ...base,
          padding: 0,
        }),
        option: (base, state) => ({
          ...base,
          backgroundColor: "white",
          color: state.data.color,
          textAlign: "center", // Centers the text horizontally
          display: "flex", // Ensures that justifyContent works
          justifyContent: "center", // Centers the text horizontally
        }),
        indicatorSeparator: () => ({
          display: "none",
        }),
        menuPortal: (base) => ({ ...base, zIndex: 2 }), // Ensures the menu appears above other elements
      }}
      components={{
        SingleValue: (props) => <SingleValue {...props} showDot={showDot} />,
        DropdownIndicator,
      }}
      name="color"
      options={options}
      isSearchable={false}
      placeholder="Select"
      menuPlacement="top" // Ensures the menu appears above the select input
      menuPortalTarget={document.body} // Renders the menu in a portal to avoid overflow issues
    />
  );
};

export default TableSelectComponent;
