import Dashboard from "./pages/Dashboard";
import NotFound from "./pages/NotFound";
import Projects from "./pages/projects/Projects";
import ProjectDetails from './pages/projects/ProjectDetails'
import Invoices from "./pages/invoices/Invoices";
import Expenses from "./pages/expenses/Expenses";
import Dealroom from "./pages/projects/dealroom";
import Templates from "./pages/templates/Templates";
import Vendors from "./pages/vendors/Vendors";
import Settings from "./pages/settings/Settings";
import GlobalTask from "./pages/globalTask/GlobalTask";
import FilesContent from "./pages/projects/FilesContent";
import GlobalClients from "./pages/globalClients/GlobalClients";
import GlobalLibrary from "./pages/globalLibrary/GlobalLibrary";

const _nav = [
  {
    path: '/dashboard',
    component: Dashboard,
    roles: ['superadmin', 'admin', 'user']
  },
  {
    path: '/projects',
    component: Projects,
    roles: ['superadmin', 'admin']
  },
  {
    path: '/invoices',
    component: Invoices,
    roles: ['superadmin', 'admin', 'user']
  },
  {
    path: '/expenses',
    component: Expenses,
    roles: ['superadmin', 'admin', 'user']
  },
  {
    path: '/clients',
    component: GlobalClients,
    roles: ['superadmin', 'admin', 'user']
  },
  {
    path: '/tasks',
    component: GlobalTask,
    roles: ['superadmin', 'admin', 'user']
  },
  {
    path: '/templates',
    component: Templates,
    roles: ['superadmin', 'admin', 'user']
  },
  {
    path: '/vendors',
    component: Vendors,
    roles: ['superadmin', 'admin', 'user']
  },
  {
    path: '/library',
    component: GlobalLibrary,
    roles: ['superadmin', 'admin', 'user']
  },
  {
    path: '/dealroom',
    component: Dealroom,
    roles: ['superadmin', 'admin', 'user']
  },
  {
    path: '/projectdetails',
    component: ProjectDetails,
    roles: ['superadmin', 'admin']
  },
  {
    path: '/settings',
    component: Settings,
    roles: ['superadmin', 'admin']
  },
  {
    path: '*',
    component: NotFound,
    roles: ['superadmin', 'admin']
  },
];

export default _nav;