import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      // This reducer doesn't need to do anything since we'll handle the logout in rootReducer
    },
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;