import React from "react";

const RefundPolicy = () => {

  return (
    <>
      <div style={{maxWidth: '750px', margin: '0 auto'}}>
        <h2>
          <a id="_44i9jw6lqcol\"></a>
          <strong>
            Refund and Cancellation Policy <br />
          </strong>
        </h2>
        <h3>
          <a id="_vixk3isat2pn\"></a>
          <strong>1. Introduction</strong>
        </h3>
        <p>
          This Refund and Cancellation Policy (&quot;Policy&quot;) applies to
          all Services provided by Xora Business Solutions Private Limited
          (&quot;Xora&quot;, &quot;we&quot;, &quot;our&quot;, &quot;us&quot;).
          By subscribing to our Services, you agree to this Policy.
        </p>
        <h3>
          <a id="_pw8fdsoimp0z\"></a>
          <strong>2. Subscription Plans</strong>
        </h3>
        <p>
          Xora offers various subscription plans for its Services. Details of
          the subscription plans, including the fees, features, and duration,
          are available on our website
          <a href="http://www.getxora.com\"> www.getxora.com</a>.
        </p>
        <h3>
          <a id="_p5ecsfjo7s0q\"></a>
          <strong>3. Cancellation Policy</strong>
        </h3>
        <h4>
          <a id="_poe7sxagbiph\"></a>
          <strong>3.1. Voluntary Cancellation</strong>
        </h4>
        <ul>
          <li>
            <strong>Monthly Subscriptions</strong>: You may cancel your monthly
            subscription at any time. Your subscription will remain active until
            the end of the current billing cycle, after which it will not renew.
            No refunds will be provided for the remaining days of the
            subscription period.
          </li>
          <li>
            <strong>Annual Subscriptions</strong>: You may cancel your annual
            subscription at any time. Your subscription will remain active until
            the end of the current billing cycle, after which it will not renew.
            Refunds for annual subscriptions will be provided on a pro-rata
            basis for the remaining months, excluding the month in which the
            cancellation was made.
          </li>
        </ul>
        <h4>
          <a id="_2d5s45gd8q0e\"></a>
          <strong>3.2. Involuntary Cancellation</strong>
        </h4>
        <p>
          Xora reserves the right to suspend or terminate your subscription for
          any violation of our Terms and Conditions or for non-payment of fees.
          In such cases, no refund will be provided.
        </p>
        <h3>
          <a id="_1g1ucu29ir1i\"></a>
          <strong>4. Refund Policy</strong>
        </h3>
        <h4>
          <a id="_jsr4esaz49gc\"></a>
          <strong>4.1. Eligibility for Refund</strong>
        </h4>
        <p>
          Refunds are provided only for annual subscriptions, subject to the
          conditions mentioned below:
        </p>
        <ul>
          <li>
            <strong>Pro-Rata Refunds</strong>: Refunds for annual subscriptions
            will be calculated on a pro-rata basis for the remaining months of
            the subscription, excluding the month in which the cancellation
            request was made.
          </li>
          <li>
            <strong>Refund Request</strong>: To request a refund, you must
            contact our customer support at uday.sathe@getxora.com within 30
            days of the cancellation date.
          </li>
        </ul>
        <h4>
          <a id="_j7dxolbo4zfb\"></a>
          <strong>4.2. Non-Refundable Services</strong>
        </h4>
        <p>The following services are non-refundable:</p>
        <ul>
          <li>Monthly subscription fees</li>
          <li>Any setup or customization fees</li>
          <li>One-time service charges</li>
        </ul>
        <h3>
          <a id="_al2qi6aeqk1w\"></a>
          <strong>5. How to Cancel Your Subscription</strong>
        </h3>
        <p>To cancel your subscription, please follow these steps:</p>
        <ol>
          <li>Log in to your account on our website.</li>
          <li>
            Navigate to the &quot;Account Settings&quot; or &quot;Billing&quot;
            section.
          </li>
          <li>
            Select &quot;Cancel Subscription&quot; and follow the prompts to
            confirm your cancellation.
          </li>
        </ol>
        <p>
          Alternatively, you can contact our customer support at
          uday.sathe@getxora.com or call us at +1-979-739-6032 for assistance.
        </p>
        <h3>
          <a id="_2iqb64fjofhg\"></a>
          <strong>6. Refund Processing</strong>
        </h3>
        <p>
          Refunds will be processed within 14 business days from the date of
          approval. Refunds will be credited to the original payment method used
          at the time of purchase. Please note that it may take additional time
          for the credit to appear on your statement, depending on your bank or
          card issuer.
        </p>
        <h3>
          <a id="_26dm99gixts3\"></a>
          <strong>7. Changes to this Policy</strong>
        </h3>
        <p>
          Xora reserves the right to update or modify this Policy at any time.
          Any changes will be effective immediately upon posting the updated
          Policy on our website. We encourage you to review this Policy
          periodically to stay informed of any updates.
        </p>
        <h3>
          <a id="_vuqp0d89l0o1\"></a>
          <strong>8. Contact Information</strong>
        </h3>
        <p>For any questions about this Policy, please contact us at:</p>
        <p>
          <strong>
            Xora Business Solutions Private Limited
            <br />
          </strong>
          D-1203, Ciana Towers, Wadhwa Atmosphere,
          <br />
          Mumbai 400080, India
          <br />
          Email: uday.sathe@getxora.com
          <br />
          Phone: +1-979-739-6032
        </p>
      </div>
    </>
  );
};

export default RefundPolicy;