import React from "react";
import "./styles.css";

const CustomToggle = ({ label, subLabel, ...props }) => {
  return (
    <div style={{width: '30%'}} className="toggle">
      <div className="toggle-text">
        <span className="toggle-label">{label}</span>
        {subLabel && (
          <span className="toggle-subLabel" style={{ maxWidth: "218px" }}>
            {subLabel}
          </span>
        )}
      </div>
      <input type="checkbox" {...props} />
      <label style={{ marginTop: subLabel ? "-36px" : "" }}></label>
    </div>
  );
};

export default CustomToggle;
