import { useEffect, useState } from "react";
import { getClientbyProjectIdService, getMembersbyProjectIdService, getProjectByBusinessService, clientDeleteFromProjectService, memberDeleteFromProjectService } from "../../services";

const ClientProjectTeamComponent = ({ selectedProject }) => {
    const projectDetail = JSON.parse(localStorage.getItem('projectDetail'));
    const userDetail = JSON.parse(localStorage.getItem("authUserData")) || null;

    const [clientList, setClientList] = useState([]);
    const [memberList, setMemberList] = useState([]);


    const fetchProjectData = async (service, setData, dataKey) => {
        try {

            const projectId = selectedProject?.value || '';
            const businessId = userDetail?.businessDetail?.businessId;
            let params = `?businessId=${businessId}`;
            if (selectedProject.value) {
              params += `&projectId=${selectedProject.value }`;
            }
            const res = await service(params);
            if (res.data.status) {
                setData(res.data.responseData[dataKey] || []);
            }
        } catch (error) {
            console.error(`Error fetching ${dataKey} by project ID:`, error);
        }
    };

    useEffect(() => {
        fetchProjectData(getClientbyProjectIdService, setClientList, 'clients');
        fetchProjectData(getMembersbyProjectIdService, setMemberList, 'members');
    }, [selectedProject]);



    

    return (
        <div className="file_content_wrapper">
         
            <SectionHeader title="Client">
            </SectionHeader>


            <div className="cm_table_view mt-4 mb-5">
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th></th>
                                <th className="ps-5">Name</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                                <th>Role</th>
                                
                            </tr>
                        </thead>
                        <tbody>

                            {
                                clientList.map((item, index) => (
                                    <tr key={item.id}>
                                        <td></td>
                                        <td className="ps-5">{(!item.firstName && !item.lastName) ? '-' : item.firstName} {item.lastName}</td>
                                        <td>{item.email || ''}</td>
                                        <td>{item.phone_number || '-'}</td>
                                        <td>{item.role || 'Client'}</td>
                                  
                                    </tr>
                                ))
                            }
                            
                            
                        </tbody>
                        </table>
                    </div>
                </div>

            <SectionHeader title="Team" />
            <p>
                <small>
                    Only existing team members can be added to a project. To add new team members to the account, head to account settings.
                </small>
            </p>


            <div className="cm_table_view mt-4 mb-5">
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th></th>
                                <th className="ps-5">Name</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                                <th>Role</th>
                                
                            </tr>
                        </thead>
                        <tbody>

                            {
                                memberList.map((item, index) => (
                                    <tr key={item.id}>
                                        <td></td>
                                        <td className="ps-5">{(!item.firstName && !item.lastName) ? '-' : item.firstName} {item.lastName}</td>
                                        <td>{item.email || ''}</td>
                                        <td>{item.phone_number || '-'}</td>
                                        <td>{item.role || 'Client'}</td>
                                  
                                    </tr>
                                ))
                            }
                            
                            
                        </tbody>
                        </table>
                    </div>
                </div>

        </div>
    );
};

const SectionHeader = ({ title, children }) => (
    <div className="d-flex justify-content-between align-items-center mt-5">
        <h3 className="mb-0 teams_heading">{title}</h3>
        {children}
    </div>
);

export default ClientProjectTeamComponent;
