import { useState, React, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import CustomSelect from "../components/common/CustomSelect";
import rightarrow from "./../assets/logos/right_arrow.svg";
import ClientDetails from "./ClientDetails";
import { leadSource, projectStage, projectStatus } from "../utils/constant";
import useValidation from "../utils/customHooks/useValidations";
import { useSelector } from "react-redux";

const AddNewProject = ({ onHide, isOpen, openClientDetailModal}) => {
  const [projectInfo, setProjectInfo] = useState({
    projectName: "",
    projectStatus: {
      name: '',
      color: ''
    },
    clientName: "",
    projectStage: {
      name: '',
      color: ''
    },
    leadSource: {
      name: '',
      color: ''
    },
    favorite: 0,
    members: [],
    clients: []
  })

  const {
    errors,
    setErrors,
    validateForm,
    values,
    runTimeErrors,
    setRunTimeErrors,
    validateRunTimeError
  } = useValidation()

  const [projectStatusInitialVal, setProjectStatusInitialVal] = useState(null)
  const [projectStageInitialVal, setProjectStageInitialVal] = useState(null)
  const [leadSourceInitialVal, setLeadSourceInitialVal] = useState(null)
  const [newLead, setNewLead] = useState([])
  const [isdropDownError, setIsDropDownError] = useState({
    projectStage: false,
    leadSource:false
  });
  const phases = useSelector(state => state?.phases?.phases?.phase);
  const status = useSelector(state => state?.status?.status?.status);
  const reduxLeadSource = useSelector(state => state?.leadSource?.leadSource?.leadSource);

  useEffect(() => {
    const _peojectInfo = JSON.parse(localStorage.getItem('ProjectDetails')) || null
    const updatedLeadSource =  JSON.parse(localStorage.getItem('leadSource')) || null

    if(_peojectInfo){
      setProjectInfo({..._peojectInfo})
      if(_peojectInfo.projectStatus !== '')
          setProjectStatusInitialVal({ label: _peojectInfo.projectStatus.name, value: _peojectInfo.projectStatus.name, color: _peojectInfo.projectStatus.color})
      if(_peojectInfo.phases !== ''){
          setProjectStageInitialVal({ label: _peojectInfo.projectStage.name, value: _peojectInfo.projectStage.name, color: _peojectInfo.projectStage.color })
      }
      if(_peojectInfo.leadSource !== ''){
        setLeadSourceInitialVal({ label: _peojectInfo.leadSource.name, value: _peojectInfo.leadSource.name, color: '' })
      }
    }
  },[])
  
  const handleSelect = (option, name) => {

    if(name === 'projectStatus'  && option?.value !== undefined){
      let val = {
        name: option?.value,
        color: option?.color
      }
      setProjectInfo({
        ...projectInfo,
        projectStatus:val
      })
    }

    if(name === 'projectStage'  && option?.value !== undefined){
      let val = {
        name: option?.value,
        color: option?.color
      }
      setProjectInfo({
        ...projectInfo,
        projectStage:val
      })

      setIsDropDownError({
        ...isdropDownError,
        projectStage : false
      })
    }

    if(name === 'leadSource'  && option?.value !== undefined){
      let val = {
        name: option?.value,
        color: ''
      }
      setProjectInfo({
        ...projectInfo,
        leadSource:val
      })

      setIsDropDownError({
        ...isdropDownError,
        leadSource : false
      })
    }

  };

  const handleDropDownError = () => {
    let validate = true
    let _errors = {...isdropDownError}
    if(projectInfo.projectStage.name === ''){
      _errors.projectStage = true
      validate = false
    }

    if(projectInfo.leadSource.name === ''){
      _errors.leadSource = true
      validate = false
    }
    setIsDropDownError(_errors)
    return validate
  }

  const handleNextClick = () => {
    let validate = handleDropDownError()
    if(validateForm(projectInfo,'projectDetails') && validate){
      localStorage.setItem('newProjectDetail', JSON.stringify(projectInfo))
      openClientDetailModal()
      onHide()
    }
  };

  const handleOnChange = (fieldName, e) => {
    setProjectInfo({
    ...projectInfo,
    [fieldName] : e.target.value.replace(/^\w/, (c) => c.toUpperCase())
    })
    setErrors({
    ...errors,
    [fieldName] : ''
    })
    setRunTimeErrors({
    ...runTimeErrors,
    [fieldName] : ''
    })
    validateRunTimeError(fieldName, e.target.value)
  }

  const projectStages = () => {
    const projectStage = phases.map(item => ({ label: item.name, value: item.name , color: item.color}));
    return projectStage

  }

  const _projectStatus = () => {
    if(status){
      const _projectStage = status.map(item => ({ label: item.name, value: item.name ,color: item.color}));
      return [..._projectStage]
    }else{
      return projectStatus

    }
  }

  const _leadSource = () => {
    const _leadSource_ = reduxLeadSource.map(item => ({ label: item.name, value: item.name , color: item.color}));
    return _leadSource_

  }

  const handleKeyDown = (e) => {
    if (/[^A-Za-z]\s/.test(e.key)) {
        e.preventDefault(); // Prevent input of numeric characters
    }
  };
  return (
    <Modal show={isOpen} backdrop='static'>
      <div className="modal_main_div">
        <h2 className="modal_heading">New Project Details</h2>
        <ul className="modal_main_ul">
          <li className="modal_main_li">
            <label className="font_14">Project Name<span className="_imp_">*</span></label>
            <input
                  type="text" 
                  value={projectInfo.projectName}
                  placeholder="Project Name"
                  maxLength={50}
                  onChange={(e) => handleOnChange('projectName',e)}
                  onKeyDown={handleKeyDown}
                  />
            <div className='_error_'>{errors?.projectName || runTimeErrors?.projectName}</div>
          </li>
          <li className="modal_main_li">
            <label className="font_14">
              Project Status <span>( optional )</span>
            </label>
            <CustomSelect 
                    options={_projectStatus()}
                    name='projectStatus'
                    placeholder='Status'
                    initialValue={projectStatusInitialVal}
                    onSelect={handleSelect} />
          </li>
          <li className="modal_main_li">
            <label className="font_14">Project Pipeline Phase<span className="_imp_">*</span></label>
            <CustomSelect 
                    options={projectStages()} 
                    name='projectStage'
                    placeholder='Design'
                    initialValue={projectStageInitialVal}
                    onSelect={handleSelect} />
            {isdropDownError.projectStage ? (
              <div className="_error_">Project Pipeline Phase is required</div>
            ) : null}
          </li>
          <li className="modal_main_li">
            <label className="font_14">Lead Source<span className="_imp_">*</span></label>
            <CustomSelect 
                    options={_leadSource()} 
                    name='leadSource'
                    placeholder='Lead Source'
                    initialValue={leadSourceInitialVal}
                    onSelect={handleSelect} />
            {isdropDownError.leadSource ? (
              <div className="_error_">Lead Source is required</div>
            ) : null}
          </li>
        </ul>
      </div>
      <div className="modal_btn_div">
        <div className="modal_btn_div">
          {/* <button className="_btn_transparent_ modal_div_button" onClick={() => {onHide(); localStorage.removeItem('ProjectDetails')}}> */}
          <button className="_btn_transparent_ modal_div_button" onClick={() => onHide()}>
            <span>Cancel</span>
          </button>
          <button className="_btn_normal_ modal_div_button" onClick={handleNextClick}>
            <span>Next</span>
            <span className="_right_btn_icon_">
              <img src={rightarrow} alt="Next" />
            </span>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddNewProject;