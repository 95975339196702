const InvoicesAmountChip = ({ data, viewType }) => {
    return (
        <div className="invoices_amount_chip mt-5">
            <div className="row">

                {
                    viewType  == 1 ? <>
                                        <div className="col">
                                            <div className="invoices_amount_chip_card">
                                                <h5>Total Expenses</h5>
                                                <p>₹ {data?.totalAmount ? data?.totalAmount?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0'}</p>
                                            </div>
                                        </div>

                                        <div className="col">
                                            <div className="invoices_amount_chip_card">
                                                <h5>Total Submitted Expenses</h5>
                                                <small>(includes overdue amt. too)</small>
                                                <p>₹ {data?.submitted?.amount ? data?.submitted?.amount?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0'}</p>
                                            </div>
                                        </div>


                                        <div className="col">
                                            <div className="invoices_amount_chip_card">
                                                <h5>Total Approved Expenses</h5>
                                                <p>₹ {data?.approved?.amount ? data?.approved?.amount?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0'}</p>
                                            </div>
                                        </div>

                                        <div className="col">
                                         
                                        </div>
                    </> : ''
                }
                

            </div>
        </div>
    )
}

export default InvoicesAmountChip;